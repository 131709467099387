import React, { useEffect, FunctionComponent, useState } from 'react';

import moment from 'moment';
import { Modal, Button, Form, Checkbox, notification } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FlagOutlined, LogoutOutlined } from '@ant-design/icons';

import {
  AccountAcceptTermsMutation,
  AccountAcceptTermsMutationVariables,
  AccountAcceptTermsDocument,
} from '../graphql/components';
import apolloClient from '../graphql/client';

import { authentication } from '../stores/authentication';
import { changedTC, changedPrivacy, changedDataUsage } from '../config';

const AgreementModal: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const logoutPath = '/account/logout';

  const [needsTC, setTC] = useState<boolean>(false);
  const [needsPrivacy, setPrivacy] = useState<boolean>(false);
  const [needsDataUsage, setDataUsage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [checkmarkTC, toggleTC] = useState<boolean>(true);
  const [checkmarkPV, togglePV] = useState<boolean>(true);
  const [checkmarkDU, toggleDU] = useState<boolean>(true);

  useEffect(() => {
    // Brings up the modal if any of the changes happened after the user record, or if there's a null value on that record
    if (authentication.isAuthenticated && location.pathname !== logoutPath) {
      if (moment(changedTC).isValid()) {
        const TC = authentication.user?.acceptedTC
          ? moment(changedTC).isAfter(moment(authentication.user?.acceptedTC))
          : true;
        setTC(TC);
        toggleTC(!TC);
      }

      if (moment(changedPrivacy).isValid()) {
        const PV = authentication.user?.acceptedPrivacy
          ? moment(changedPrivacy).isAfter(
              moment(authentication.user?.acceptedPrivacy)
            )
          : true;
        setPrivacy(PV);
        togglePV(!PV);
      }

      if (moment(changedDataUsage).isValid()) {
        const DU = authentication.user?.acceptedDataUsage
          ? moment(changedDataUsage).isAfter(
              moment(authentication.user?.acceptedDataUsage)
            )
          : true;
        setDataUsage(DU);
        toggleDU(!DU);
      }
    }
  }, []);

  const handleLogout = () => {
    history.push(logoutPath);
  };
  const handleAccept = async () => {
    if (!checkmarkTC || !checkmarkPV || !checkmarkDU) {
      return;
    }

    setLoading(true);
    try {
      const res = await apolloClient.mutate<
        AccountAcceptTermsMutation,
        AccountAcceptTermsMutationVariables
      >({
        mutation: AccountAcceptTermsDocument,
        variables: {
          phoneNumber: authentication.user?.phoneNumber || '',
        },
      });

      if (res.errors || !res.data || !res.data.accepted) {
        setLoading(false);
        notification.error({
          message: 'Ocorreu um erro a comunicar com a API.',
          description:
            'Infelizmente não nos foi possível resolver este problema. Contacte a administração ou tente de novo mais tarde.',
        });
        return;
      }
    } catch (e) {
      setLoading(false);
      notification.error({
        message: 'Ocorreu um erro a comunicar com a API.',
        description:
          'Infelizmente não nos foi possível resolver este problema. Contacte a administração ou tente de novo mais tarde.',
      });
      return;
    }

    setLoading(false);
    setTC(false);
    setPrivacy(false);
    setDataUsage(false);
  };

  return (
    <Modal
      visible={needsTC || needsPrivacy || needsDataUsage}
      title={'Aviso!'}
      closable={false}
      onOk={handleAccept}
      footer={[
        <Button key="logout" loading={loading} onClick={handleLogout}>
          Sair <LogoutOutlined />
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleAccept}
          disabled={!checkmarkTC || !checkmarkPV || !checkmarkDU}
        >
          Aceitar
        </Button>,
      ]}
    >
      <Title level={2}>
        <FlagOutlined /> Alteração nos nossos termos de utilização
      </Title>
      <p>
        Procuramos ser transparentes em relação aos nossos acordos de utilização
        de serviço e houve uma alteração no(s) seguintes(s) pontos desde a
        última vez que utilizou o serviço. Pedimos que reveja e aceite cada um
        antes de proceder:
      </p>

      <Form layout="vertical">
        {needsTC && (
          <Form.Item required wrapperCol={{ span: 24 }}>
            <Checkbox
              onChange={e => toggleTC(e.target.checked)}
              checked={checkmarkTC}
              style={{ width: '100%' }}
            >
              Li, compreendi e aceito os{' '}
              <Link to="/about/terms-and-conditions">Termos e Condições</Link>{' '}
              de download e utilização da CovidAPP.
            </Checkbox>
          </Form.Item>
        )}
        {needsPrivacy && (
          <Form.Item required wrapperCol={{ span: 24 }}>
            <Checkbox
              onChange={e => togglePV(e.target.checked)}
              checked={checkmarkPV}
              style={{ width: '100%' }}
            >
              Li e compreendi a{' '}
              <Link to="/about/privacy-policy">Política de Privacidade</Link>{' '}
              que descreve como serão tratados os dados pessoais no âmbito da
              CovidAPP.
            </Checkbox>
          </Form.Item>
        )}
        {needsDataUsage && (
          <Form.Item required wrapperCol={{ span: 24 }}>
            <Checkbox
              onChange={e => toggleDU(e.target.checked)}
              checked={checkmarkDU}
              style={{ width: '100%' }}
            >
              Consinto o tratamento pela CovidAPP dos meus dados de saúde que eu
              disponibilizar exclusivamente para as finalidades previstas na
              política de privacidade.
            </Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AgreementModal;
