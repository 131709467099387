import React, { FunctionComponent } from 'react';
import { ConfigProvider } from 'antd';
import ptPT from 'antd/lib/locale-provider/pt_PT';
import { ApolloProvider } from 'react-apollo';
import 'animate.css';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { useObserver, useObservable } from 'mobx-react-lite';
import client from './graphql/client';
import Routes from './routes';
import { isReady } from './stores';

import './App.scss';

const App: FunctionComponent = () => {
  useObservable(isReady);

  return useObserver(() => {
    if (!isReady.get()) {
      return null;
    }

    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <ConfigProvider locale={ptPT}>
            <Routes />
          </ConfigProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  });
};

export default App;
