import { Button, Layout, Menu } from 'antd';
import { useObservable, useObserver } from 'mobx-react-lite';
import React, { SFC, useEffect } from 'react';
import { Link, RouteProps } from 'react-router-dom';
import { LogoutOutlined, DownOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import { ReactComponent as Logo } from '../assets/logo.svg';
import SocialComponent from '../components/social-component';
import Authentication, { authentication } from '../stores/authentication';
import AgreementModal from '../components/agreement-modal';
import PT2020Banner from '../components/portugal-2020';

const { Header, Content, Footer } = Layout;

const withMainLayout = (
  Component: React.ComponentType<any>,
  padded = true
) => ({ children, ...props }: RouteProps) => {
  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [props.location?.pathname]);

  return (
    <>
      <Layout className="layout">
        <Head pathname={props.location?.pathname} />

        <Content style={padded ? { padding: '10px 10px' } : {}}>
          <div
            className="site-layout-content"
            style={padded ? {} : { padding: 0 }}
          >
            {authentication.isAuthenticated && <AgreementModal />}
            <Component {...props}>{children}</Component>
          </div>
        </Content>

        <Footer style={{ textAlign: 'center', backgroundColor: 'white' }}>
          <Link
            className="underline-red"
            style={{ padding: 5 }}
            to="/about/privacy-policy"
          >
            <b>Política de Privacidade</b>
          </Link>
          <Link
            className="underline-red"
            style={{ padding: 5 }}
            to="/about/terms-and-conditions"
          >
            <b>Termos & Condições</b>
          </Link>
          <p>
            <br />
            Ajude outras pessoas a ultrapassar o COVID-19, <br />
            partilha este link nas plataformas sociais:
          </p>
          <SocialComponent />
          <PT2020Banner />
        </Footer>
      </Layout>
    </>
  );
};

const Head: SFC<{ pathname?: string }> = ({ pathname }) => {
  useObservable(authentication);

  const selectableKeys = [
    '/about/stats',
    '/about/precautions',
    '/about/team',
    '/about/how-it-works',
    '/account/profile',
    '/patient/reports',
    '/patient/dashboard',
    '/doctor/patients',
    '/doctor/dashboard',
  ];

  const selectedKeys = selectableKeys.filter(path => pathname?.includes(path));

  return useObserver(() => (
    <Header id="header">
      <Link
        to="/"
        className="logo"
        style={{
          display: 'flex',
          padding: '0 16px',
          height: '64px',
          alignItems: 'center',
        }}
      >
        <Logo />
      </Link>

      <Menu
        id="menu"
        mode="horizontal"
        theme="light"
        selectedKeys={[
          // selected page
          selectedKeys[0],
        ]}
      >
        {/* Logged */}
        {/* <MenuLink key="/about" children="Informação Institucional" visible={authentication.isAuthenticated} /> */}
        <MenuLink to="/account/login" visible={!authentication.isAuthenticated}>
          <Button>Entrar</Button>
        </MenuLink>

        <MenuLink to="/account/logout" visible={authentication.isAuthenticated}>
          Sair <LogoutOutlined />
        </MenuLink>

        {/* Show role switching */}
        {authentication.allowedRoles.length > 1 && (
          <Menu.SubMenu
            title={
              <>
                Vista de {authentication.roleInfo?.title} <DownOutlined />
              </>
            }
          >
            {Authentication.rolesInfo.map(
              (roleInfo, index) =>
                !!authentication.allowedRoles.includes(roleInfo.role) && (
                  <Menu.Item
                    className={classnames({
                      'ant-menu-item-selected':
                        authentication.roleInfoIndex === index,
                    })}
                    onClick={() => (authentication.roleInfoIndex = index)}
                  >
                    Modo de
                    {roleInfo.title}
                  </Menu.Item>
                )
            )}
          </Menu.SubMenu>
        )}

        <MenuLink
          key="/account/profile"
          to="/account/profile"
          visible={authentication.isAuthenticated}
        >
          A sua conta
        </MenuLink>

        <MenuLink visible key="/about/stats" to="/about/stats">
          Estatísticas
        </MenuLink>

        <MenuLink
          key="/about/precautions"
          to="/about/precautions"
          visible={
            !authentication.isAuthenticated || authentication.role === 'patient'
          }
        >
          Cuidados a ter
        </MenuLink>
        <MenuLink
          key="/about/team"
          to="/about/team"
          visible={!authentication.isAuthenticated}
        >
          Equipa
        </MenuLink>

        {/* <MenuLink
          key="/about/how-it-works"
          to="/about/how-it-works"
          children="Como Funciona"
          visible={!authentication.isAuthenticated}
          style={{ float: "right" }}
        /> */}

        {/* Patient */}
        <MenuLink
          key="/patient/reports"
          to="/patient/reports"
          visible={authentication.role === 'patient'}
        >
          Monitorização
        </MenuLink>
        <MenuLink
          key="/patient/dashboard"
          to="/patient/dashboard"
          visible={authentication.role === 'patient'}
        >
          Visão Geral
        </MenuLink>

        {/* Doctor */}
        <MenuLink
          key="/doctor/patients"
          to="/doctor/patients"
          visible={authentication.role === 'doctor'}
        >
          Pacientes
        </MenuLink>
        {/* <MenuLink
          key="/doctor/dashboard"
          to="/doctor/dashboard"
          children="Início"
          visible={authentication.role === "doctor"}
          style={{ float: "right" }}
        /> */}
      </Menu>
    </Header>
  ));
};

const MenuLink: SFC<{
  children: any;
  to: string;
  visible?: boolean;
  style?: React.CSSProperties;
}> = ({ children, to, visible = true, ...props }) => (
  <Menu.Item hidden={!visible} {...props}>
    <Link to={to}>{children}</Link>
  </Menu.Item>
);

export default withMainLayout;
