/** *****************************************
 *        STRING RELATED FUNCTIONS         *
 * FROM http://stackoverflow.com/a/1144788 *
 ****************************************** */

export function escapeRegExp(string: string) {
  return string.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
}

export function replaceAll(find: string, replace: string, str: string) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

/** *****************************************
 *         CHAR RELATED FUNCTIONS          *
 ****************************************** */

export function isNumber(char: string) {
  const asciiVal = char.charCodeAt(0);
  return asciiVal >= 48 && asciiVal <= 57;
}

/** *****************************************
 *            BI CHECK FUNCTIONS           *
 ****************************************** */

export function validBI(biVal: string, is10: number) {
  let i = 1;
  let sum = 0;
  for (let j = biVal.length - 1; j >= 0; j--) {
    if (j === biVal.length - 1 && is10 === 1) {
      sum += 10 * i;
      i++;
      continue;
    }
    sum += parseInt(biVal.charAt(j)) * i;
    i++;
  }
  return sum % 11 === 0 ? 1 : 0;
}

export function performBIValidation(biVal: string) {
  if (!validBI(biVal, 0)) {
    if (parseInt(biVal.charAt(biVal.length - 1)) === 0) {
      return validBI(biVal, 1);
    }
    return 0;
  }
  return 1;
}

/** *****************************************
 *            CC CHECK FUNCTIONS           *
 ****************************************** */

export function getNumberFromChar(letter: string) {
  if (isNumber(letter)) {
    return parseInt(letter);
  }
  return letter.charCodeAt(0) - 55;
}

export function performCCValidation(ccVal: string) {
  let sum = 0;
  let secondDigit = false;

  for (let i = ccVal.length - 1; i >= 0; i--) {
    let valor = getNumberFromChar(ccVal.charAt(i));
    if (secondDigit) {
      valor *= 2;
      if (valor > 9) {
        valor -= 9;
      }
    }

    sum += valor;
    secondDigit = !secondDigit;
  }

  return sum % 10 === 0 ? 1 : 0;
}

/** *****************************************
 *              CHECK FUNCTION             *
 ****************************************** */

/*
  shallow - enables or disable confirmation digits check
*/
export function check(val: string, shallow = false) {
  const ccRegExp = /^[0-9]{7,8}[0-9]([A-Z]|[0-9]){2}[0-9]$/;
  const ccShortRegExp = /^[0-9]{8}$/;
  const biRegExp = /^[0-9]{7,8}[0-9]$/;
  const ccNum = replaceAll(' ', '', val);
  if (ccRegExp.test(val)) {
    return !!performCCValidation(ccNum);
  } if (shallow && ccShortRegExp.test(val)) {
    return true;
  } if (biRegExp.test(val)) {
    return !!performBIValidation(ccNum);
  }
  return false;
}

/** *****************************************
 *             LEGACY FUNCTIONS            *
 ****************************************** */

export function checkCC(ccVal: string) {
  const regExp = /^[0-9]{7,8}[0-9]([A-Z]|[0-9]){2}[0-9]$/;
  if (regExp.test(ccVal)) {
    return performCCValidation(replaceAll(' ', '', ccVal));
  }
  return -1;
}

export function checkBI(biVal: string) {
  const regExp = /^[0-9]{7,8}[0-9]$/;
  if (regExp.test(biVal)) {
    return performBIValidation(replaceAll(' ', '', biVal));
  }
  return -1;
}
