function defaultHasuraEnv(environment: string): string {
  return (
    (environment === 'production' &&
      'https://covidapp-hasura-production.herokuapp.com/v1/graphql') ||
    (environment === 'staging' &&
      'https://covidapp-hasura-staging.herokuapp.com/v1/graphql') ||
    (environment === 'next' &&
      'https://covidapp-hasura-next.herokuapp.com/v1/graphql') ||
    'http://localhost:9000/v1/graphql'
  );
}

export const env = process.env.REACT_APP_ENV || 'development';
export const hasuraEnv = process.env.REACT_APP_HASURA_ENV;

export const isProduction = env === 'production';
export const isStaging = env === 'staging';
export const isNext = env === 'next';
export const isDevelopment = env === 'development';

export const hasuraEndpoint = hasuraEnv
  ? defaultHasuraEnv(hasuraEnv)
  : defaultHasuraEnv(env);
export const hasuraWsEndpoint = hasuraEndpoint.replace(/^http/, 'ws');

export const SentryDSN = process.env.REACT_APP_SENTRY_DSN;
export const isSentryActive = SentryDSN && SentryDSN.length;

// Share URL
export const appUrl =
  (isProduction && 'https://covidapp.pt') ||
  (isStaging && 'https://staging.covidapp.pt') ||
  (isNext && 'https://next.covidapp.pt') ||
  'http://localhost:9000';

export const mapBoxMaxZoom = 9;
export const mapBoxToken =
  process.env.REACT_APP_MAPBOX_TOKEN ||
  'pk.eyJ1IjoicmVhbHRpbWVhZHMiLCJhIjoiY2s3Z2kyOWJsMGIwNzNtbzJrczR1bGd2ZyJ9.mWdUsYnFU4GELKOHjwbfaQ';

export const recaptchaSiteKey =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
  '6LeFKewUAAAAAEz7Pab0ZrOtYwtae3lsaIXv0UEW';

// Last ToS Date changes
export const changedTC = '2020-04-29'; //'2020-04-29';
export const changedPrivacy = '2020-04-27'; //'2020-04-27';
export const changedDataUsage = '2020-04-24'; //'2020-04-24';
