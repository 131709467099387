import React, { FunctionComponent, useState, useCallback } from 'react';
import { Card, Button, message } from 'antd';
import { Redirect } from 'react-router-dom';
import delay from 'delay';
import Title from 'antd/lib/typography/Title';
import withMainLayout from '../../layouts/withMainLayout';
import { authentication } from '../../stores';
import Spacer from '../../components/spacer';

const Logout: FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const onLogout = useCallback(async () => {
    await setLoading(true);
    await delay(500);
    await authentication.logout();
    message.success('Sessão terminada com sucesso!');
    await setLoading(false);
  }, []);

  if (!authentication.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ marginTop: '6%' }}>
      <Card
        bordered={false}
        style={{
          maxWidth: '600px',
          margin: 'auto',
          height: '100%',
          boxShadow:
            '0px 6.17824px 21.5365px rgba(0, 0, 0, 0.030405), 0px 17.9145px 49.8481px rgba(0, 0, 0, 0.0358662), 0px 16px 64px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Title level={2}>Tem a certeza de que pretende sair?</Title>
        <Spacer />
        <p className="section-text">
          Para voltar a entrar terá que voltar a confirmar a sua sessão e ter o
          seu telefone próximo.
        </p>
        <br />

        <Button
          type="primary"
          size="large"
          onClick={onLogout}
          loading={loading}
        >
          Terminar Sessão
        </Button>
      </Card>
    </div>
  );
};

export default withMainLayout(Logout);
