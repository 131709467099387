import React, { FC } from 'react';
import banner from '../assets/images/pt2020.png';

const PT2020Banner: FC<{}> = () => (
  <>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '76px',
      }}
    >
      <div className="site-footer-pt2020">
        <span className="pt2020-title">
          {' '}
          Projecto Co-Financiado por (
          <a href="/PT2020-CovidApp.pdf" target="_blank" className="red-link">
            mais informações
          </a>
          )
        </span>
        <img
          src={banner}
          alt="Covidapp Compete2020 Banner"
          className="pt2020-banner"
        />
      </div>
    </div>
  </>
);

export default PT2020Banner;
