import React, { FC, useMemo, useState } from 'react';
import MapGL, { Source, Layer } from 'react-map-gl';
import { useAboutStatsQuery } from '../../../graphql/components';
import getCoordsFromZip from '../../../helpers/get-coords-from-zip';
import withMainLayout from '../../../layouts/withMainLayout';
import { heatmapTriage, heatmapInRisk, heatmapInfected } from './map-style';
import ControlPanel from './control-panel';
import './stats.scss';
import { mapBoxMaxZoom, mapBoxToken } from '../../../config';

interface IFeature {
  type: string,
  geometry: IPoint,
  properties: {
    monitoring: number;
    inRisk: number;
    infected: number
  };
}

interface IPoint {
  type: string,
  coordinates: [number, number, number]
}

function convertZipCodeIntoPoint(zipCode: string): IPoint {
  const zipCoords = getCoordsFromZip(zipCode);

  return {
    type: 'Point',
    coordinates: [
      zipCoords?.lng || 0,
      zipCoords?.lat || 0,
      zipCoords?.acc || 0,
    ],
  };
}

const Stats: FC<{}> = () => {
  const { data, loading } = useAboutStatsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 1000 * 60,
  });

  const geoJsonData = useMemo(() => {
    const features: IFeature[] = [];

    if (data?.stats.byZipCode) {
      for (const statByZipCode of data.stats.byZipCode) {
        if (!statByZipCode.zipCode) {
          continue;
        }

        features.push({
          type: 'Feature',
          geometry: convertZipCodeIntoPoint(statByZipCode.zipCode),
          properties: {
            monitoring: statByZipCode.totalUsers,
            inRisk: statByZipCode.usersWithLowRisk + statByZipCode.usersWithHighRisk,
            infected: statByZipCode.usersInfected,
          },
        });
      }
    }

    return {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
          date: 'TODO',
        },
      },
      features,
    };
  }, [data, loading]);

  const [viewport, setViewport] = useState({
    latitude: 40,
    longitude: -11,
    zoom: 6,
    bearing: 0,
    pitch: 0,
  });

  return (
    <div id="stats-page">
      <ControlPanel {...{ data, loading }} />
      <section id="map">
        <MapGL
          {...viewport}
          maxZoom={mapBoxMaxZoom}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/dark-v9"
          onViewportChange={setViewport}
          mapboxApiAccessToken={mapBoxToken}
        >
          {data && (
            <Source type="geojson" data={geoJsonData as any}>
              <Layer
                type="heatmap"
                paint={heatmapTriage}
              />
              <Layer
                type="heatmap"
                paint={heatmapInRisk}
              />
              <Layer
                type="heatmap"
                paint={heatmapInfected}
              />
            </Source>
          )}
        </MapGL>
      </section>
    </div>
  );
};

export default withMainLayout(Stats, false);
