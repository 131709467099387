import React, { FC } from 'react';
import { Card, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import SocialComponent from '../../../components/social-component';
// import Spacer from '../../../components/spacer'
import { PatientDataProps } from '.';

const SocialShareWidget: FC<PatientDataProps> = () => (
  <Col
    xl={{ span: 8 }}
    md={{ span: 12 }}
    sm={{ span: 24 }}
    xs={{ span: 24 }}
  >
    <Card id="social-share-widget" title="Ajude-nos a chegar a mais pessoas">
      <Title level={4}>
        Precisamos de chegar ao maximo de pessoas para ajudar quem mais precisa!
      </Title>
      <Paragraph>
        É super importante registar os sintomas de todos os casos, quer sejam casos suspeitos ou confirmados. A nossa aplicação vai medir a evolução dos sintomas de cada pessoa e reportar à comunidade de médicos quando forem detectados casos de relevo.
      </Paragraph>
      <SocialComponent />
    </Card>
  </Col>
);

export default SocialShareWidget;
