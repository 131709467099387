import React from 'react';

export const breathingDifficultyRange = [
  {
    key: '1',
    emoji: <img src={require('../../../assets/images/faces/happy.png')} alt="Muito bem" />,
    description: 'Tenho falta de ar apenas ao realizar exercício intenso.',
  },
  {
    key: '2',
    emoji: <img src={require('../../../assets/images/faces/smile.png')} alt="Bem" />,
    description:
      'Tenho falta de ar ao subir escadas ou andar depressa em plano.',
  },
  {
    key: '3',
    emoji: <img src={require('../../../assets/images/faces/meh.png')} alt="Normal" />,
    description:
      'Tenho falta de ar ao andar em plano, tenho dificuldade para acompanhar o passo de outra pessoa da minha idade. Já não consigo caminhar como fazia há um mês atrás.',
  },
  {
    key: '4',
    emoji: <img src={require('../../../assets/images/faces/sad.png')} alt="Alguma dor" />,
    description:
      'Tenho falta de ar ao andar alguns metros em plano ou ao fim de alguns minutos. Tenho falta de ar ao tomar banho.',
  },
  {
    key: '5',
    emoji: <img src={require('../../../assets/images/faces/pain.png')} alt="Muitas dores" />,
    description: 'Tenho falta de ar apenas ao vestir-me ou despir-me.',
  },
];
