import jwtDecode from 'jwt-decode';
import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { notification } from 'antd';
import mem from 'mem';
import { Hydrated } from './helpers/hydrate';
import apolloClient from '../graphql/client';
import {
  User,
  AuthSendVerificationCodeMutation,
  AuthSendVerificationCodeMutationVariables,
  AuthSendVerificationCodeDocument,
  AuthRefreshMutation,
  AuthRefreshMutationVariables,
  AuthRefreshDocument,
  AuthRevokeMutation,
  AuthRevokeMutationVariables,
  AuthRevokeDocument,
  AuthVerifyCodeDocument,
  AuthVerifyCodeMutation,
  AuthVerifyCodeMutationVariables,
  ProfileGetQuery,
  ProfileGetQueryVariables,
  ProfileGetDocument,
  ProfileUpdateDocument,
  ProfileUpdateMutation,
  ProfileUpdateMutationVariables,
} from '../graphql/components';

export const getTokenPayload = mem(
  (accessToken: string) => jwtDecode(accessToken) as any
);

export const getClaims = mem((accessToken: string) => {
  // gather the access token payload
  const payload = getTokenPayload(accessToken);
  const claims = payload && payload['https://hasura.io/jwt/claims'];

  return claims;
});

export interface RoleInfo {
  role: string;
  title: string;
}

export default class Authentication extends Hydrated {
  static rolesInfo: RoleInfo[] = [
    { role: 'patient', title: 'Paciente' },
    { role: 'doctor', title: 'Médico' },
    { role: 'manager', title: 'Gestor' },
  ];

  @computed public get isAuthenticated(): boolean {
    return !!this.accessToken;
  }

  @persist @observable public accessToken: string | null = null;

  @persist @observable public roleInfoIndex: number | null = null;

  @persist('object')
  @observable
  public userData: User | null = null;

  @computed public get user() {
    if (!this.accessToken) {
      return undefined;
    }

    return this.userData;
  }

  @computed public get roleInfo() {
    if (!this.accessToken) {
      return undefined;
    }

    if (!this.roleInfoIndex) {
      const claims = getClaims(this.accessToken);
      const defaultRole = claims['x-hasura-default-role'] || '';

      const index = Authentication.rolesInfo.findIndex(
        roleInfo => roleInfo === defaultRole
      );
      if (index > -1) {
        return Authentication.rolesInfo[index];
      }
    }

    return Authentication.rolesInfo[this.roleInfoIndex || 0];
  }

  @computed public get role() {
    return this.roleInfo?.role || null;
  }

  @computed public get userUUID(): string | null {
    if (!this.accessToken) {
      return null;
    }
    const claims = getClaims(this.accessToken);
    return claims['x-hasura-user-id'] || null;
  }

  @computed public get allowedRoles(): string[] {
    if (!this.accessToken) {
      return [];
    }
    const claims = getClaims(this.accessToken);
    return claims['x-hasura-allowed-roles'] || [];
  }

  @computed public get isProfileComplete(): boolean {
    const user = this.userData;
    return !!(
      user &&
      user?.phoneNumber &&
      user?.name &&
      user?.email &&
      user?.zipCode
    );
  }

  constructor() {
    super('Authentication');
  }

  public async ready() {
    await super.ready();

    if (this.isAuthenticated) {
      await this.handleSessionToken();
      await this.getUserData();
      await this.handleRemovedAccount();
    }
  }

  @action private async handleSessionToken() {
    if (!this.accessToken) return;

    const payload = getTokenPayload(this.accessToken);

    if (payload.jti) return;

    // if there's no jti, it means that we need to refresh this token
    try {
      const res = await apolloClient.mutate<
        AuthRefreshMutation,
        AuthRefreshMutationVariables
      >({
        mutation: AuthRefreshDocument,
      });

      const newAccessToken = res.data?.authRefresh.accessToken;

      if (newAccessToken) {
        this.accessToken = newAccessToken;
      }
    } catch (err) {
      // do nothing
    }
  }

  public getHeaders(
    accessToken: string | null = this.accessToken,
    role: string | null = this.role
  ) {
    if (!accessToken) {
      return {};
    }

    const claims = getClaims(accessToken);
    const allowedRoles = claims['x-hasura-allowed-roles'] || [];
    let pickedupRole = role
      ? allowedRoles.filter((r: string) => r === role)[0]
      : allowedRoles[0];

    if (!pickedupRole) {
      // scale down role
      pickedupRole = allowedRoles[0];
    }

    if (!pickedupRole) {
      throw new Error('Invalid role');
    }

    return {
      Authorization: `Bearer ${accessToken}`,
      'x-hasura-role': pickedupRole,
      // "x-hasura-user-id": claims["x-hasura-user-id"]
    };
  }

  @action private handleRemovedAccount() {
    if (this.userData?.phoneNumber && this.userData.phoneNumber.length > 9) {
      this.logout();

      notification.error({
        duration: 10, // seg
        message: 'Sessão terminada!',
        description:
          'Não foi possível encontrar uma conta com os dados da sua sessão.',
      });
    }
  }

  @action public async getUserData(
    userUUID: string | null = this.userUUID,
    accessToken?: string
  ) {
    if (!userUUID) {
      return;
    }

    const res = await apolloClient.query<
      ProfileGetQuery,
      ProfileGetQueryVariables
    >({
      query: ProfileGetDocument,
      variables: { uuid: userUUID },
      context: {
        headers: this.getHeaders(accessToken),
      },
      fetchPolicy: 'network-only',
    });

    this.userData = ((res.data.user as unknown) as User) || null;
  }

  @action public async updateUserData(data: Partial<User>) {
    const res = await apolloClient.mutate<
      ProfileUpdateMutation,
      ProfileUpdateMutationVariables
    >({
      mutation: ProfileUpdateDocument,
      variables: { data },
    });

    const newUserData = res.data?.updated?.user[0] as User;
    if (res.errors || !newUserData) {
      throw new Error('Something went wrong!');
    }

    this.userData = newUserData;
  }

  @action public async sendCode(phoneNumber: string, reCaptchaToken?: string) {
    const res = await apolloClient.mutate<
      AuthSendVerificationCodeMutation,
      AuthSendVerificationCodeMutationVariables
    >({
      mutation: AuthSendVerificationCodeDocument,
      variables: {
        phoneNumber,
      },
      context: {
        headers: {
          'X-ReCaptcha-Token': reCaptchaToken,
        },
      },
    });

    if (res.errors || !res.data) {
      throw new Error('Something went wrong!');
    }
  }

  @action public async verifyCode(
    phoneNumber: string,
    code: string,
    reCaptchaToken?: string
  ) {
    const res = await apolloClient.mutate<
      AuthVerifyCodeMutation,
      AuthVerifyCodeMutationVariables
    >({
      mutation: AuthVerifyCodeDocument,
      variables: {
        code,
        phoneNumber,
      },
      context: {
        headers: {
          'X-ReCaptcha-Token': reCaptchaToken,
        },
      },
    });

    if (res.errors || !res.data) {
      throw new Error('Something went wrong!');
    }

    const { accessToken, userUUID } = res.data.auth;

    // load the data before we set the user as logged in
    await this.getUserData(userUUID, accessToken);

    this.accessToken = accessToken;
  }

  @action public async logout() {
    try {
      await apolloClient.mutate<
        AuthRevokeMutation,
        AuthRevokeMutationVariables
      >({
        mutation: AuthRevokeDocument,
      });
    } catch {
      // do nothing
    }

    this.accessToken = null;
    this.userData = null;
    this.roleInfoIndex = null;
    await apolloClient.resetStore();
  }
}

export const authentication = new Authentication();
