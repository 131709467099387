import React, { FunctionComponent } from 'react';
import { Card } from 'antd';
import withMainLayout from '../../../layouts/withMainLayout';
import Spacer from '../../../components/spacer';
import './precautions.scss';

import Title from 'antd/lib/typography/Title';
import Distance from '../../../assets/icons/distance.svg';
import Washing from '../../../assets/icons/washing.svg';
import Cough from '../../../assets/icons/cough.svg';
import Avoidface from '../../../assets/icons/avoidface.svg';
import Avoidcroud from '../../../assets/icons/avoidcroud.svg';
import Services from '../../../assets/icons/services.svg';
import SleepAlone from '../../../assets/icons/sleepAlone.svg';
import Avoidold from '../../../assets/icons/avoidold.svg';
import Drinkwater from '../../../assets/icons/drinkwater.svg';

import NoVisits from '../../../assets/icons/novisits.svg';
import AvoidPublic from '../../../assets/icons/avoidpublic.svg';
import DontCough from '../../../assets/icons/dontcough.svg';
import DontReuse from '../../../assets/icons/dontreuse.svg';

import doIcon from '../../../assets/icons/doIcon.svg';
import DontDoIcon from '../../../assets/icons/dontDoIcon.svg';

import WashHands from '../../../assets/icons/washHands.svg';
import Share from '../../../assets/icons/share.svg';


const doList = [
  {
    text: 'Dentro de casa, tente ficar a pelo menos 2 metros de distância (3 passos) de outras pessoas, idosos ou doentes crónicos.',
    icon: Distance,
  },
  {
    text: 'Lave as mãos regularmente com água morna e sabão pelo menos durante 20 segundos.',
    icon: Washing,
  },
  {
    text: 'Tape o nariz e boca se espirrar ou tossir e lave imediatamente as mãos.',
    icon: Cough,
  },
  {
    text: 'Evitar tocar na face com as mãos.',
    icon: Avoidface,
  },
  {
    text: 'Evite aglomerados populacionais e locais públicos.',
    icon: Avoidcroud,
  },
  {
    text: 'Utilize serviços de entrega ao domicílio, ou peça a amigos e familiares que lhe levem medicação ou produtos de mercearia que necessite, mas evite o contacto com eles.',
    icon: Services,
  },
  {
    text: 'Durma sozinho(a) se possível.',
    icon: SleepAlone,
  },
  {
    text: 'Tente não conviver com idosos e pessoas com doenças crónicas.',
    icon: Avoidold,
  },
  {
    text: 'Beba água regularmente e tente fazer algum exercício dentro de casa.',
    icon: Drinkwater,
  },
];

const dontList = [
  {
    text: 'Não receba visitas (caso necessite de alguma coisa peça que lhe entreguem à porta)',
    icon: NoVisits,
  },
  {
    text: 'Não frequente locais públicos, não vá passear para a rua ou shoppings.',
    icon: AvoidPublic,
  },
  {
    text: 'Não tossir ou espirrar sem tapar a boca e nariz.',
    icon: DontCough,
  },
  {
    text: 'Não reutilize lenços de papel.',
    icon: DontReuse,
  },
];

const hygieneList = [
  {
    text: 'Medidas de etiqueta respiratória: tapar o nariz e a boca quando espirrar ou tossir, com um lenço de papel ou com o antebraço, nunca com as mãos, e deitar sempre o lenço de papel no lixo.',
    icon: Cough,
  },
  {
    text: 'Evitar tocar na cara com as mãos.',
    icon: Avoidface,
  },
  {
    text: 'Lave as mãos frequentemente: Deve lavá-las sempre que se assoar, espirrar, tossir ou após contacto direto com pessoas doentes. Deve lavá-las durante 20 segundos (o tempo que demora a cantar os “Parabéns”) com água e sabão ou com solução à base de álcool a 70%.',
    icon: WashHands,
  },
  {
    text: 'Evitar partilhar objetos pessoais ou comida em que tenha tocado.',
    icon: Share,
  },
  {
    text: 'Evitar contacto próximo com pessoas com infeção respiratória.',
    icon: Avoidold,
  },
];

const Precautions: FunctionComponent = () => (
  <div id="precautions" className="white">
    <section>
      <div className="measures-background boxed">
        <div className="col">
          <Title level={2}>Cuidados</Title>
          <Title level={3}>Medidas de Contacto</Title>
        </div>
        <div className="row pad-top-50">
          <div className="col do-it">
            <img className="do-icon" src={doIcon} alt="measure icon" />
            <div className="col">
              {doList.map((item, i) => (
                <div className="card-wrapper row pad-card" key={i}>
                  <img className="measure-icon" src={item.icon} alt="measure icon" />
                  <Card className="do green-background" bordered={false}>{item.text}</Card>
                </div>
              ))}
            </div>
          </div>
          <div className="col dont-do">
            <img className="dont-do-icon" src={DontDoIcon} alt="measure icon" />
            <div className="col">
              {dontList.map((item, i) => (
                <div className="card-wrapper row pad-card" key={i}>
                  <img className="measure-icon" src={item.icon} alt="measure icon" />
                  <Card className="dont red-background" bordered={false}>{item.text}</Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="higiene">
      <div className="boxed">
        <Title style={{ color: 'white' }} level={2}>Medidas de Higiene</Title>
        <div className="row pad-top-50">
          <div className="col-wrap">
            {hygieneList.map((item, i) => (
              <div className="card-wrapper row-wrap pad-card" key={i}>
                <img className="measure-icon" src={item.icon} alt="measure icon" />
                <Card className="dont transparent-background" bordered={false}>{item.text}</Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>


    <section>
      <div className="boxed">
        <Title level={2}>Mais Informações</Title>
        <p style={{ maxWidth: 750 }}>
          Para complementar as medidas aqui referidas consulte as recomendações
          para como
          {' '}
          <a className="red-link" href="/doente-em-casa.pdf"> lidar com um doente em casa</a>
          {' '}
          e os
          {' '}
          <a className="red-link" href="/recomendacoes-covid19.pdf"> aconselhamentos gerais</a>
          .
        </p>
        <p>
          Se está grávida, por favor consulte as nossas
          {' '}
          <a className="red-link" href="/gravidas.pdf"> recomendações</a>
          .
        </p>
        <Spacer />

        <img
          alt="quarentena"
          src={require('../../../assets/images/quarentena.jpg')}
          className="home-quarentena"
        />
      </div>
    </section>
  </div>
);

export default withMainLayout(Precautions, false);
