import React, { FC } from 'react';
import {
  WarningOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  QuestionOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Link } from 'react-router-dom';
import { Card, Progress, Col } from 'antd';

import { PatientDataProps } from '.';
import SNS24Link from '../../../components/sns24-link';

type UserSeverity = 'triage' | 'important' | 'urgent'

const statusValues: {
  [key: string]: number
} = {
  triage: 96,
  important: 64,
  urgent: 32,
  default: 96,
};

const statusMessages: {
  [key: string]: JSX.Element
} = {
  triage: (
    <Paragraph>
      A sua situação não aparenta ser um caso de risco, no entanto pedimos que continue a monitorizar a sua situação activamente, duas vezes por dia.
    </Paragraph>
  ),
  important: (
    <Paragraph>
      A sua situação exige redobrada monitorização. Para além de aumentar as monitorizações, por favor reveja o nosso guia de
      {' '}
      <Link to="/about/precautions">cuidados a ter</Link>
      {' '}
      e pondere se deve contactar a linha
      {' '}
      <SNS24Link />
      .
    </Paragraph>
  ),
  urgent: (
    <>
      <Paragraph>
        A sua situação aparenta ser de risco e pode exigir ação imediata. Considere contactar a linha
        {' '}
        <SNS24Link />
        .
      </Paragraph>
      <Paragraph>
        Para mais informações leia também a FAQ do
        {' '}
        <a href="https://www.sns24.gov.pt/tema/doencas-infecciosas/covid-19/" target="_blank" rel="noopener noreferrer">SNS 24</a>
      </Paragraph>
      <Paragraph>
        Vamos fornecer esta informação a profissionais de saúde para que possa ser acompanhado.
      </Paragraph>
    </>
  ),
};


const HealthAnalysisWidget: FC<PatientDataProps> = ({ loading, data }) => {
  const reports = data?.reports_data || [];
  const lastReport = reports?.[0];

  const severity = (
    (lastReport?.severity || 0) === 1 && 'triage'
    || (lastReport?.severity || 0) === 2 && 'important'
    || (lastReport?.severity || 0) === 3 && 'urgent'
    || null
  );
  const reportCount = data?.reports_count?.aggregate?.count || 0;

  // NOTE: relevant for dev purposes.
  // severity = "triage" as UserSeverity;
  // severity = "important" as UserSeverity;
  // severity = "urgent" as UserSeverity;
  // const reportCount: number = 1;

  if (!loading && !severity) {
    return null;
  }

  return (
    <Col
      xl={{ span: 8 }}
      md={{ span: 12 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <Card
        id="health-analysis-widget"
        loading={loading}
        title="Análise de Saúde"
      >
        {!severity || reportCount === 0 ? (
          <Paragraph className="no-margins">
            Antes de poder ser classificado a sua análise de saúde, por favor faça um registo dos seus sintomas
          </Paragraph>
        ) : null}

        {severity && reportCount > 0 ? (
          <>
            <div style={{ textAlign: 'center' }}>
              <Progress
                type="dashboard"
                strokeColor={
                  (severity === 'urgent' && 'red')
                  || (severity === 'important' && '#F2C500')
                  || (severity === 'triage' && 'green')
                  || undefined
                }
                percent={statusValues[severity] || statusValues.default}
                format={(percent) => (severity === 'urgent' && <CloseCircleOutlined />)
                  || (severity === 'important' && <WarningOutlined />)
                  || (severity === 'triage' && <CheckOutlined />) || (
                    <QuestionOutlined />
                  )}
              />
            </div>

            <b>{statusMessages[severity]}</b>

            <Paragraph className="disclaimer">
              <InfoCircleOutlined />
              {' '}
              O feedback que estamos a partilhar consigo não é, de forma alguma,
              um conselho médico, nem substitui a informação que pode ser
              prestada por um profissional de saúde ou pela linha{' '}
              <SNS24Link withTel />
              . Este é o resultado de um algorítmo que se limita a verificar se
              os teus sintomas se compactuam com algum dos niveis de resposta
              pré-definidos que preparámos.
            </Paragraph>
          </>
        ) : null}
      </Card>
    </Col>
  );
};

export default HealthAnalysisWidget;
