import React, { FunctionComponent, useState, useMemo } from 'react';
import {
  Spin, Card, Table, Result, Input,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import Title from 'antd/lib/typography/Title';
import computeAge from '../../helpers/compute-age';
import withMainLayout from '../../layouts/withMainLayout';
import {
  useDoctorPatientListSubscription,
  useDoctorPatientListNumberSubscription,
} from '../../graphql/components';
import Spacer from '../../components/spacer';
import FilterDropdown from '../../components/filter-dropdown';
import getCityFromZip from '../../helpers/get-city-from-zip';
import {
  SEVERITY_LABELS,
  SEVERITY_NUMBER_LABELS,
  SEVERITY_LABELS_NUMBER,
} from '../../constants';

const renderBoolean = (value: boolean | undefined) => {
  if (value === undefined || value === null) {
    return '-';
  }
  return (
    <span style={{ color: value ? 'green' : 'red' }}>
      {value ? 'Sim' : 'Não'}
    </span>
  );
};

const isDoctorSeeing = (patient: any) => {
  const lastViewTimestamp = patient?.lastReport?.[0]?.views?.[0]?.createdAt;
  if (patient?.lastReport?.[0]?.Notes?.[0]?.User?.role === 'doctor') {
    return true;
  } if (
    lastViewTimestamp
    && moment(lastViewTimestamp).isAfter(moment().subtract(15, 'minutes'))
  ) {
    return true;
  }
  return false;
};

const columns = [
  {
    title: 'Telefone',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: 'Género',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: 'Idade',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Cidade',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Temp',
    dataIndex: 'temperature',
    key: 'temperature',
  },
  {
    title: 'Dispneia (1-5)',
    dataIndex: 'breathingTroubleRate',
    key: 'breathingTroubleRate',
  },
  {
    title: 'Triagem',
    dataIndex: 'severity',
    key: 'severity',
  },
  {
    title: 'Visto',
    dataIndex: 'seen',
    key: 'seen',
  },
  {
    title: 'Detalhes',
    key: 'action',
    render: (_text: string, record: any) => (
      <Link to={`/doctor/patients/${record.uuid}`}>Ver -&gt;</Link>
    ),
  },
];
const now = moment()
  .hour(0)
  .minute(0);

const renderSeverity = (severity: number) => {
  let color;
  if (severity === 1) {
    color = 'green';
  } else if (severity === 2) {
    color = 'rgb(200,200,0)';
  } else {
    color = 'red';
  }
  return <span style={{ color }}>{SEVERITY_LABELS_NUMBER[severity]}</span>;
};
const PatientsList: FunctionComponent = () => {
  const [page, setPage] = useState<number>(0);
  const [limit] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterGender, setFilterGender] = useState('');
  const [filterAge, setFilterAge] = useState('');
  const [filterTemperature, setFilterTemperature] = useState('');
  const [filterBreathingTrouble, setFilterBreathingTrouble] = useState('');
  const [filterSeverity, setFilterSeverity] = useState<number>(0);

  const [ageMin, ageMax] = useMemo(() => {
    let min: number = parseInt(filterAge.split('-')?.[0], 10) - 1;
    let max: number = parseInt(filterAge.split('-')?.[1], 10) + 1;
    if (filterAge.includes('<')) {
      min = 0;
      max = 11;
    }
    if (filterAge.includes('+')) {
      min = 69;
      max = 999;
    }
    return [min, max];
  }, [filterAge]);
  const [tempMin, tempMax] = useMemo(() => {
    const min: number = parseInt(filterTemperature.split('-')?.[0], 10);
    const max: number = parseInt(filterTemperature.split('-')?.[1], 10);
    return [min, max];
  }, [filterTemperature]);

  const [search] = useDebounce(searchQuery, 300);
  const CommonVariables = {
    offset: page * limit,
    limit,
    userFilters: {
      ...(filterGender ? { gender: { _eq: filterGender } } : {}),
      ...(filterAge
        ? {
          dob: {
            _gte: now
              .clone()
              .startOf('day')
              .subtract(ageMax, 'years')
              .toISOString(),
            _lte: now
              .clone()
              .endOf('day')
              .subtract(ageMin, 'years')
              .toISOString(),
          },
        }
        : {}),
      ...(search
        ? {
          _or: [
            {
              name: { _ilike: `%${search}%` },
            },
            { idCardNumber: { _ilike: `%${search}%` } },
          ],
        }
        : {}),
    },
  };
  const {
    // error: AggError,
    // loading: AggLoading,
    data: AggData,
  } = useDoctorPatientListNumberSubscription({
    variables: CommonVariables,
  });
  const { error, loading, data } = useDoctorPatientListSubscription({
    variables: {
      ...CommonVariables,
      reportFilters: {
        ...(filterTemperature
          ? { temperature: { _gte: tempMin, _lte: tempMax } }
          : {}),
        ...(filterBreathingTrouble
          ? { breathingTroubleRate: { _eq: +filterBreathingTrouble } }
          : {}),
        ...(filterSeverity ? { severity: { _eq: filterSeverity } } : {}),
      },
    },
  });

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 50 }}>
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <Result
        status="warning"
        title="Erro ao carregar os dados. Tente novamente."
      />
    );
  }

  let patients = (data?.patients || []).filter(
    (patient) => patient.phoneNumber.length < 10,
  );

  if (patients.length > 0) {
    patients = patients.map((patient) => {
      const reportData = patient?.lastReport
        ? {
          temperature: `${patient?.lastReport?.[0]?.temperature}ºC`,
          breathingTroubleRate:
            patient?.lastReport?.[0]?.breathingTroubleRate,
          severity: patient?.lastReport?.[0]?.severity
            ? renderSeverity(patient.lastReport[0].severity)
            : '',
          seen: renderBoolean(isDoctorSeeing(patient)),
        }
        : {};

      return {
        ...patient,
        key: patient.uuid,
        age: computeAge(moment(patient.dob).toISOString()),
        city:
          patient.zipCode && getCityFromZip(patient.zipCode.substring(0, 4))
            ? getCityFromZip(patient.zipCode.substring(0, 4))
            : '-',
        ...reportData,
      } as any;
    });

    patients = patients.filter((p) => {
      if (searchQuery !== '') {
        const nameMatches = !!p.name && p.name.toLowerCase().includes(searchQuery.toLowerCase());
        const ccMatches = !!p.idCardNumber && p.idCardNumber.includes(searchQuery);

        if (!nameMatches && !ccMatches) {
          return false;
        }
      }

      return true;
    });
  }

  return (
    <>
      <Title style={{ marginBottom: 36 }} level={1}>
        Pacientes
      </Title>
      <Card
        bordered={false}
        style={{
          boxShadow:
            '0px 6.17824px 21.5365px rgba(0, 0, 0, 0.030405), 0px 17.9145px 49.8481px rgba(0, 0, 0, 0.0358662), 0px 16px 64px rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FilterDropdown
              parameter={filterGender === '' ? 'Género' : filterGender}
              options={[
                { label: 'Todos', value: '' },
                { label: 'Masculino', value: 'Masculino' },
                { label: 'Feminino', value: 'Feminino' },
              ]}
              onChange={(value) => setFilterGender(value as string)}
            />
            <Spacer horizontal />
            <FilterDropdown
              parameter={filterAge === '' ? 'Idade' : filterAge}
              options={[
                { label: 'Todas', value: '' },
                { label: '<10', value: '<10' },
                { label: '10-19', value: '10-19' },
                { label: '20-29', value: '20-29' },
                { label: '30-49', value: '30-49' },
                { label: '50-69', value: '50-69' },
                { label: '70+', value: '70+' },
              ]}
              onChange={(value) => setFilterAge(value as string)}
            />
            <Spacer horizontal />
            <FilterDropdown
              parameter={
                filterTemperature === '' ? 'Temperatura' : filterTemperature
              }
              options={[
                { label: 'Todas', value: '' },
                { label: '34-37', value: '34-37' },
                { label: '37-38', value: '37-38' },
                { label: '38-39', value: '38-39' },
                { label: '40-44', value: '40-44' },
              ]}
              onChange={(value) => setFilterTemperature(value as string)}
            />
            <Spacer horizontal />
            <FilterDropdown
              parameter={
                filterBreathingTrouble === ''
                  ? 'Dispneia'
                  : filterBreathingTrouble
              }
              options={[
                { label: 'Todas', value: '' },
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5', value: '5' },
              ]}
              onChange={(value) => setFilterBreathingTrouble(value as string)}
            />
            <Spacer horizontal />
            <FilterDropdown
              parameter={
                filterSeverity === 0
                  ? 'Triagem'
                  : SEVERITY_LABELS_NUMBER[filterSeverity]
              }
              options={[
                { label: 'Todas', value: 0 },
                ...Object.keys(SEVERITY_LABELS).map((severity) => ({
                  label: SEVERITY_LABELS[severity],
                  value: SEVERITY_NUMBER_LABELS[severity],
                })),
              ]}
              onChange={(value) => setFilterSeverity(value as number)}
            />
          </div>
          <Spacer horizontal />
          <Input.Search
            placeholder="Pesquisar por nome ou CC..."
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            style={{ flex: 1 }}
          />
        </div>
      </Card>
      <Spacer />
      <Card
        extra={`(${AggData?.User_aggregate?.aggregate?.count} total)`}
        bordered={false}
        style={{
          boxShadow:
            '0px 6.17824px 21.5365px rgba(0, 0, 0, 0.030405), 0px 17.9145px 49.8481px rgba(0, 0, 0, 0.0358662), 0px 16px 64px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Table
          dataSource={patients}
          columns={columns}
          pagination={{
            current: page + 1,
            pageSize: limit,
            total: AggData?.User_aggregate?.aggregate?.count || 0,
            onChange: (page) => setPage(page - 1),
          }}
        />
      </Card>
    </>
  );
};

export default withMainLayout(PatientsList);
