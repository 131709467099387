import React, { FunctionComponent } from 'react';

interface ISpacerProps {
  height?: string
  width?: string
  horizontal?: boolean
}

const Spacer: FunctionComponent<ISpacerProps> = (props) => (
  <div
    style={{
      height: props.height || '15px',
      width: props.horizontal ? props.width || '15px' : '1px',
    }}
  />
);

export default Spacer;
