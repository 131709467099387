import React, { FunctionComponent, useEffect } from 'react';
import { useParams, RouteComponentProps } from 'react-router-dom';
import { Spin } from 'antd';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import {
  User,
  Report,
  useDoctorPatientDetailsSubscription,
  useAddReportViewMutation,
} from '../../graphql/components';
import withMainLayout from '../../layouts/withMainLayout';
import PatientDetails from '../../components/patient-details';
import Back from '../../components/back-button';
import { authentication } from '../../stores';

const fifteenMinAgo = moment().subtract(15, 'minutes');

const PatientDetailsPage: FunctionComponent<RouteComponentProps> = (props) => {
  const { id } = useParams();
  const [mutate, { loading: LoadingReportView }] = useAddReportViewMutation();
  const { loading, data } = useDoctorPatientDetailsSubscription({
    variables: { userUUID: id },
  });

  useEffect(() => {
    const lastReport: any = data?.patient?.reports?.[0];
    const userIsViewing = (lastReport?.views || []).some(
      (view: any) => view?.userUUID === authentication.userUUID
        && moment(view.createdAt).isAfter(fifteenMinAgo),
    );
    if (!userIsViewing && lastReport) {
      mutate({
        variables: {
          data: {
            reportUUID: lastReport.uuid,
          },
        },
      });
    }
  }, [data?.patient]);

  if (loading || LoadingReportView) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 50 }}>
        <Spin />
      </div>
    );
  }
  const patient: Partial<User> | undefined | null = data?.patient;
  const reports: Partial<Report>[] = data?.patient?.reports ?? [];
  // @ts-ignore
  const viewers: Partial<
    User
  >[] = data?.patient?.reports?.[0]?.views?.filter((view: any) => moment(view.createdAt).isAfter(fifteenMinAgo)
  );

  return (
    <>
      <Back history={props.history} style={{ marginBottom: 36 }}>
        <LeftOutlined />
        {' '}
        Regressar à lista de pacientes
      </Back>
      <Title style={{ marginBottom: 36 }} level={1}>
        {patient?.name}
      </Title>
      <PatientDetails patient={patient} reports={reports} viewers={viewers} />
    </>
  );
};

export default withMainLayout(PatientDetailsPage);
