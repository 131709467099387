import React, { FunctionComponent, useEffect } from 'react';
import { Row, Spin } from 'antd';
import withMainLayout from '../../../layouts/withMainLayout';
import { usePatientDashboardQuery, PatientDashboardQuery } from '../../../graphql/components';

import HistoryWidget from './history-widget';
import MonitorDataWidget from './monitor-data-widget';
import UserFeedbackWidget from './user-feedback-widget';
import HealthAnalysisWidget from './health-analysis-widget';
import SocialShareWidget from './social-share-widget';
import MyZoneWidget from './my-zone-widget';
import UserDataWidget from './user-data-widget';

import './dashboard.scss';

export interface PatientDataProps {
  loading: boolean;
  data?: PatientDashboardQuery;
}

const Home: FunctionComponent = () => {
  // These could be delivered as props
  const { loading, data, refetch } = usePatientDashboardQuery({
    fetchPolicy: 'cache-and-network',
  });

  // ensure we have the severity for all teh reports set and loaded
  useEffect(() => {
    let lastTimeout: number;
    const reports = data?.reports_data || [];
    const reportsWithNoSeverity = reports.filter((r) => !r.severity);

    if (!loading && reports.length > 0 && reportsWithNoSeverity.length > 0) {
      lastTimeout = setTimeout(refetch, 30 * 1000);
    }

    return () => {
      clearTimeout(lastTimeout);
    };
  }, [loading, data]);

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 50 }}>
        <Spin />
      </div>
    );
  }

  return (
    <div id="patient-dashboard">
      <Row gutter={[36, 36]} align="stretch">
        <HistoryWidget {...{ loading, data }} />
        <MonitorDataWidget {...{ loading, data }} />
        <HealthAnalysisWidget {...{ loading, data }} />
        <UserDataWidget {...{ loading, data }} />
        <MyZoneWidget />
        <SocialShareWidget {...{ loading, data }} />
        <UserFeedbackWidget {...{ loading, data }} />
      </Row>
    </div>
  );
};

export default withMainLayout(Home);
