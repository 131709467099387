import React, { FunctionComponent } from 'react';
import { Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import withMainLayout from '../../../layouts/withMainLayout';
import './team.scss';
import FMUP from '../../../assets/images/fmup.png';
import MED from '../../../assets/images/med.png';
import GULBEN from '../../../assets/images/gulbenkian.png';
import EDP from '../../../assets/images/edp.png';
import teamMembers from './team-members';
import entities from './entities';
import { Entity } from './typings';

const Entities: FunctionComponent = () => (
  <div id="team">
    <div className="blue-background">
      <section id="intro">
        <div className="boxed">
          <Title style={{ color: 'white', fontWeight: 'bold' }} level={1}>
            Equipa
          </Title>

          <Paragraph className="white-text">
            Este projecto é o resultado de um esforço de vários cidadãos que se
            juntaram para dar resposta aos problemas nesta crise que
            atravessamos. <br />
            Alguns em Quarentena preventiva em casa, outros nos Hospitais ou
            respetivas Instituições que os albergam coordenaram, idealizaram e
            desenvolveram este produto num curtíssimo espaço de tempo.
          </Paragraph>

          <Paragraph className="white-text mb-50">
            Se trabalha na Área da Saúde, do Software ou tem ao seu dispôr meios
            de divulgação, podemos eventualmente precisar da sua ajuda. Entre em
            contacto via{' '}
            <a className="red-link" href="mailto:geral@covidapp.pt">
              geral@covidapp.pt
            </a>{' '}
            e indique-nos como nos pode ajudar.
          </Paragraph>
        </div>
      </section>

      <section className="white-background">
        <div className="boxed">
          <Title style={{ maxWidth: '450px', marginTop: 96 }} level={1}>
            Apoios e parcerias Institucionais
          </Title>
          <Paragraph style={{ paddingTop: 10 }}>
            <Title level={2}>Apoios</Title>
            <Row className="apoios" justify="space-between">
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <div className="partner">
                  <img src={GULBEN} alt="gulbenkian logo" />
                </div>
              </Col>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <div className="partner">
                  <img src={EDP} alt="edp logo" />
                </div>
              </Col>
            </Row>
          </Paragraph>
          <Paragraph>
            <Title level={2}>Parcerias</Title>
            <div className="partners">
              <div style={{ flex: 1 }}>
                <Title level={3}>FMUP/MEDCIDS</Title>
                <p className="ph-max">
                  Fornece a experiência em Ciências de Dados de Saúde aplicadas
                  a processos de investigação/avaliação clínica e a Avaliação de
                  Tecnologias e Serviços de Saúde.
                </p>
                <p className="ph-max mb-50">
                  Fornece o Serviço de Inteligência Artificial que permite
                  analisar, dia-a-dia, os dados da CovidApp e disponibilizar
                  relatórios ou outros instrumentos de visualização e sumário de
                  dados para acompanhamento e predição da evolução da COVID-19.
                  Os dados incluem caracterização demográfica, clínica,
                  laboratorial e de tratamentos dos casos, e modelação para
                  apoio à decisão com avaliação de opções e cenários que a
                  CovidApp e o MEDCIDS-FMUP entendam úteis.
                </p>
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <img
                  className="partner-logo"
                  src={FMUP}
                  alt="FMUP - Faculdade de Medicina e Universidade do Porto"
                />
                <img className="partner-logo" src={MED} alt="MEDCIDS" />
              </div>
            </div>
          </Paragraph>
        </div>
      </section>

      <section id="people" className="ant-background">
        <div className="boxed">
          <Title style={{ marginTop: 96 }} level={1}>
            Membros da Equipa
          </Title>
          <Row gutter={32}>
            {teamMembers.map((person, personIndex) => {
              const personEntities: Entity[] = ((person.entities
                .map((personEntity: string) =>
                  entities.find(({ key }) => key === personEntity)
                )
                .filter(ent => !!ent) || []) as unknown) as Entity[];

              return (
                <Col
                  className="person"
                  xxl={6}
                  xl={8}
                  md={12}
                  sm={24}
                  xs={24}
                  key={personIndex}
                >
                  <Title level={3}>{person.name}</Title>

                  {!!person.extra && <Paragraph>{person.extra}</Paragraph>}

                  <Title level={4}>{person.title}</Title>
                  {personEntities.map((entity, entityIndex) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      key={entityIndex}
                      href={entity.url || '#'}
                      title={entity.name}
                    >
                      {entity.name}
                    </a>
                  ))}
                </Col>
              );
            })}
          </Row>
        </div>
      </section>
    </div>
  </div>
);

export default withMainLayout(Entities, false);
