import { TeamMember } from './typings';

const teamMembers: TeamMember[] = [
  {
    name: 'Carlos Ochoa Leite',
    role: 'Revisão Conteúdos Médicos',
    title: 'Gabinete de Medicina do Trabalho',
    entities: ['IPOP'],
    extra: 'Cédula: 54491',
  },
  {
    name: 'Flávio Campos Costa',
    role: 'Revisão Conteúdos Médicos',
    title: 'Serviço Reumatologia',
    entities: ['CHUC'],
    extra: 'Cédula: 54770',
  },
  {
    name: 'João Ferreira Coimbra',
    role: 'Revisão Conteúdos Médicos',
    title: 'Serviço de Medicina Interna',
    entities: ['CHUP'],
    extra: 'Cédula: 55125',
  },
  {
    name: 'Joel Sousa',
    role: 'Revisão Conteúdos Médicos',
    title: 'Serviço de Angiologia e Cirurgia Vascular',
    entities: ['CHUSJ', 'FMUP'],
    extra: 'Cédula: 54507',
  },
  {
    name: 'Luis Braz',
    role: 'Revisão Conteúdos Médicos',
    title: 'Serviço de Neurologia',
    entities: ['CHUSJ'],
    extra: 'Cédula: 54649',
  },
  {
    name: 'Nuno Leal',
    role: 'Revisão Conteúdos Médicos',
    title: 'Serviço de Medicina Interna',
    entities: ['CHVNG'],
    extra: 'Cédula: 57075',
  },
  {
    name: 'Pedro Marques',
    role: 'Revisão Conteúdos Médicos',
    title: 'Serviço de Medicina Interna',
    entities: ['CHUSJ'],
    extra: 'Cédula: 55420',
  },
  {
    name: 'Pedro Couto',
    role: 'Revisão Médica',
    title: 'USF Longara Vida',
    entities: ['ACSBTVSN'],
    extra: 'Cédula: 53271',
  },
  {
    name: 'Luís Pereira Leite',
    role: 'Revisão Médica',
    title: 'Medicina Geral e Familiar',
    entities: ['CHTMAD'],
    extra: 'Cédula: 55421',
  },
  {
    name: 'Inês Ferrer Sales',
    role: 'Revisão Médica',
    title: 'Cirurgia Geral',
    entities: ['CHL'],
    extra: 'Cédula: 53083',
  },
  {
    name: 'Catarina Estevinho',
    role: 'Revisão Médica',
    title: 'Serviço de Ginecologia-Obstetrícia',
    entities: ['CHTS'],
    extra: 'Cédula: 56568',
  },
  {
    name: 'António Costa Braga',
    role: 'Revisão Médica',
    title: 'Serviço de Ginecologia-Obstetrícia',
    entities: ['CMIN'],
    extra: 'Cédula: 50070',
  },
  {
    name: 'José Moreira',
    role: 'Engenharia do Produto',
    title: 'Tech Lead',
    entities: ['MOSANO'],
  },
  {
    name: 'Francisco Sales',
    role: 'Engenharia do Produto',
    title: 'Tech Lead',
    entities: ['MOSANO'],
  },
  {
    name: 'Nelson Novais',
    role: 'Operações',
    title: 'Operations Manager',
    entities: ['MOSANO'],
  },
  {
    name: 'António Dias',
    role: 'Engenharia do Produto',
    title: 'Full-Stack Engineer',
    entities: ['MOSANO'],
  },
  {
    name: 'Miguel Frazão',
    role: 'Engenharia do Produto',
    title: 'Backend Engineer',
    entities: ['MOSANO'],
  },
  {
    name: 'Pedro Romano',
    role: 'Engenharia do Produto',
    title: 'Full-Stack Engineer',
    entities: ['MOSANO'],
  },
  {
    name: 'Ana Lourenço',
    role: 'Desenho do Produto',
    title: 'UI/UX Designer',
    entities: ['MOSANO'],
  },
  {
    name: 'Pedro Zenha',
    role: 'Engenharia do Produto',
    title: 'Software Developer',
    entities: ['MOSANO'],
  },
  {
    name: 'Pedro Carvalho',
    role: 'Engenharia do Produto',
    title: 'Software Developer',
    entities: ['MOSANO'],
  },
  {
    name: 'Ana Isabel Fonseca',
    role: 'Engenharia do Produto',
    title: 'Software Developer',
    entities: ['MOSANO'],
  },
  {
    name: 'Rafael Dias Almeida',
    role: 'Politica de Privacidade de Dados e T&C',
    title: 'Advogado',
    entities: ['BROSETA'],
  },
  {
    name: 'Luis Pereira',
    role: 'Copy',
    title: 'Head Of Operations',
    entities: ['LANDING_JOBS'],
  },
  {
    name: 'Maria Rosmaninho',
    role: 'UX',
    title: 'Copy and Content Strategy',
    entities: ['TANGIVEL'],
  },
  {
    name: 'Maria Antunes',
    role: 'UX/UI',
    title: 'Service Design',
    entities: ['TANGIVEL'],
  },
  {
    name: 'Fabio Gonçalves',
    role: 'Designer Gráfico',
    title: 'Branding e UI',
    entities: [],
  },
];

export default teamMembers;
