import { create } from 'mobx-persist';

export const hydrate = create({
  jsonify: true,
  storage: localStorage,
});

export default hydrate;

export class Hydrated {
  private hydratePromise: Promise<any> | null = null

  constructor(className?: string) {
    if (className) {
      this.hydratePromise = hydrate(className, this);
    }
  }

  public ready() {
    return this.hydratePromise;
  }
}
