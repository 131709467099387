import { WebSocketLink } from 'apollo-link-ws';
import { hasuraWsEndpoint } from '../../config';
import { authentication } from '../../stores';

const wsLink = new WebSocketLink({
  options: {
    connectionParams: () => ({
      headers: {
        'Content-Type': 'application/json',
        ...authentication.getHeaders(),
      },
    }),
    lazy: true,
    reconnect: true,
  },
  uri: hasuraWsEndpoint,
});

export default wsLink;
