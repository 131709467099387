import React, { useMemo, FC, useState } from 'react';
import {
  Axis, Chart, Geom, Tooltip,
} from 'bizcharts';
import { Card, Col } from 'antd';
import moment from 'moment';
import { PatientDataProps } from '.';

import {
  MAX_TEMPERATURE,
  MIN_TEMPERATURE,
  MIN_HEART_RATE,
  MAX_HEART_RATE,
  MIN_BREATH_RATE,
  MAX_BREATH_RATE,
} from '../../../constants';

const scale = {
  time: {
    type: 'time',
    alias: 'Time',
    formatter: (val: string) => moment(val).format('DD MMM @ HH:mm'),
  },
  temperature: {
    alias: 'Temperatura',
    min: MIN_TEMPERATURE,
    max: MAX_TEMPERATURE,
  },

  heartRate: {
    alias: 'Frequência Cardíaca',
    min: MIN_HEART_RATE,
    max: MAX_HEART_RATE,
  },
  breathRate: {
    type: 'linear',
    alias: 'Frequência Respiratória',
    min: MIN_BREATH_RATE,
    max: MAX_BREATH_RATE,
  },
  severity: {
    type: 'cat',
    alias: 'Análise de Saúde',
    values: [0, 1, 2, 3],
    formatter: (val: number | null) => (`${val}` === '1' && 'Prevenção')
      || (`${val}` === '2' && 'Moderado')
      || (`${val}` === '3' && 'Urgente')
      || 'A aguardar\n análise',
  },
};

const HistoryWidget: FC<PatientDataProps> = ({ data }) => {
  const [activeTab, onSetActiveTab] = useState<string>('severity');
  // const cardRef = useRef(null)

  const chartData = useMemo(() => {
    const cd = [];

    for (const report of data?.reports_data || []) {
      cd.push({
        time: report.createdAt,
        severity: report.severity,
        temperature: report.temperature,
        heartRate: report.heartRate,
        breathRate: report.breathRate,
      });
    }

    return cd;
  }, [data]);

  if (!data || (data.reports_data?.length || 0) === 0) {
    return null;
  }

  return (
    <Col
      xl={{ span: 16 }}
      md={{ span: 12 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <Card
        id="history-widget"
        // ref={cardRef}
        tabList={[
          { key: 'severity', tab: 'Análise de Saúde' },
          { key: 'temperature', tab: 'Temperatura' },
          { key: 'heartRate', tab: 'Frequência Cardíaca' },
          { key: 'breathRate', tab: 'Frequência Respiratória' },
        ]}
        activeTabKey={activeTab}
        onTabChange={onSetActiveTab}
      >
        <Chart
            //  @ts-ignore
          height={300}
          forceFit
          scale={scale}
          data={chartData}
          padding={{
            top: 40,
            bottom: 40,
            left: 80,
            right: 40,
          }}
        >
          <Tooltip />
          {chartData.length && <Axis />}

          <Geom
            type="line"
            shape="smooth"
            position={`time*${activeTab}`}
            size={2}
            color="#90daef"
          />
          <Geom type="point" position={`time*${activeTab}`} size={2} />
        </Chart>
      </Card>
    </Col>
  );
};

export default HistoryWidget;
