import React, { FC } from 'react';
import {
  Card, Button, Statistic, Col,
} from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spacer from '../../../components/spacer';
import { PatientDataProps } from '.';

const MonitorDataWidget: FC<PatientDataProps> = ({ loading, data }) =>
// const isProfileComplete: boolean = authentication.isProfileComplete

  (
    <Col
      xl={{ span: 8 }}
      md={{ span: 12 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
      id="monitor-data-widget"
    >
      <Card
        loading={loading}
        title="Monitorização"
        extra={(
          <Link to="/patient/reports/create">
            <Button type="primary" key="console">
              <PlusOutlined />
              {' '}
              Nova Monitorização
            </Button>
          </Link>
        )}
      >
        <Spacer />
        <Statistic
          className="stat"
          title="Relatorios de Monitorização submetidos até ao momento"
          value={data?.reports_count?.aggregate?.count || 0}
          valueStyle={{
            textAlign: 'center',
          }}
        />

        <Spacer />

        <div style={{ textAlign: 'center' }}>
          <Link to="/patient/reports">
            Ver todos os Relatórios de Monitorização
          </Link>
        </div>
      </Card>
    </Col>
  );
export default MonitorDataWidget;
