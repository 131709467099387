import { onError } from 'apollo-link-error';

import Sentry from '../../sentry';
import { authentication } from '../../stores';
import { isSentryActive } from '../../config';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const { message, locations, path } = error;

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      if (isSentryActive) {
        if (authentication.isAuthenticated) {
          Sentry.setUser({
            email: authentication?.userData?.email || undefined,
            id: authentication?.userData?.uuid,
            username: authentication?.userData?.phoneNumber,
          });
        }
        Sentry.captureException(error);
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export default errorLink;
