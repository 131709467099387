import React, { FunctionComponent, useState } from 'react';
import { Card, Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import ReportForm from './report-form';
import withMainLayout from '../../../layouts/withMainLayout';
import { authentication } from '../../../stores';
// import Spacer from '../../../components/spacer'
// import Title from 'antd/lib/typography/Title'

const Report: FunctionComponent = () => {
  const [sent, setSent] = useState<boolean>(false);
  const { isProfileComplete } = authentication;

  if (!isProfileComplete) {
    return (
      <Card title="Confirme os seus dados">
        <Result
          status="warning"
          title="Faltam dados relevantes na sua conta"
          subTitle="Os profissionais de saúde necessitam de informações pessoais para que o possam identificar e contactar."
          extra={[
            <Link to="/account/profile" key={0}>
              <Button key="console" type="primary">
                Preencher os dados em falta
              </Button>
            </Link>,
          ]}
        />
      </Card>
    );
  }

  return (
    <Card
      title="Novo Relatório de Monitorização"
      bordered={false}
      style={{
        maxWidth: '600px',
        margin: 'auto',
        boxShadow:
          '0px 6.17824px 21.5365px rgba(0, 0, 0, 0.030405), 0px 17.9145px 49.8481px rgba(0, 0, 0, 0.0358662), 0px 16px 64px rgba(0, 0, 0, 0.05)',
      }}
    >
      {!sent && <ReportForm onSent={() => setSent(true)} />}
      {sent && (
        <Result
          status="success"
          title="O seu relatório de sintomas foi submetido com sucesso!"
          subTitle="Aconselhamos a enviar 2 relatórios por dia, de manhã e de tarde, para que os profissionais de saúde sejam capazes de avaliar a evolução dos seus sintomas."
          extra={[
            <Link to="/patient/dashboard" key={0}>
              <Button key="console">Voltar ao Painel Principal</Button>
            </Link>,
          ]}
        />
      )}
    </Card>
  );
};

export default withMainLayout(Report);
