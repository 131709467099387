/* eslint-disable react/no-unescaped-entities */
import React, { FunctionComponent } from 'react';
import Title from 'antd/lib/typography/Title';
import { Table } from 'antd';
import withMainLayout from '../../../layouts/withMainLayout';
import Spacer from '../../../components/spacer';

import userRights from './user-rights.json';
import './privacy-policy.scss';

const userRightsWithKeys = userRights.map((ur: any, key) => ({ ...ur, key }));

const PrivacyPolicy: FunctionComponent = () => {
  const columns = [
    {
      dataIndex: 'name',
    },
    {
      className: 'column-rights',
      dataIndex: 'rights',
    },
  ];

  return (
    <div id="privacy-policy" className="section">
      <Title>
        Política de Privacidade
        <span className="bold"> CovidApp</span>
      </Title>
      <Spacer />
      <p>
        A presente Política de Privacidade (a{' '}
        <span className="bold">"Política"</span>) estabelece os termos de
        tratamento dos dados pessoais em resultado da utilização da aplicação
        móvel <span className="bold">CovidApp</span> (“
        <span className="bold">CovidApp</span>
        ”).
      </p>
      <p>
        Este projecto é o resultado de um esforço de vários indivíduos que se
        juntaram para dar resposta aos problemas nesta crise que atravessamos.
        Alguns em Quarentena preventiva em casa, outros nos Hospitais ou
        respetivas Instituições que os albergam coordenaram, idealizaram e
        desenvolveram este produto num curtíssimo espaço de tempo.
      </p>
      <p>
        A Faculdade de Medicina da Universidade do Porto (“FMUP”) colabora com o
        projecto, através da integração de uma Interface de Programação de
        Aplicação (“API”) de forma a permitir a partilha de dados agregados para
        a trabalhar a rede neural.
      </p>
      <p>
        Os dados partilhados com a FMUP não constituem dados pessoais nos termos
        do RGPD, uma vez que são dados agregados e não permitem identificar
        dados pessoais.
      </p>
      <p>
        A <span className="bold">CovidApp</span> é uma aplicação desenvolvida
        pela <span className="bold">Sales & Novais, LDA.</span>, sendo esta
        última, neste âmbito, o responsável pelo tratamento dos seus dados
        pessoais.
      </p>
      <p>
        Esta Política é complementada e integrada, em tudo o que não estiver
        previsto nela, pelos <span className="bold">T&C</span> que pode também
        consultar no website da{' '}
        <span className="bold">CovidApp</span>.
      </p>
      <p>
        Toda a informação providenciada pelos utilizadores na{' '}
        <span className="bold">CovidApp</span> apenas poderá ser acedida
        profissionais de saúde creditados para o efeito. Em nenhuma
        circunstância os dados introduzidos servirão para efeitos de marketing
        e/ou promocionais. Contudo, os dados de contacto poderão ser utilizados
        por profissionais de saúde para entrar em contacto com os utilizadores,
        de forma a encaminhá-los para um serviço de saúde.
      </p>
      <p>
        A <span className="bold">CovidApp</span> não guarda qualquer tipo de
        cookie.
      </p>
      <Title level={2}>Categorias de Dados</Title>
      <p>
        Através da <span className="bold">CovidApp</span>, obtemos dados
        pessoais do utilizador.
      </p>
      <p>
        Recolhemos e armazenamos, designadamente, os seguintes dados pessoais,
        que podem ser fornecidos diretamente, por terceiros ou por via da
        utilização da <span className="bold">CovidApp</span>:
        <ul>
          - <span className="bold">Dados de contacto</span>. Recolhemos
          informações de contacto, tais como endereços de e-mail, nomes, morada,
          números de telefone.
        </ul>
        <ul>
          - <span className="bold">Dados de saúde</span>: Mediante o
          consentimento do utilizador, recolhemos informações sobre o seu estado
          de saúde, nomeadamente a existência dos sintomas típicos do Covid19,
          através da seleção de caixas pré-formatadas. As opções são:
          <li>Temperatura</li>
          <li>Medicação</li>
          <li>Dificuldade em respirar</li>
          <li>Frequência cardíaca</li>
          <li>Diabetes</li>
          <li>Patologias crónicas</li>
          <li>Hipertensão</li>
          <li>Tensão Arterial</li>
          <li>
            Sintomas de Covid19 (o utilizador deverá descrever os sintomas)
          </li>
        </ul>
        <ul>
          - <span className="bold">Informação de log in</span>: Informação do
          utilizador, nomeadamente número de telefone móvel e número de cartão
          do cidadão
        </ul>
        <p>
          Os seus dados pessoais e restante informação são tratados de forma a
          que possamos disponibilizar o serviço da{' '}
          <span className="bold">CovidApp</span>, o qual tem como finalidades:
          <ul>
            - Identificar e autenticar o utilizador para que este possa utilizar
            a <span className="bold">CovidApp</span>;
          </ul>
          <ul>
            - Utilizar todas as funcionalidades disponíveis da{' '}
            <span className="bold">CovidApp</span>;
            {/* <li>Receber mensagem de validação de login</li>
            <li>Registo de Sintomas</li>
            <li>Classificação de Sintomas (assim que disponível)</li>
            <li>
              Acesso ao histórico dos relatórios introduzidos pelo utilizador em
              questão
            </li>
            <li>
              Partilha da aplicação em redes sociais, como: Facebook, WhatsApp,
              Twitter, Facebook Menseger, Linkedin e Reddit.
            </li>
            <li>Editar dados de utilizador.</li>
            <li>Informações sobre cuidados a ter.</li>
            <li>Informações sobre a Equipa e Apoios</li>
            <li>Informações sobre como funciona a App.</li>
            <li>Apagar a conta de utilizador e dados pessoais associados.</li> */}
          </ul>
          <ul>
            - Respondermos a possíveis pedidos, perguntas e instruções feitas
            através da <span className="bold">CovidApp</span>.
          </ul>
        </p>
        <Title level={2}>Base legal do tratamento </Title>
        <p>
          Os dados pessoais do utilizador são tratados para os fins descritos
          acima. A base legal para o tratamento dos dados pessoais inclui:
          <ul>
            ➜ <span className="bold">Prestação do Serviço da CovidApp</span>.
            Tratamento necessário para a execução das obrigações contratuais
            emergentes da adesão pelo utilizador aos{' '}
            <span className="bold">T&C</span> de forma a disponibilizar a{' '}
            <span className="bold">CovidApp</span> e providenciar o serviço.
            Para utilizar a <span className="bold">CovidApp</span> é necessário
            que nos forneça dados pessoais para tratamento, conforme descrito
            acima. Se não nos fornecer os dados pessoais necessários para fazer
            o download e aceder à <span className="bold">CovidApp</span>, não
            poderemos permitir a utilização da{' '}
            <span className="bold">CovidApp</span>.
          </ul>
          <ul>
            ➜ <span className="bold">Consentimento</span>. Podemos tratar os
            seus dados de saúde para a finalidade exclusiva de identificação da
            existência dos sintomas típicos do Covid19. Para tal, deverá
            consentir o tratamento de forma livre, específica, informada e
            inequívoca, carregando na caixa adequada no início do log-in da{' '}
            <span className="bold">CovidApp</span>.
          </ul>
          <ul>
            ➜ O titular dos dados poderá, a qualquer momento, revogar o
            consentimento relativo ao tratamento dos dados saúde. Para o efeito,
            e uma vez que é preciso o consentimento para o funcionamento da{' '}
            <span className="bold">CovidApp</span>, o apagamento dos dados de
            saúde determinará o cancelamento da conta do utilizador.
          </ul>
          <ul>
            ➜ <span className="bold">Interesse legítimo</span>. É do interesse
            da <span className="bold">CovidApp</span> (mas também de todos) que
            a <span className="bold">CovidApp</span> acompanhe e se adapte às
            necessidades dos Portugueses fazendo face aos desafios colocados
            pela pandemia do Covid19. Assim, a{' '}
            <span className="bold">CovidApp</span> irá tratar os seus dados não
            sensíveis com as seguintes finalidades: a melhoria dos serviços da{' '}
            <span className="bold">CovidApp</span> de forma a poder implementar
            atualizações, a gestão da nossa relação com o Utilizador,
            identificação e prevenção de registos fraudulentos e outras
            atividades criminosas, tratamento para efeitos de reclamações e
            outras responsabilidades, gestão da segurança da{' '}
            <span className="bold">CovidApp</span>;
          </ul>
        </p>
        <Title level={2}>Partilha de Informação</Title>
        <p>
          Os dados pessoais sobre o utilizador dos serviços não serão
          comunicados e transferidos para outras entidades.
        </p>
        <p> Não serão efectuadas transferências internacionais de dados.</p>
        <p>
          Embora exista partilha de dados com a FMUP, estes são dados agregados
          e não permitem identificar pessoas singulares, não constituindo dados
          pessoais nos termos do RGPD.
        </p>
        <Title level={2}>Conservação e Armazenamento de Dados Pessoais</Title>
        <p>
          Conservamos os seus dados pessoais durante toda a execução dos
          serviços. Podemos, ainda conservar dados pessoais para além da
          execução dos serviços nos termos legalmente permitidos ou para
          eventual defesa de direitos em sede judicial ou administrativa.
        </p>
        <p>
          Para mais informações sobre os prazos de conservação dos seus dados
          pessoais, por favor entre em contacto connosco através do email
          geral@CovidApp.pt.
        </p>
        <Title level={2}>Os seus direitos</Title>
        <p>
          Enquanto titular dos dados, o utilizador pode exercer os seus direitos
          nos seguintes termos:
        </p>
        <Table
          showHeader={false}
          columns={columns}
          rowKey="key"
          dataSource={userRightsWithKeys}
          pagination={false}
          bordered
        />
        <div className="m-10">
          <Title level={2}>Como Protegemos os Dados Pessoais</Title>
          <p>
            Mantemos as devidas salvaguardas administrativas, técnicas e físicas
            concebidas para proteger os dados pessoais que obtemos através dos
            serviços contra destruição acidental, ilegal ou não autorizada,
            interferência, perda, alteração, acesso, divulgação ou utilização.
          </p>
          <p>
            A <span className="bold">CovidApp</span> também mantém procedimentos
            razoáveis para ajudar a assegurar que tais dados são confiáveis para
            o uso pretendido e são precisos, completos e atuais.
          </p>
          <p>Como nos pode contactar: geral@covidapp.pt.</p>
        </div>
      </p>
    </div>
  );
};

export default withMainLayout(PrivacyPolicy);
