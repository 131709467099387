import React, { SFC, useEffect } from 'react';
import { useFormik } from 'formik';
import { string, number, object, date, boolean } from 'yup';
import moment from 'moment';
import classnames from 'classnames';
import {
  Alert,
  notification,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Table,
  Tooltip,
  Spin,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  PatientSubmitReportMutation,
  PatientSubmitReportMutationVariables,
  PatientSubmitReportDocument,
  usePatientLastReportQuery,
} from '../../../graphql/components';
import {
  MAX_TEMPERATURE,
  MIN_TEMPERATURE,
  MIN_HEART_RATE,
  MAX_HEART_RATE,
  MIN_BREATH_RATE,
  MAX_BREATH_RATE,
  MIN_BREATHING_TROUBLE,
  MAX_BREATHING_TROUBLE,
  MAX_MAX_BLOOD_PRESSURE,
  MIN_MAX_BLOOD_PRESSURE,
  MAX_MIN_BLOOD_PRESSURE,
  MIN_MIN_BLOOD_PRESSURE,
  MEDICATION_ANALGESIC_PREMADE_OPTIONS,
} from '../../../constants';
import apolloClient from '../../../graphql/client';

import './report-form.scss';
import { breathingDifficultyRange } from './utils';
import noop from '../../../helpers/noop';
import Spacer from '../../../components/spacer';

const now = moment();
const tomorrow = now
  .clone()
  .startOf('day')
  .add(1, 'day');
const beginOfYear = moment('2020/01/01 00:00:00');

const schema = object().shape({
  hasCough: boolean().when('hasSymptoms', {
    is: true,
    then: (s: any) => s.required(),
  }),
  symptomsBeginDate: date()
    .min(beginOfYear.toDate())
    .max(tomorrow.toDate())
    .when('hasSymptoms', {
      is: true,
      then: (s: any) => s.required(),
    })
    .nullable(),
  temperature: number()
    .min(MIN_TEMPERATURE)
    .max(MAX_TEMPERATURE)
    .required(),
  heartRate: number()
    .min(MIN_HEART_RATE)
    .max(MAX_HEART_RATE)
    .required(),
  breathRate: number()
    .min(MIN_BREATH_RATE)
    .max(MAX_BREATH_RATE)
    .required(),
  breathingTroubleRate: number()
    .min(MIN_BREATHING_TROUBLE)
    .max(MAX_BREATHING_TROUBLE)
    .required(),
  bloodPressureMin: number()
    .min(MIN_MIN_BLOOD_PRESSURE)
    .max(MAX_MIN_BLOOD_PRESSURE)
    .notRequired(),
  bloodPressureMax: number()
    .min(MIN_MAX_BLOOD_PRESSURE)
    .max(MAX_MAX_BLOOD_PRESSURE)
    .when(
      'bloodPressureMin',
      (bloodPressureMin: number | undefined, schema: any) =>
        bloodPressureMin
          ? schema.min(bloodPressureMin)
          : schema.min(MIN_MAX_BLOOD_PRESSURE)
    )
    .notRequired(),
  symptomsEvolution: string()
    .oneOf(['negative', 'neutral', 'positive'])
    .required(),
  hasBeenTested: boolean().required(),
  lastTestResult: boolean()
    .nullable()
    .when('hasBeenTested', {
      is: true,
      then: (s: any) => s.required(),
    }),
  lastTestDate: date()
    .nullable()
    .min(beginOfYear.toDate())
    .max(tomorrow.toDate())
    .when('hasBeenTested', {
      is: true,
      then: (s: any) => s.required(),
    }),
  tookPainkillers: boolean().required(),
  analgesics: string(),
  hadContactWithInfectedPatients: boolean().required(),
  hasSymptoms: boolean().required(),
  otherSymptoms: string(),
});

const ReportForm: SFC<{
  onSent: () => void;
}> = ({ onSent }) => {
  const { loading, data } = usePatientLastReportQuery({
    fetchPolicy: 'network-only',
  });

  const formik = useFormik<PatientSubmitReportMutationVariables>({
    initialStatus: 'loading',
    initialValues: {
      temperature: undefined,
      heartRate: undefined,
      breathRate: undefined,
      breathingTroubleRate: 0,
      symptomsBeginDate: undefined,
      symptomsEvolution: undefined,
      otherSymptoms: '',
      bloodPressureMin: undefined,
      bloodPressureMax: undefined,
      hadContactWithInfectedPatients: undefined,
      hasSymptoms: undefined,
      tookPainkillers: undefined,
      hasBeenTested: undefined,
      lastTestResult: undefined,
      lastTestDate: undefined,
      hasCough: undefined,
      analgesics: undefined,
    },
    validationSchema: schema,
    onSubmit: async ({
      temperature,
      heartRate,
      breathRate,
      breathingTroubleRate,
      symptomsBeginDate,
      symptomsEvolution,
      otherSymptoms,
      bloodPressureMin,
      bloodPressureMax,
      hadContactWithInfectedPatients,
      hasSymptoms,
      tookPainkillers,
      hasBeenTested,
      lastTestResult,
      lastTestDate,
      hasCough,
      analgesics,
    }) => {
      try {
        await apolloClient.mutate<
          PatientSubmitReportMutation,
          PatientSubmitReportMutationVariables
        >({
          mutation: PatientSubmitReportDocument,
          variables: {
            temperature,
            heartRate,
            breathRate,
            breathingTroubleRate,
            symptomsBeginDate,
            symptomsEvolution,
            otherSymptoms,
            bloodPressureMin,
            bloodPressureMax,
            hadContactWithInfectedPatients,
            hasSymptoms,
            tookPainkillers,
            hasBeenTested,
            lastTestResult,
            lastTestDate,
            hasCough,
            analgesics,
          },
        });

        onSent();

        notification.success({
          message: 'O seu relatório de sintomas foi submetido com sucesso',
        });
      } catch (err) {
        notification.error({
          message: 'Ocorreu um erro ao tentar submeter o seu relatorio.',
          description:
            'Infelizmente não nos foi possível submeter o seu relatório. Por favor verifique o acesso à internet ou tente mais tarde.',
        });
      }
    },
  });

  // Handle data preload
  useEffect(() => {
    if (loading) {
      return;
    }

    const report = data?.Report[0] || false;

    if (!report) {
      formik.setFieldValue('symptomsEvolution', 'neutral');
    } else {
      formik.setValues({
        ...formik.values,
        hasSymptoms: report.hasSymptoms,
        symptomsBeginDate: report.symptomsBeginDate,
        hadContactWithInfectedPatients: report.hadContactWithInfectedPatients,
        hasBeenTested: report.hasBeenTested,
        lastTestResult: report.lastTestResult,
        lastTestDate: report.lastTestDate,
      });
    }

    formik.setStatus('ready');
  }, [loading, data]);

  const getValidateStatus = (
    field: keyof PatientSubmitReportMutationVariables
  ) =>
    (formik.isSubmitting && 'validating') ||
    (formik?.errors[field] && 'error') ||
    undefined;

  if (formik.status !== 'ready') {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 50 }}>
        <Spin />
      </div>
    );
  }

  return (
    <div id="report-form">
      <Form
        initialValues={{
          ...formik.values,
          symptomsBeginDate:
            formik.values.symptomsBeginDate &&
            moment(formik.values.symptomsBeginDate),
          lastTestDate:
            formik.values.lastTestDate && moment(formik.values.lastTestDate),
        }}
        layout="vertical"
        onSubmitCapture={formik.handleSubmit}
        onChangeCapture={(e: any) => {
          const target = (e.target as unknown) as {
            name: string;
            value?: string;
          };
          const name = target?.name;
          let val: string | boolean | undefined = target?.value;

          // Boolean
          if (
            [
              'hasCough',
              'tookPainkillers',
              'hadContactWithInfectedPatients',
              'hasSymptoms',
              'hasBeenTested',
              'lastTestResult',
            ].indexOf(name) > -1
          ) {
            val = val === 'true';
          }

          formik.setFieldValue(name, val);
        }}
      >
        <Form.Item
          required
          validateStatus={getValidateStatus('hadContactWithInfectedPatients')}
          name="hadContactWithInfectedPatients"
          label="Contacto com pessoas infectadas"
          extra="Teve contacto com algum caso confirmado ou muito suspeito de estar infectado com covid-19?"
        >
          <Radio.Group
            name="hadContactWithInfectedPatients"
            buttonStyle="solid"
          >
            <Radio.Button value={false}>Não</Radio.Button>
            <Radio.Button value>Sim, tive contacto.</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          required
          validateStatus={getValidateStatus('hasSymptoms')}
          name="hasSymptoms"
          label="Tem alguns sintomas?"
          extra="Por favor indique que sim caso apresente quaisquer sintomas. (ex: Febre, Expectoração, Tosse, Congestão Nasal, Dificuldades Respiratórias, etc)."
        >
          <Radio.Group name="hasSymptoms" onChange={noop} buttonStyle="solid">
            <Radio.Button value={false}>Não</Radio.Button>
            <Radio.Button value>Sim, tenho sintomas.</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          className={classnames('can-be-hidden', {
            hide: !formik.values.hasSymptoms,
          })}
          required={!!formik.values.hasSymptoms}
          validateStatus={getValidateStatus('symptomsBeginDate')}
          name="symptomsBeginDate"
          label="Tenho sintomas desde"
          extra="Se tem sintomas, introduza a data de inicio dos sintomas:"
        >
          <DatePicker
            format="DD/MM/YYYY"
            name="symptomsBeginDate"
            disabledDate={date =>
              date.isSameOrAfter(tomorrow) || date.isSameOrBefore(beginOfYear)
            }
            onChange={date => {
              formik.setFieldValue('symptomsBeginDate', date?.toDate());
            }}
          />
        </Form.Item>

        <Form.Item
          className={classnames('can-be-hidden', {
            hide: !formik.values.hasSymptoms,
          })}
          required
          validateStatus={getValidateStatus('hasCough')}
          name="hasCough"
          label="Tem tosse seca nas últimas 12 horas?"
          extra="Por favor indique se nas últimas 12 horas tem sentido tosse seca e persistente, ou se teve sensação de irritação ou de cócegas na garganta continuas sem muco ou expectoração."
        >
          <Radio.Group name="hasCough" buttonStyle="solid">
            <Radio.Button value={false}>Não</Radio.Button>
            <Radio.Button value>Sim, tenho sentido tosse</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          required
          validateStatus={getValidateStatus('temperature')}
          name="temperature"
          label="Temperatura Corporal Axilar (32-44 °C)"
          extra="Introduza a sua Temperatura Corporal. Aconselhamos a medir na axila para que consigamos ter uma análise mais correcta."
        >
          <InputNumber
            name="temperature"
            step={0.1}
            min={MIN_TEMPERATURE}
            max={MAX_TEMPERATURE}
            onChange={val => formik.setFieldValue('temperature', val)}
          />
        </Form.Item>

        <Form.Item
          required
          validateStatus={getValidateStatus('tookPainkillers')}
          name="tookPainkillers"
          label="Tomou algum medicamento para a febre nas últimas 8 horas?"
          extra="Marque no caso de ter tomado algum antipiréticos nas últimas 8horas: paracetamol, ben-u-ron, brufen, nimed, etc"
        >
          <Radio.Group name="tookPainkillers" buttonStyle="solid">
            <Radio.Button value={false}>Não</Radio.Button>
            <Radio.Button value>Tomei nas últimas 8 horas</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {formik.values.tookPainkillers ? (
          <Form.Item
            validateStatus={getValidateStatus('analgesics')}
            name="analgesics"
            label="Quais?"
            extra={
              <div>
                <p>Ibuprofeno (Brufen, Nurofen, Spidifen)</p>
                <p>Paracetamol (Ben-U-Ron, Dafalgan, Panadol)</p>
              </div>
            }
          >
            <Radio.Group name="analgesics" buttonStyle="solid">
              <Radio.Button value="ibuprofen">
                {MEDICATION_ANALGESIC_PREMADE_OPTIONS.ibuprofen}
              </Radio.Button>
              <Radio.Button value="paracetamol">
                {MEDICATION_ANALGESIC_PREMADE_OPTIONS.paracetamol}
              </Radio.Button>
            </Radio.Group>
            <Spacer />
            <Input
              disabled={formik.isSubmitting}
              placeholder="Outros"
              name="analgesics"
              value={
                formik.values.analgesics === 'paracetamol' ||
                formik.values.analgesics === 'ibuprofen'
                  ? ''
                  : formik.values.analgesics || ''
              }
              onChange={formik.handleChange}
              style={{ marginBottom: '0.5em' }}
            />
          </Form.Item>
        ) : null}

        <Form.Item
          required
          validateStatus={getValidateStatus('breathingTroubleRate')}
          name="breathingTroubleRate"
          label="Classifique a sua dificuldade em respirar?"
          extra={
            <Tooltip
              placement="bottom"
              title={
                <Table
                  id="emoji-table-explainer-container"
                  dataSource={breathingDifficultyRange}
                  columns={[
                    {
                      title: 'Emoji',
                      dataIndex: 'emoji',
                      key: 'emoji',
                    },
                    {
                      title: 'Descrição',
                      dataIndex: 'description',
                      key: 'description',
                    },
                  ]}
                  footer={() => null}
                  pagination={false}
                />
              }
            >
              <div style={{ cursor: 'pointer' }}>
                <InfoCircleOutlined /> – Caso tenha dificuldade em escolher um
                nível, passe aqui o rato para ver casos exemplares.
              </div>
            </Tooltip>
          }
        >
          <Radio.Group
            size="large"
            className="breathing-trouble-rate"
            name="breathingTroubleRate"
            buttonStyle="solid"
          >
            <Radio.Button value={1}>
              <img
                src={require('../../../assets/images/faces/happy.png')}
                alt="Muito bem"
              />
            </Radio.Button>
            <Radio.Button value={2}>
              <img
                src={require('../../../assets/images/faces/smile.png')}
                alt="Bem"
              />
            </Radio.Button>
            <Radio.Button value={3}>
              <img
                src={require('../../../assets/images/faces/meh.png')}
                alt="Normal"
              />
            </Radio.Button>
            <Radio.Button value={4}>
              <img
                src={require('../../../assets/images/faces/sad.png')}
                alt="Alguma dor"
              />
            </Radio.Button>
            <Radio.Button value={5}>
              <img
                src={require('../../../assets/images/faces/pain.png')}
                alt="Muitas dores"
              />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          required
          validateStatus={getValidateStatus('breathRate')}
          name="breathRate"
          label={`Frequência Respiratória (${MIN_BREATH_RATE} - ${MAX_BREATH_RATE}, ciclos por minuto)`}
          extra={
            <div>
              <InfoCircleOutlined /> – Introduza a sua Frequência Respiratória.
              No caso de não possuir um medidor, clique neste{' '}
              <a
                href="https://youtu.be/Fz0Od-OR_r0"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Link
              </a>{' '}
              e siga os passos descritos.
            </div>
          }
        >
          <InputNumber
            name="breathRate"
            step={1}
            min={MIN_BREATH_RATE}
            max={MAX_BREATH_RATE}
            onChange={val => formik.setFieldValue('breathRate', val)}
          />
        </Form.Item>

        <Form.Item
          required
          validateStatus={getValidateStatus('heartRate')}
          name="heartRate"
          label={`Frequência Cardíaca (${MIN_HEART_RATE} - ${MAX_HEART_RATE}, batimentos por minuto)`}
          extra={
            <div style={{ cursor: 'pointer' }}>
              <InfoCircleOutlined /> – Introduza a sua Frequência Cardíaca. No
              caso de não possuir um medidor, clique neste{' '}
              <a
                href="https://youtu.be/XBkCIAgLSr8"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Link
              </a>{' '}
              e siga os passos descritos.
            </div>
          }
        >
          <InputNumber
            name="heartRate"
            step={1}
            min={MIN_HEART_RATE}
            max={MAX_HEART_RATE}
            onChange={val => formik.setFieldValue('heartRate', val)}
          />
        </Form.Item>

        {!!data?.Report[0] && (
          <Form.Item
            required
            validateStatus={getValidateStatus('symptomsEvolution')}
            name="symptomsEvolution"
            label="Como descreve a evolução dos seus sintomas?"
            extra="Por favor indique comparativamente à sua última monitorização."
          >
            <Radio.Group name="symptomsEvolution" buttonStyle="solid">
              <>
                <Radio.Button value="negative">Pior</Radio.Button>
                <Radio.Button value="neutral">Semelhante</Radio.Button>
                <Radio.Button value="positive">Melhor</Radio.Button>
              </>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          required
          validateStatus={getValidateStatus('hasBeenTested')}
          name="hasBeenTested"
          label="Já fez um teste ao Covid-19?"
          extra="Por favor indique se já efectou um teste de rastreio com resultados ao Covid-19. Este campo é obrigatório."
        >
          <Radio.Group name="hasBeenTested" buttonStyle="solid">
            <Radio.Button value={false}>Não</Radio.Button>
            <Radio.Button value>Sim, já fiz um teste de rastreio</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          className={classnames('can-be-hidden', {
            hide: !formik.values.hasBeenTested,
          })}
          required={!!formik.values.hasBeenTested}
          validateStatus={getValidateStatus('lastTestResult')}
          name="lastTestResult"
          label="Qual foi o resultado do teste?"
          extra="Por favor indique o resultado do último teste de rastreio  de Covid-19 que efectou e recebeu resultados:"
        >
          <Radio.Group name="lastTestResult" buttonStyle="solid">
            <Radio.Button value={false}>Negativo</Radio.Button>
            <Radio.Button value>
              O resultado do teste foi positivo para a presença de Covid-19
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          className={classnames('can-be-hidden', {
            hide: !formik.values.hasBeenTested,
          })}
          required={!!formik.values.hasBeenTested}
          validateStatus={getValidateStatus('lastTestDate')}
          name="lastTestDate"
          label="Indique quando foi que fez o teste?"
          extra="Por favor indique em que data é que fez a recolha para o último teste de rastreio de Covid-19:"
        >
          <DatePicker
            format="DD/MM/YYYY"
            name="lastTestDate"
            disabledDate={date =>
              date.isSameOrAfter(tomorrow) || date.isSameOrBefore(beginOfYear)
            }
            onChange={date => {
              formik.setFieldValue('lastTestDate', date?.toDate());
            }}
          />
        </Form.Item>

        <Form.Item
          required={false}
          validateStatus={getValidateStatus('bloodPressureMin')}
          name="bloodPressureMin"
          label={`Tensão Arterial Mínima (${MIN_MIN_BLOOD_PRESSURE} - ${MAX_MIN_BLOOD_PRESSURE} mmHg)`}
          extra="Introduza a sua Tensão Arterial Mínima. No caso de não possuir um medidor, não necessita de preencher. Este campo é opcional."
        >
          <InputNumber
            name="bloodPressureMin"
            step={1}
            min={MIN_MIN_BLOOD_PRESSURE}
            max={MAX_MIN_BLOOD_PRESSURE}
            onChange={val => {
              // Required so value isn't defined as empty string and breaks query
              if (typeof val !== 'number') {
                formik.values.bloodPressureMin = undefined;
              } else {
                formik.values.bloodPressureMin = val;
              }
            }}
          />
        </Form.Item>

        <Form.Item
          required={false}
          validateStatus={getValidateStatus('bloodPressureMax')}
          name="bloodPressureMax"
          label={`Tensão Arterial Máxima (${MIN_MAX_BLOOD_PRESSURE} - ${MAX_MAX_BLOOD_PRESSURE} mmHg)`}
          extra="Introduza a sua Tensão Arterial Máxima. No caso de não possuir um medidor, não necessita de preencher. Este campo é opcional."
        >
          <InputNumber
            name="bloodPressureMax"
            step={1}
            min={MIN_MAX_BLOOD_PRESSURE}
            max={MAX_MAX_BLOOD_PRESSURE}
            onChange={val => {
              // Required so value isn't defined as empty string and breaks query
              if (typeof val !== 'number') {
                formik.values.bloodPressureMax = undefined;
              } else {
                formik.values.bloodPressureMax = val;
              }
            }}
          />
        </Form.Item>

        <Form.Item
          required={false}
          validateStatus={getValidateStatus('otherSymptoms')}
          name="otherSymptoms"
          label="Possui outros sintomas para alem dos apresentados?"
          extra="Se sim, por favor descreva outros sintomas que eventualmente sentiu. Se não, não necessita de preencher. Este campo é opcional."
        >
          <Input.TextArea name="otherSymptoms" />
        </Form.Item>

        {!formik.isValid && (
          <Alert
            style={{ marginBottom: 15 }}
            type="error"
            message="Existem campos obrigatórios que não estão correctamente preenchidos. Por favor corrija e volte a tentar submeter novamente."
          />
        )}

        <Button
          type="primary"
          htmlType="submit"
          disabled={!formik.dirty || !formik.isValid}
          loading={formik.isSubmitting}
          size="large"
        >
          Submeter dados
        </Button>
      </Form>
    </div>
  );
};

export default ReportForm;
