import React, { FunctionComponent } from 'react';
import { useFormik } from 'formik';
import { string, object } from 'yup';

import {
  Form, Input, notification, Modal,
} from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import {
  User,
  AuthDeleteAccountDocument,
  AuthDeleteAccountMutation,
  AuthDeleteAccountMutationVariables,
} from '../../../graphql/components';
import { authentication } from '../../../stores';

import apolloClient from '../../../graphql/client';

interface DeleteModalProps {
  phoneNumber: string
  visible: boolean
  onClose: () => any
}

const DeleteModal: FunctionComponent<DeleteModalProps> = ({
  phoneNumber,
  visible,
  onClose,
}) => {
  const formik = useFormik<Partial<User>>({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: object().shape({
      phoneNumber: string()
        .required('Este campo é obrigatório.')
        .matches(new RegExp(phoneNumber), {
          message:
            'O número de telemóvel tem que ser igual ao que se encontra registado.',
        }),
    }),
    onSubmit: async (values) => {
      try {
        // call the api
        const res = await apolloClient.mutate<
          AuthDeleteAccountMutation,
          AuthDeleteAccountMutationVariables
        >({
          mutation: AuthDeleteAccountDocument,
        });

        if (!res.data?.deleted) {
          throw new Error('The account wasnt deleted for some reasone');
        }
      } catch (e) {
        notification.error({
          message: 'Ocorreu um erro ao tentar remover os seus dados.',
          description: 'Infelizmente não nos foi possível remover os seus dados. Por favor verifique o acesso à internet ou tente mais tarde.',
        });
        return;
      }

      // success handling
      // close modal
      onClose();

      // logout
      await authentication.logout();

      notification.success({
        message: 'Os seus dados pessoais foram removidos com sucesso!',
      });
    },
  });

  return (
    <Form onChange={formik.handleChange}>
      <Modal
        confirmLoading={formik.isSubmitting}
        className="delete-profile-modal"
        title="Confirmação de remoção dos dados pessoais"
        visible={visible}
        closable
        onCancel={onClose}
        onOk={formik.submitForm}
        okType="danger"
        okText="Remover Dados Pessoais"
        cancelButtonProps={{
          // disabled: formik.isSubmitting,
          type: 'primary',
        }}
      >
        <Paragraph>
          Tem a certeza de que deseja prosseguir com esta operação?
        </Paragraph>

        <Paragraph>
          Para confirmar, insira o seu número de telemóvel abaixo:
        </Paragraph>

        <Form.Item
          required
          validateStatus={
            (formik.isSubmitting && 'validating')
            || (formik.errors?.phoneNumber && 'error')
            || (formik.values.phoneNumber && 'success')
            || undefined
          }
          wrapperCol={{ span: 24 }}
          extra={formik.errors.phoneNumber}
        >
          <Input
            type="text"
            placeholder="912345678"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            maxLength={9}
            disabled={formik.isSubmitting}
            style={{ margin: '0 0 8px 0', width: '70%' }}
          />
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default DeleteModal;
