import { observable } from 'mobx';
import { authentication } from './authentication';
import { isProduction } from '../config';

export const isReady = observable.box<boolean>(false);
export const readyPromise = Promise.resolve(authentication.ready()).then(() => {
  isReady.set(true);
  return true;
});

export { authentication };

if (!isProduction) {
  Object.defineProperty(window, 'stores', {
    get() {
      return {
        authentication,
      };
    },
  });
}
