import React, { FunctionComponent } from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

interface IFilterDropdown {
  options: Array<{ label: string; value: string | number }>
  parameter: string
  onChange: (value: string | number) => unknown
}

const FilterDropdown: FunctionComponent<IFilterDropdown> = ({
  options,
  parameter,
  onChange,
}) => (
  <Dropdown
    overlay={(
      <Menu>
        {options.map((option) => (
          <Menu.Item
            key={option.value}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu>
      )}
    trigger={['click']}
  >
    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
      {parameter}
      {' '}
      <DownOutlined />
    </a>
  </Dropdown>
);

export default FilterDropdown;
