import React, { FunctionComponent } from 'react';
import { useFormik } from 'formik';
import { string, object } from 'yup';

import {
  Form, Input, notification, Modal, Radio,
} from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import {
  Note,
  DoctorPatientDetailsDocument,
  Report,
} from '../graphql/components';
import Spacer from './spacer';
import { DOCTOR_ACTION_LABELS } from '../constants';

interface DoctorNoteModalProps {
  visible: boolean;
  onClose: () => any;
  mutate: any;
  report: Partial<Report>;
}

const DoctorNoteModal: FunctionComponent<DoctorNoteModalProps> = ({
  visible,
  onClose,
  mutate,
  report,
}) => {
  const form = useFormik<Partial<Note>>({
    initialValues: {
      doctorContent: '',
      patientReachability: 'NOT_CONTACTED',
    },
    validationSchema: object().shape({
      doctorContent: string().required('Campo Obrigatório'),
      patientReachability: string()
        .oneOf([
          'NOT_CONTACTED',
          'CONTACTED_BUT_UNAVAILABLE',
          'CALLED_STAY_ISOLATED',
          'CALLED_TAKE_ACTION',
        ])
        .required(),
    }),
    onSubmit: async (values) => {
      try {
        await mutate({
          variables: {
            data: {
              doctorContent: values.doctorContent,
              hasChangedReport: false,
              patientReachability: values.patientReachability,
              reportUUID: report.uuid,
              patientContent: '',
            },
          },
          refetchQueries: [
            {
              query: DoctorPatientDetailsDocument,
              variables: { userUUID: report.userUUID },
            },
          ],
        });
      } catch (e) {
        notification.error({
          message: 'Ocorreu um erro ao tentar adicionar a nota.',
          description: 'Infelizmente não nos foi possível a nota. Por favor verifique o acesso à internet ou tente mais tarde.',
        });
        return;
      }

      // success handling
      // close modal
      onClose();

      notification.success({
        message: 'Nota adicionada com sucesso!',
      });
    },
  });

  return (
    <Form onChange={form.handleChange}>
      <Modal
        confirmLoading={form.isSubmitting}
        className="add-note-modal"
        title="Adicionar nota ao doente"
        visible={visible}
        closable
        onCancel={onClose}
        onOk={form.submitForm}
        okType="default"
        okText="Adicionar Nota"
        okButtonProps={{
          disabled: form.isSubmitting || !form.isValid || !form.dirty,
          type: 'primary',
        }}
      >
        <Paragraph>
          Deixe uma nota para os seus colegas e para o doente acerca do seu
          estao de saúde.
        </Paragraph>

        <Spacer />

        <Form.Item
          required
          validateStatus={
            (form.isSubmitting && 'validating')
            || (form.errors?.doctorContent && 'error')
            || (form.values.doctorContent && 'success')
            || undefined
          }
          wrapperCol={{ span: 24 }}
          extra={form.errors.doctorContent}
        >
          <Input.TextArea
            rows={6}
            placeholder="Deixe o seu comentário"
            name="doctorContent"
            value={form.values.doctorContent}
            disabled={form.isSubmitting}
            style={{ margin: '0 0 8px 0', width: '100%' }}
          />
        </Form.Item>
        <Paragraph>Tomou alguma ação?</Paragraph>
        <Spacer />
        <Form.Item
          required
          validateStatus={
            (form.isSubmitting && 'validating')
            || (form.errors?.patientReachability && 'error')
            || (form.values.patientReachability && 'success')
            || undefined
          }
          wrapperCol={{ span: 24 }}
          extra={form.errors.patientReachability}
        >
          <Radio.Group
            value={form.values.patientReachability}
            buttonStyle="solid"
            onChange={(e) => form.setValues({
              ...form.values,
              patientReachability: e.target.value,
            })}
          >
            <Radio.Button style={{ marginRight: 10 }} value="NOT_CONTACTED">
              {DOCTOR_ACTION_LABELS.NOT_CONTACTED}
            </Radio.Button>
            <Radio.Button value="CONTACTED_BUT_UNAVAILABLE">
              {DOCTOR_ACTION_LABELS.CONTACTED_BUT_UNAVAILABLE}
            </Radio.Button>
          </Radio.Group>
          <Radio.Group
            value={form.values.patientReachability}
            buttonStyle="solid"
            onChange={(e) => form.setValues({
              ...form.values,
              patientReachability: e.target.value,
            })}
          >
            <Radio.Button
              style={{ marginRight: 10, marginTop: 10 }}
              value="CALLED_STAY_ISOLATED"
            >
              {DOCTOR_ACTION_LABELS.CALLED_STAY_ISOLATED}
            </Radio.Button>
            <Radio.Button value="CALLED_TAKE_ACTION">
              {DOCTOR_ACTION_LABELS.CALLED_TAKE_ACTION}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default DoctorNoteModal;
