import { Form, Input, Button, Checkbox } from 'antd';
import { useFormik } from 'formik';
import { string, object, boolean } from 'yup';
import React, { SFC } from 'react';
import { Link } from 'react-router-dom';
import { authentication } from '../../../stores';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const schema = object().shape({
  phoneNumber: string()
    .matches(/9[1236][0-9]{7}/)
    .required('Campo obrigatório'),
  terms: boolean()
    .oneOf([true])
    .required('Obrigatório'),
  personalData: boolean()
    .oneOf([true])
    .required('Obrigatório'),
  healthData: boolean()
    .oneOf([true])
    .required('Obrigatório'),
  juridicData: boolean()
    .oneOf([true])
    .required('Obrigatório'),
  redundantData: boolean()
    .oneOf([true])
    .required('Obrigatório'),
});

const GetPhoneNumber: SFC<{
  phoneNumber: string;
  onSubmit: (value: string) => void | Promise<void>;
}> = ({ onSubmit, phoneNumber }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const formik = useFormik({
    initialValues: {
      phoneNumber,
      terms: false,
      personalData: false,
      healthData: false,
      juridicData: false,
      redundantData: false,
    },
    validateOnChange: true,
    validationSchema: schema,
    async onSubmit(values) {
      const phoneNumber = values.phoneNumber;
      let reCaptchaToken;

      if (executeRecaptcha) {
        reCaptchaToken = await executeRecaptcha('AuthSendVerificationCode');
      }

      await authentication.sendCode(phoneNumber, reCaptchaToken);

      onSubmit(phoneNumber);
    },
  });

  const hasErrors = Object.keys(formik.errors).length > 0;

  return (
    <>
      <Form
        layout="vertical"
        onChange={formik.handleChange}
        onSubmitCapture={formik.handleSubmit}
      >
        <Form.Item
          required
          validateStatus={
            (formik.isSubmitting && 'validating') ||
            (formik?.errors?.phoneNumber && 'error') ||
            (formik.values.phoneNumber && 'success') ||
            undefined
          }
          label="Número de Telemóvel"
          wrapperCol={{ span: 24 }}
          extra="Ser-lhe-á enviado um SMS com um código de 4 digitos para confirmação. Ao confirmar que o número é seu e válido, poupamos tempo aos profissionais de saúde se for necessário ligar."
        >
          <Input
            type="text"
            placeholder="912345678"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            maxLength={9}
            disabled={formik.isSubmitting}
            style={{ margin: '0 0 8px 0', width: '70%' }}
          />
        </Form.Item>

        <Form.Item
          required
          validateStatus={
            (formik.isSubmitting && 'validating') ||
            (formik?.errors?.terms && 'error') ||
            (formik.values.terms && 'success') ||
            undefined
          }
          wrapperCol={{ span: 24 }}
          style={{ marginBottom: 15 }}
        >
          <Checkbox
            onChange={e =>
              formik.setValues({ ...formik.values, terms: e.target.checked })
            }
            checked={formik.values.terms}
            style={{ width: '100%', fontSize: 10 }}
          >
            Li, compreendi e aceito os{' '}
            <Link to="/about/terms-and-conditions">Termos e Condições</Link> de
            utilização da CovidApp.
          </Checkbox>
        </Form.Item>
        <Form.Item
          required
          validateStatus={
            (formik.isSubmitting && 'validating') ||
            (formik?.errors?.personalData && 'error') ||
            (formik.values.personalData && 'success') ||
            undefined
          }
          wrapperCol={{ span: 24 }}
          style={{ marginBottom: 15 }}
        >
          <Checkbox
            onChange={e =>
              formik.setValues({
                ...formik.values,
                personalData: e.target.checked,
              })
            }
            checked={formik.values.personalData}
            style={{ width: '100%', fontSize: 10 }}
          >
            Li e compreendi a{' '}
            <Link to="/about/privacy-policy">Política de Privacidade</Link> que
            descreve como serão tratados os dados pessoais no âmbito da
            CovidApp.
          </Checkbox>
        </Form.Item>
        <Form.Item
          required
          validateStatus={
            (formik.isSubmitting && 'validating') ||
            (formik?.errors?.healthData && 'error') ||
            (formik.values.healthData && 'success') ||
            undefined
          }
          wrapperCol={{ span: 24 }}
          style={{ marginBottom: 15 }}
        >
          <Checkbox
            onChange={e =>
              formik.setValues({
                ...formik.values,
                healthData: e.target.checked,
              })
            }
            checked={formik.values.healthData}
            style={{ width: '100%', fontSize: 10 }}
          >
            Consinto o tratamento pela CovidApp dos meus dados de saúde que eu
            disponibilizar exclusivamente para as finalidades previstas na
            política de privacidade.
          </Checkbox>
        </Form.Item>
        <Form.Item
          required
          validateStatus={
            (formik.isSubmitting && 'validating') ||
            (formik?.errors?.juridicData && 'error') ||
            (formik.values.juridicData && 'success') ||
            undefined
          }
          wrapperCol={{ span: 24 }}
          style={{ marginBottom: 15 }}
        >
          <Checkbox
            onChange={e =>
              formik.setValues({
                ...formik.values,
                juridicData: e.target.checked,
              })
            }
            checked={formik.values.juridicData}
            style={{ width: '100%', fontSize: 10 }}
          >
            Declaro estar informado de que a CovidApp procede a decisões
            individuais automatizadas em relação à semaforização de sintomas,
            sem intervenção humana, que poderão produzir efeitos na minha esfera
            jurídica.
          </Checkbox>
        </Form.Item>
        <Form.Item
          required
          validateStatus={
            (formik.isSubmitting && 'validating') ||
            (formik?.errors?.redundantData && 'error') ||
            (formik.values.redundantData && 'success') ||
            undefined
          }
          wrapperCol={{ span: 24 }}
          style={{ marginBottom: 35 }}
        >
          <Checkbox
            onChange={e =>
              formik.setValues({
                ...formik.values,
                redundantData: e.target.checked,
              })
            }
            checked={formik.values.redundantData}
            style={{ width: '100%', fontSize: 10 }}
          >
            Consinto de forma livre, expressa e informada que os dados pessoais
            que me dizem respeito sejam tratados pela Sales & Novais
            LDA./Mosano, com o número de identificação de pessoa coletiva 514549319, para
            as finalidades e tratamentos indicados na Política de Privacidade no
            âmbito da CovidApp, pelo tempo e duração que se mostrem necessários
            à concretização das referidas finalidades, incluindo na tomada de
            decisões exclusivamente com base no tratamento automatizado.
          </Checkbox>
        </Form.Item>

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={formik.isSubmitting}
          disabled={hasErrors}
        >
          Enviar SMS
        </Button>
      </Form>
    </>
  );
};

export default GetPhoneNumber;
