import { setContext } from 'apollo-link-context';
import { authentication } from '../../stores';

const authLink = setContext((gqlReq, prevContext) => ({
  ...prevContext,
  headers: {
    ...authentication.getHeaders(),
    ...prevContext.headers,
  },
}));

export default authLink;
