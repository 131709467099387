import React, { FC } from 'react';
import { Collapse } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const { Panel } = Collapse;

const AppSecurityAlert: FC<{ className?: string }> = ({ className }) => (
  <Collapse
    bordered={false}
    expandIconPosition="right"
    accordion
    className={classnames('app-security-alert', className)}
  >
    <Panel
      key="1"
      header="Não confunda a CovidApp com a Covid-19 Tracker"
      style={{
        backgroundColor: '#e6f7ff',
        border: '1px solid #91d5ff',
      }}
    >
      <Paragraph>
        Fomos alertados por alguns dos nossos utilizadores para o facto de que a
        {' '}
        <b>CovidApp</b>
        {' '}
        estaria a ser confundida com a{' '}
        <b>Covid-19 Tracker</b>
        .
        <br />
        <b>O Centro Nacional de Cibersegurança de Portugal</b>
        {' '}
        decretou a
        {' '}
        <a
          href="https://www.facebook.com/CentroNacionalCibersegurancaPT/photos/a.1396791080638737/2550202991964201/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>Covid-19 Tracker</b>
          {' '}
          como sendo um{' '}
          <i>ransomware</i>
        </a>
        {' '}
        que coloca em causa a segurança da informação e equipamento do
        utilizador.
        <br />
      </Paragraph>

      <Paragraph>
        A CovidApp não tem qualquer relação com a aplicação
        {' '}
        <b>Covid-19 Tracker</b>
        , e pedimos aos nossos utilizadores que em caso
        de dúvida, consultem a nossa
        {' '}
        <Link to="/about/privacy-policy">Política de Privacidade</Link>
        {' '}
        ou
        confirmem com as autoridades competentes.
      </Paragraph>
    </Panel>
  </Collapse>
);

export default AppSecurityAlert;
