import { mapBoxMaxZoom } from '../../../config';

export const heatmapTriage: mapboxgl.HeatmapPaint = {
  // Increase the heatmap weight based on frequency and property magnitude
  'heatmap-weight': [
    'interpolate',
    ['linear'],
    ['get', 'monitoring'],
    0,
    0,
    10,
    1,
  ],
  // Increase the heatmap color weight weight by zoom level
  // heatmap-intensity is a multiplier on top of heatmap-weight
  'heatmap-intensity': [
    'interpolate',
    ['linear'],
    ['zoom'],
    0,
    1,
    mapBoxMaxZoom,
    3,
  ],
  // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
  // Begin color ramp at 0-stop with a 0-transparancy color
  // to create a blur-like effect.
  'heatmap-color': [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
    'rgba(33,102,172, 0)',
    0.9,
    'rgba(33,102,172, 1)',
  ],
  // Adjust the heatmap radius by zoom level
  'heatmap-radius': [
    'interpolate',
    ['linear'],
    ['zoom'],
    0,
    10,
    5,
    20,
    10,
    50,
    15,
    100,
  ],
  // Transition from heatmap to circle layer by zoom level
  'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0.4, 10, 0.9],
};

export const heatmapInRisk: mapboxgl.HeatmapPaint = {
  // Increase the heatmap weight based on frequency and property magnitude
  'heatmap-weight': ['interpolate', ['linear'], ['get', 'inRisk'], 0, 0, 10, 1],
  // Increase the heatmap color weight weight by zoom level
  // heatmap-intensity is a multiplier on top of heatmap-weight
  'heatmap-intensity': [
    'interpolate',
    ['linear'],
    ['zoom'],
    0,
    1,
    mapBoxMaxZoom,
    3,
  ],
  // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
  // Begin color ramp at 0-stop with a 0-transparancy color
  // to create a blur-like effect.
  'heatmap-color': [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
    'rgba(255,200,200,0)',
    0.9,
    'rgba(255,201,81,1)',
  ],
  // Adjust the heatmap radius by zoom level
  'heatmap-radius': [
    'interpolate',
    ['linear'],
    ['zoom'],
    0,
    5,
    5,
    15,
    10,
    40,
    15,
    80,
  ],
  // Transition from heatmap to circle layer by zoom level
  'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0.4, 10, 0.9],
};

export const heatmapInfected: mapboxgl.HeatmapPaint = {
  // Increase the heatmap weight based on frequency and property magnitude
  'heatmap-weight': [
    'interpolate',
    ['linear'],
    ['get', 'infected'],
    0,
    0,
    10,
    1,
  ],
  // Increase the heatmap color weight weight by zoom level
  // heatmap-intensity is a multiplier on top of heatmap-weight
  'heatmap-intensity': [
    'interpolate',
    ['linear'],
    ['zoom'],
    0,
    2,
    5,
    10,
    10,
    30,
    15,
    60,
  ],
  // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
  // Begin color ramp at 0-stop with a 0-transparancy color
  // to create a blur-like effect.
  'heatmap-color': [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    0,
    'rgba(255,101,101,0)',
    0.5,
    'rgba(255,101,101,1)',
  ],
  // Adjust the heatmap radius by zoom level
  'heatmap-radius': [
    'interpolate',
    ['linear'],
    ['zoom'],
    0,
    2,
    mapBoxMaxZoom,
    20,
  ],
  // Transition from heatmap to circle layer by zoom level
  'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0.8, 10, 1],
};
