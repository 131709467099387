import React, { FC, useMemo, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import classnames from 'classnames';
import useInterval from '@use-it/interval';
import moment from 'moment';
import { AboutStatsQuery } from '../../../graphql/components';

interface ITotalStats {
  monitoring: number;
  inRisk: number;
  infected: number;
}

const ControlPanel: FC<{
  loading: boolean;
  data?: AboutStatsQuery;
}> = ({ data, loading }) => {
  const [now, setNow] = useState(moment());

  useInterval(() => {
    setNow(moment());
  }, 1000 * 2);

  const totalStats = useMemo(() => {
    const totalStats: ITotalStats = {
      monitoring: 0,
      inRisk: 0,
      infected: 0,
    };

    if (data?.stats.byZipCode) {
      for (const statsByZipCode of data.stats.byZipCode) {
        totalStats.monitoring += statsByZipCode.totalUsers;
        totalStats.inRisk += statsByZipCode.usersWithLowRisk + statsByZipCode.usersWithHighRisk;
        totalStats.infected += statsByZipCode.usersInfected;
      }
    }

    return totalStats;
  }, [loading, data]);

  return (
    <div className="control-panel">
      <Title level={2}>Estatísticas</Title>
      <Paragraph>
        Com base nos dados que os nossos utilizadores reportam, esta é a situação actual em
        {' '}
        <b>Portugal</b>
        :
        {' '}
        <br />
        <br />
        <LegendBox name="monitoring" />
        {' '}
        <b>Em Monitorização:</b>
        {' '}
        {totalStats.monitoring}
        <br />
        <LegendBox name="in-risk" />
        {' '}
        <b>Casos Suspeitos:</b>
        {' '}
        {totalStats.inRisk}
        <br />
        <LegendBox name="infected" />
        {' '}
        <b>Infectados:</b>
        {' '}
        {totalStats.infected}
        <br />
        <br />
        Actualizado
        {' '}
        {moment(data?.stats.lastUpdatedAt).from(now)}
        <br />
      </Paragraph>
      <br />
      <a target="_blank" rel="noopener noreferrer" href="https://covid19.min-saude.pt/ponto-de-situacao-atual-em-portugal/">
        Veja as informações oficiais
      </a>
    </div>
  );
};

const LegendBox: FC<{ name: string }> = ({ name }) => (
  <div className={classnames('box', { [name]: !!name })} />
);

export default ControlPanel;
