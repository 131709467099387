import { Entity } from './typings';

const entities: Entity[] = [
  {
    key: 'MOSANO',
    name: 'Mosano',
    url: 'https://mosano.eu',
  },
  {
    key: 'CHUSJ',
    name: 'Centro Hospitalar Universitário de S. João do Porto',
  },
  {
    key: 'CHL',
    name: 'Centro Hospitalar de Leiria',
  },
  {
    key: 'CHUC',
    name: 'Centro Hospitalar e Universitário de Coimbra',
  },
  {
    key: 'CHUP',
    name: 'Centro Hospitalar e Universitário do Porto',
  },
  {
    key: 'FMUP',
    name: 'Faculdade de Medicina da Universidade do Porto',
  },
  {
    key: 'CHVNG',
    name: 'Centro Hospitalar Vila Nova de Gaia/Espinho',
  },
  {
    key: 'ACSBTVSN',
    name:
      'Agrupamento Centros de Saúde do Baixo Tâmega III - Vale do Sousa Norte',
  },
  {
    key: 'IPOP',
    name: 'Instituto Português de Oncologia do Porto',
  },
  {
    key: 'CHTMAD',
    name: 'Centro Hospitalar de trás-os-montes e Alto Douro',
  },
  {
    key: 'DGS',
    name: 'Direção-Geral da Saúde',
    url: 'https://dgs.pt',
  },
  {
    key: 'ACES VSN',
    name: 'Agrupamento de Centros de Saúde',
    url: '',
  },
  {
    key: 'BROSETA',
    name: 'Broseta',
    url: 'https://broseta.pt',
  },

  {
    key: 'TANGIVEL',
    name: 'Tangível',
    url: 'https://tangivel.com',
  },
  {
    key: 'LANDING_JOBS',
    name: 'Landing Jobs',
  },
  {
    key: 'CHTS',
    name: 'Centro Hospitalar do Tâmega e Sousa',
  },
  {
    key: 'CMIN',
    name: 'Centro Materno Infantil do Norte',
  },
];

export default entities;
