import { Card, message } from 'antd';
import React, { FunctionComponent, useState, useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Redirect } from 'react-router-dom';
import withMainLayout from '../../../layouts/withMainLayout';
import GetPhoneNumber from './get-phone-number';
import GetCode from './get-code';
import { authentication } from '../../../stores';
import Spacer from '../../../components/spacer';
import { isSentryActive, recaptchaSiteKey } from '../../../config';
import Sentry from '../../../sentry';

const Login: FunctionComponent = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [step, setStep] = useState<'PHONE_NUMBER' | 'CODE'>('PHONE_NUMBER');
  useEffect(() => {
    return () => {
      try {
        const scripts = document.getElementsByTagName('script');
        Array.from(scripts || []).forEach(script => {
          if (/recaptcha/i.test(script?.src || '')) {
            script?.parentNode?.removeChild(script);
          }
        });
      } catch (_e) {
        // silently ignore error
      }
      try {
        const bodyElement = document.getElementsByClassName('grecaptcha-badge');
        bodyElement?.[0]?.remove?.();
      } catch (_e) {
        // silently ignore error
      }
    };
  }, []);
  const { isProfileComplete } = authentication;

  if (authentication.isAuthenticated) {
    return (
      <Redirect to={isProfileComplete ? '/' : '/account/profile?afterLogin'} />
    );
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
      <div style={{ marginTop: '5px' }}>
        <Card
          bordered={false}
          style={{
            maxWidth: '600px',
            margin: 'auto',
            height: '100%',
            boxShadow:
              '0px 6.17824px 21.5365px rgba(0, 0, 0, 0.030405), 0px 17.9145px 49.8481px rgba(0, 0, 0, 0.0358662), 0px 16px 64px rgba(0, 0, 0, 0.05)',
          }}
          bodyStyle={{ paddingBottom: '40px' }}
        >
          <Title level={2}>Autenticação</Title>
          <Spacer />
          {step === 'PHONE_NUMBER' && (
            <GetPhoneNumber
              phoneNumber={phoneNumber}
              onSubmit={async pn => {
                try {
                  await setPhoneNumber(pn);
                  await setStep('CODE');
                } catch (e) {
                  message.error(
                    'Lamentamos, ocorreu um erro a enviar o código'
                  );
                  if (isSentryActive) {
                    Sentry.captureException(e);
                  }
                }
              }}
            />
          )}

          {step === 'CODE' && (
            <GetCode
              phoneNumber={phoneNumber}
              code={code}
              onChangeNumber={() => setStep('PHONE_NUMBER')}
              onResendCode={() => authentication.sendCode(phoneNumber)}
              onSubmit={async cd => {
                try {
                  await setCode(cd);
                  message.success('Sessão iniciada com sucesso!');
                } catch (e) {
                  message.error(
                    'Lamentamos, ocorreu um erro a validar o código'
                  );

                  if (isSentryActive) {
                    Sentry.captureException(e);
                  }
                }
              }}
            />
          )}
        </Card>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default withMainLayout(Login);
