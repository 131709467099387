import React, { useState, SFC } from 'react';
import { useFormik } from 'formik';
import { string, object, date, boolean } from 'yup';
import './profile.scss';

import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Card,
  notification,
  Result,
  Spin,
  Radio,
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';
import { RouteComponentProps } from 'react-router';
import withMainLayout from '../../../layouts/withMainLayout';
import { User } from '../../../graphql/components';
import { authentication } from '../../../stores';
import AppSecurityAlert from '../../../components/app-security-alert';
import { check } from '../../../helpers/id-validator';

import DeleteModal from './delete-modal';

const now = moment();

const schema = object().shape({
  name: string().required(),
  email: string()
    .email()
    .required(),
  dob: date()
    .max(new Date())
    .required(),
  gender: string().required(),
  zipCode: string()
    .matches(/^\d{4}$|^\d{4}-\d{3}$/)
    .required(),
  idCardNumber: string()
    .nullable()
    .notRequired()
    .test(
      'isPtIdCard',
      'Número de cartão inválido',
      value => !value || check(value, true)
    ),
  hasDiabetes: boolean().required(),
  hasHypertension: boolean().required(),
  hypertensionMedication: string(),
  chronicMedication: string(),
});

const Profile: SFC<RouteComponentProps> = ({ location }) => {
  const [sent, setSent] = useState<boolean>(false);
  const [datePickerMode, setDatePickerMode] = useState('year');
  const [isVisible, setVisible] = useState<boolean>(false);
  const { userData } = authentication;

  const formik = useFormik<Partial<User>>({
    initialValues: { ...userData },
    validationSchema: schema,
    onSubmit: async values => {
      try {
        const data: Partial<User> = {
          name: values.name,
          email: values.email,
          gender: values.gender,
          idCardNumber: values.idCardNumber || undefined,
          dob: values.dob,
          zipCode: values.zipCode,
          hasDiabetes: values.hasDiabetes,
          hasHypertension: values.hasHypertension,
          hypertensionMedication: values.hypertensionMedication,
          chronicMedication: values.chronicMedication,
        };

        await authentication.updateUserData(data);

        notification.success({
          message: 'Os seus dados pessoais foram actualizados com sucesso!',
        });

        await setSent(true);
      } catch (e) {
        notification.error({
          message: 'Ocorreu um erro ao tentar guardar os seus dados.',
          description:
            'Infelizmente não nos foi possível guardar os seus dados. Por favor verifique o acesso à internet ou tente mais tarde.',
        });
      }
    },
  });

  if (!userData) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 50 }}>
        <Spin />
      </div>
    );
  }

  const getValidateStatus = (field: keyof User) =>
    (formik.isSubmitting && 'validating') ||
    (formik?.errors[field] && 'error') ||
    undefined;

  const isAfterLogin = location.search.match(/afterLogin/);
  const showAccountDelete = !isAfterLogin && !sent;

  return (
    <div id="account-profile" style={{ marginTop: '3%', marginBottom: '3%' }}>
      <DeleteModal
        phoneNumber={userData?.phoneNumber}
        visible={isVisible}
        onClose={() => setVisible(false)}
      />

      <div
        style={{
          maxWidth: '600px',
          margin: 'auto',
          height: '100%',
        }}
      >
        <Card
          title="A sua conta e dados pessoais"
          bordered={false}
          style={{
            boxShadow:
              '0px 6.17824px 21.5365px rgba(0, 0, 0, 0.030405), 0px 17.9145px 49.8481px rgba(0, 0, 0, 0.0358662), 0px 16px 64px rgba(0, 0, 0, 0.05)',
          }}
          bodyStyle={{ padding: '40px' }}
        >
          {sent && (
            <Result
              status="success"
              title="Os seus dados pessoais foram actualizados com sucesso!"
              extra={[
                <Link to="/patient/dashboard" key={0}>
                  <Button key="console">Voltar ao Painel Principal</Button>
                </Link>,
              ]}
            />
          )}

          {!sent && (
            <>
              <p className="section-text">
                Por favor mantenha os seus dados pessoais atualizados para que
                os responsáveis de saúde possam contactá-lo e validar a sua
                informação.
              </p>
              <Form
                layout="vertical"
                onSubmitCapture={formik.handleSubmit}
                onChangeCapture={e => {
                  const target = (e.target as unknown) as {
                    name: string;
                    value?: string;
                  };
                  const name = target?.name;
                  let val: string | boolean | undefined = target?.value;

                  // Boolean
                  if (['hasDiabetes', 'hasHypertension'].indexOf(name) > -1) {
                    val = val === 'true';
                  }

                  formik.setFieldValue(name, val);
                }}
              >
                {/* <Form.Item
                required
                label="Identificação"
                wrapperCol={{ span: 24 }}
              >
                <Input
                  disabled
                  placeholder="Identificação"
                  name="uuid"
                  value={formik.values.uuid}
                />
              </Form.Item> */}

                {/* <Form.Item
                  required
                  label="Número de telemóvel"
                  wrapperCol={{ span: 24 }}
                  extra={`Este campo é obrigatório. Nós fornecemos o seu contacto telefónico a profissionais de saúde no caso de os seus sintomas serem identificados como suspeitos.`}
                >
                  <Input
                    disabled
                    placeholder="Número de Telemóvel"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    style={{ marginBottom: '0.5em' }}
                  />
                </Form.Item> */}
                <Form.Item
                  required
                  label="Nome"
                  validateStatus={getValidateStatus('name')}
                  wrapperCol={{ span: 24 }}
                  extra="Este campo é obrigatório."
                >
                  <Input
                    disabled={formik.isSubmitting}
                    placeholder="Escreva o seu Nome Completo"
                    name="name"
                    value={formik.values.name || ''}
                    onChange={formik.handleChange}
                    style={{ marginBottom: '0.5em' }}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Email"
                  validateStatus={getValidateStatus('email')}
                  wrapperCol={{ span: 24 }}
                  extra="Este campo é obrigatório. Poderemos fazer algumas das comunicações via email, tais como os de lembretes de monitorização."
                >
                  <Input
                    disabled={formik.isSubmitting}
                    placeholder="Email"
                    name="email"
                    value={formik.values.email || ''}
                    onChange={formik.handleChange}
                    style={{ marginBottom: '0.5em' }}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Género"
                  validateStatus={getValidateStatus('gender')}
                  wrapperCol={{ span: 24 }}
                  extra="Este campo é obrigatório."
                >
                  <Select
                    defaultValue={formik.values.gender || ''}
                    onChange={gender => {
                      if (gender) {
                        formik.setFieldValue('gender', gender);
                      }
                    }}
                    style={{ marginBottom: '0.5em' }}
                  >
                    <Select.Option value="Masculino">Masculino</Select.Option>
                    <Select.Option value="Feminino">Feminino</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  required
                  label="Data de Nascimento"
                  validateStatus={getValidateStatus('dob')}
                  wrapperCol={{ span: 24 }}
                  extra="Este campo é obrigatório."
                >
                  <DatePicker
                    disabled={formik.isSubmitting}
                    placeholder="Data de Nascimento"
                    name="dob"
                    format="DD/MM/YYYY"
                    inputReadOnly
                    showToday={false}
                    mode={datePickerMode as any}
                    style={{ width: '100%', marginBottom: '0.5em' }}
                    value={
                      (!!formik.values.dob && moment(formik.values.dob)) ||
                      undefined
                    }
                    onPanelChange={(_, mode) => setDatePickerMode(mode)}
                    disabledDate={current => current?.isAfter(now)}
                    onChange={date => {
                      if (date) {
                        formik.setFieldValue('dob', date);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Código Postal"
                  validateStatus={getValidateStatus('zipCode')}
                  wrapperCol={{ span: 24 }}
                  extra="Este campo é obrigatório. (Ex: 4400 ou 4400-023) Com base no código postal, vamos reencaminhar os seus relatórios de sintomas aos médicos mais próximos."
                >
                  <Input
                    disabled={formik.isSubmitting}
                    placeholder="Código Postal"
                    name="zipCode"
                    value={formik.values.zipCode || ''}
                    onChange={formik.handleChange}
                    style={{ marginBottom: '0.5em' }}
                    maxLength={8}
                  />
                </Form.Item>

                <Form.Item
                  required={false}
                  label="Cartão de Cidadão"
                  validateStatus={getValidateStatus('idCardNumber')}
                  wrapperCol={{ span: 24 }}
                  extra="Este campo é opcional. No entanto é relevante para que os profissionais da saúde possam aceder ao seu histórico clínico."
                >
                  <Input
                    disabled={formik.isSubmitting}
                    placeholder="Cartão de Cidadão"
                    name="idCardNumber"
                    value={formik.values.idCardNumber?.toUpperCase() || ''}
                    onChange={formik.handleChange}
                    style={{ marginBottom: '0.5em' }}
                  />
                </Form.Item>

                <Form.Item
                  required
                  validateStatus={getValidateStatus('hasDiabetes')}
                  label="Tem Diabetes?"
                  extra="Por favor indique caso tenha diabetes. Este campo é obrigatório."
                >
                  <Radio.Group
                    name="hasDiabetes"
                    value={formik.values.hasDiabetes}
                    defaultValue={formik.values.hasDiabetes}
                    buttonStyle="solid"
                    style={{ margin: '10px 0' }}
                  >
                    <Radio.Button value={false}>Não</Radio.Button>
                    <Radio.Button value>Sim, tenho Diabetes</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  required
                  validateStatus={getValidateStatus('hasHypertension')}
                  label="Tem hipertensão diagnosticada?"
                  extra="Marque em caso de história ou antecedentes de hipertensão arterial (tensão alta). Este campo é obrigatório."
                >
                  <Radio.Group
                    name="hasHypertension"
                    value={formik.values.hasHypertension}
                    defaultValue={formik.values.hasHypertension}
                    buttonStyle="solid"
                    style={{ margin: '10px 0' }}
                  >
                    <Radio.Button value={false}>Não</Radio.Button>
                    <Radio.Button value>Sim, tenho Hipertensão</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                {formik.values.hasHypertension ? (
                  <Form.Item
                    required={false}
                    label="Medicação para Hipertensão"
                    validateStatus={getValidateStatus('hypertensionMedication')}
                    wrapperCol={{ span: 24 }}
                    extra="Este campo é opcional. No entanto é relevante para que os profissionais da saúde possam aceder ao seu histórico clínico."
                  >
                    <Input
                      disabled={formik.isSubmitting}
                      placeholder="Medicação de Hipertensão"
                      name="hypertensionMedication"
                      value={formik.values.hypertensionMedication || ''}
                      onChange={formik.handleChange}
                      style={{ marginBottom: '0.5em' }}
                    />
                  </Form.Item>
                ) : null}

                <Form.Item
                  required={false}
                  label="Medicação Crónica"
                  validateStatus={getValidateStatus('chronicMedication')}
                  wrapperCol={{ span: 24 }}
                  extra="Este campo é opcional. No entanto é relevante para que os profissionais da saúde possam aceder ao seu histórico clínico."
                >
                  <Input
                    disabled={formik.isSubmitting}
                    placeholder="Medicação crónica"
                    name="chronicMedication"
                    value={formik.values.chronicMedication || ''}
                    onChange={formik.handleChange}
                    style={{ marginBottom: '0.5em' }}
                  />
                </Form.Item>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={!formik.isValid || !formik.dirty}
                    loading={formik.isSubmitting}
                  >
                    Guardar dados pessoais
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Card>

        {showAccountDelete && (
          <>
            <AppSecurityAlert className="alert" />
            <Card
              className="delete-account"
              title="Deseja apagar os seus dados pessoais?"
            >
              <Paragraph>
                Lembramos que somos uma comunidade a tentar ajudar casos
                suspeitos ou confirmados do Covid19. O sucesso da nossa missão
                provém única e exclusivamente da quantidade de pessoas que
                utilizam a <b>CovidApp</b>
                .
                <br />
              </Paragraph>
              <Paragraph>
                Os seus dados pessoais apenas poderão ser fornecidos a
                Profissionais da Saúde{' '}
                <b>caso os seus relatórios indiquem uma situação de risco</b>
                , para que os mesmos o possam contactar.
                <br />
              </Paragraph>
              <Paragraph>
                Se ainda assim não pretende continuar com a <b>CovidApp</b> e
                decidir abandonar a plataforma, clique no botão abaixo.
              </Paragraph>

              <Button
                type="danger"
                size="large"
                onClick={e => setVisible(true)}
              >
                <WarningOutlined /> Desejo remover os meus dados pessoais
              </Button>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default withMainLayout(Profile);
