import coordsMap from '../data/zip-to-coords.json';

interface ICoords {
  lat: number;
  lng: number;
  acc: number;
}

function getCoordsFromZip(zipCode: string): ICoords | null {
  // @ts-ignore
  return coordsMap[zipCode] || null;
}

export default getCoordsFromZip;
