import React, { FunctionComponent } from 'react';
import { Table } from 'antd';
import moment from 'moment';

import { ColumnsType } from 'antd/lib/table';
import { Report } from '../graphql/components';
import { SEVERITY_LABELS_NUMBER } from '../constants';
import { authentication } from '../stores/authentication';

const renderEvolution = (evolution?: string) => {
  if (evolution === 'positive') {
    return <span style={{ color: 'green' }}>Melhorou</span>;
  } if (evolution === 'negative') {
    return <span style={{ color: 'red' }}>Piorou</span>;
  }
  return 'Manteve';
};

const renderBoolean = (value: boolean | undefined) => {
  if (value === undefined || value === null) {
    return '-';
  }
  return value ? 'Sim' : 'Não';
};

const renderSeverity = (severity: number) => {
  let color;
  if (severity === 1) {
    color = 'green';
  } else if (severity === 2) {
    color = '#F2C500';
  } else {
    color = 'red';
  }
  return <span style={{ color }}>{SEVERITY_LABELS_NUMBER[severity]}</span>;
};

const renderDate = (value: moment.Moment | undefined) => {
  if (value === undefined || value === null) {
    return '-';
  }
  return moment(value).format('DD MMM @ HH:mm');
};

const isDoctor = authentication?.role === 'doctor';

const columns: ColumnsType<Partial<Report>> = [
  {
    title: 'Submetido a',
    width: 160,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: renderDate,
  },
  {
    title: 'Triagem',
    width: 100,
    dataIndex: 'severity',
    key: 'severity',
    render: (severity: number) => (severity ? renderSeverity(severity) : '-'),
  },
  {
    title: 'Data sintomas',
    width: 100,
    // title: "Data dos últimos sintomas",
    dataIndex: 'symptomsBeginDate',
    key: 'symptomsBeginDate',
    render: (symptomsBeginDate) => (symptomsBeginDate ? moment(symptomsBeginDate).format('DD MMM') : '-'),
  },
  {
    title: 'Contacto Infect.',
    width: 100,
    dataIndex: 'hadContactWithInfectedPatients',
    key: 'hadContactWithInfectedPatients',
    render: renderBoolean,
  },
  {
    title: 'Tem sintomas',
    width: 140,
    dataIndex: 'hasSymptoms',
    key: 'hasSymptoms',
    render: renderBoolean,
  },
  {
    title: 'Tem tosse',
    width: 140,
    dataIndex: 'hasCough',
    key: 'hasCough',
    render: renderBoolean,
  },
  {
    title: 'Temp.',
    width: 75,
    dataIndex: 'temperature',
    key: 'temperature',
  },
  {
    title: isDoctor ? 'Dispneia (1-5)' : 'Dificuldade Respiratória',
    width: 140,
    // title: "Dificuldade em respirar (1-5)",
    dataIndex: 'breathingTroubleRate',
    key: 'breathingTroubleRate',
  },
  {
    title: 'Freq. Respiratória',
    width: 140,
    // title: "Frequência Respiratória (ciclos/min)",
    dataIndex: 'breathRate',
    key: 'breathRate',
  },
  {
    title: 'Freq. Cardíaca',
    width: 120,
    // title: "Frequência Cardíaca (batimentos/min)",
    dataIndex: 'heartRate',
    key: 'heartRate',
  },
  {
    title: 'Evolução',
    width: 140,
    dataIndex: 'symptomsEvolution',
    key: 'symptomsEvolution',
    render: renderEvolution,
  },
  {
    title: 'Fez teste a Covid-19',
    width: 140,
    dataIndex: 'hasBeenTested',
    key: 'hasBeenTested',
    render: renderBoolean,
  },
  {
    title: 'Resultado do último teste',
    width: 140,
    dataIndex: 'lastTestResult',
    key: 'lastTestResult',
    render: renderBoolean,
  },
  {
    title: 'Data do último teste',
    width: 140,
    dataIndex: 'lastTestDate',
    key: 'lastTestDate',
    render: renderDate,
  },
  {
    title: 'Tensão Arterial',
    width: 140,
    // title: "Tensão Arterial (Min-Max mmHg)",
    dataIndex: 'bloodPressure',
    key: 'bloodPressure',
    render(_, { bloodPressureMin, bloodPressureMax }) {
      return bloodPressureMin && bloodPressureMax
        ? `${bloodPressureMin || '_'}-${bloodPressureMax || '_'}`
        : '-';
    },
  },
  {
    title: 'Outros sintomas',
    width: 300,
    dataIndex: 'otherSymptoms',
    key: 'otherSymptoms',
  },
];

export interface ReportsTableProps {
  reports: Partial<Report>[];
}

const ReportsTable: FunctionComponent<ReportsTableProps> = ({ reports }) => (
  <Table<Partial<Report>>
      // size="small"
    scroll={{
      x: 'scroll',
    }}
      // tableLayout="fixed"
    dataSource={reports}
    rowKey="uuid"
    columns={columns}
  />
);

export default ReportsTable;
