import React, { FunctionComponent } from 'react';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';
import withMainLayout from '../../../layouts/withMainLayout';
import Spacer from '../../../components/spacer';

const TermsConditions: FunctionComponent = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    }}
    className="section"
  >
    <Title>
      Bem-vindo à <span className="bold">CovidApp</span>
    </Title>
    <Spacer />
    <p>
      Estes Termos e Condições de uso da aplicação e website "
      <span className="bold">CovidApp</span>" ("
      <span className="bold">T&C</span>
      ") sumarizam as regras a considerar para utilizar a{' '}
      <span className="bold">CovidApp</span>.
    </p>
    <p>
      Este projecto é o resultado de um esforço de vários indivíduos que se
      juntaram para dar resposta aos problemas nesta crise que atravessamos.
      Alguns em quarentena preventiva em casa, outros nos hospitais ou
      respetivas instituições que os albergam coordenaram, idealizaram e
      desenvolveram este produto num curtíssimo espaço de tempo.
    </p>
    <p>
      A Faculdade de Medicina da Universidade do Porto (“FMUP”) colabora com o
      projecto, através da integração de uma Interface de Programação de
      Aplicação (“API”) de forma a permitir a partilha de dados agregados para a
      trabalhar a rede neural.
    </p>
    <p>
      Estes <span className="bold">T&C</span> são complementados, em tudo o que
      não estiver previsto neste documento, pela Política de Privacidade que
      pode também ser consultada
      <Link to="/about/privacy-policy"> aqui.</Link>
    </p>
    <p>
      A <span className="bold">CovidApp</span> destina-se a pessoas singulares,
      residentes em Portugal e maiores de idade (“
      <span className="bold">Utilizador</span>” ou “
      <span className="bold">Utilizadores</span>
      ”), sendo a sua utilização regulada pelos{' '}
      <span className="bold">T&C</span> disponíveis à data de cada acesso à{' '}
      <span className="bold">CovidApp</span> pelos{' '}
      <span className="bold">Utilizadores</span>, implicando a sua utilização
      uma aceitação pelo <span className="bold">Utilizador</span>.
    </p>
    <p>
      Caso não concorde com o disposto nos <span className="bold">T&C</span>,
      pedimos-lhe que não utilize a <span className="bold">CovidApp</span>, uma
      vez que qualquer utilização que faça da mesma ou dos conteúdos nela
      disponibilizados implicará a sua aceitação
    </p>
    <p>
      A <span className="bold">CovidApp</span> pode, a qualquer momento
      modificar estes <span className="bold">T&C</span>, notificando sempre o{' '}
      <span className="bold">Utilizador</span> através de um banner.
    </p>
    <p>
      Caso o <span className="bold">Utilizador</span> continue a utilizar a{' '}
      <span className="bold">CovidApp</span>, considera-se que aceita os termos
      e condições alterados.
    </p>
    <p>
      Para aceder à <span className="bold">CovidApp</span>, o{' '}
      <span className="bold">Utilizador</span> terá de se registar (“Registo”) e
      será necessário disponibilizar um conjunto de dados pessoais os quais
      serão tratados nos termos da Política de Privacidade (link em cima). O{' '}
      <span className="bold">Utilizador</span> deve manter confidenciais os
      dados de acesso ao seu Registo.
    </p>
    <p>
      O Registo pode ser cancelado pela <span className="bold">CovidApp </span>
      se incumprir as regras aplicáveis à utilização da{' '}
      <span className="bold">CovidApp</span> (nomeadamente os
      <span className="bold"> T&C</span> e a Política de Privacidade).
    </p>
    <p>
      O <span className="bold">Utilizador</span> poderá, igualmente, em qualquer
      altura, solicitar o cancelamento do seu Registo através do separador
      dedicado na <span className="bold">CovidApp</span>.
    </p>
    <p>
      A <span className="bold">CovidApp</span> tem o direito exclusivo de, a
      todo o tempo, suspender, bloquear, interromper ou fazer cessar parcial ou
      totalmente, o acesso à <span className="bold">CovidApp</span>, em especial
      nas operações de gestão, manutenção, reparação, alteração ou modernização
      ou encerrar, definitiva ou temporariamente, parcial ou totalmente, a
      qualquer momento, de acordo com a sua vontade, sem aviso prévio.
    </p>
    <p>
      É expressamente proibida a utilização da{' '}
      <span className="bold">CovidApp</span> para disponibilizar ou transmitir
      qualquer tipo de material que contenha ou possa conter vírus, defeitos,
      ramsonware ou outro item ou códigos informáticos, ficheiros ou programas
      que possam interromper, destruir ou limitar a funcionalidade de qualquer
      equipamento ou sistema informático ou equipamento de comunicações ou ainda
      que possa afetar negativamente a imagem da{' '}
      <span className="bold">CovidApp</span> .
    </p>
    <p>
      O <span className="bold">Utilizador</span> não deverá, ainda,
      disponibilizar ou transmitir qualquer conteúdo não solicitado ou não
      autorizado como SPAM ou recolher, disponibilizar, transmitir ou explorar
      informações sobre outros <span className="bold">Utilizadores</span> para
      fins não autorizados, incluindo para fins comerciais ou que sejam por
      qualquer forma pagos.
    </p>
    <p>
      O <span className="bold">Utilizador</span> reconhece e aceita que o
      conteúdo apresentado na <span className="bold">CovidApp</span> (textos,
      imagens, gráficos, som e animação e todas as outras informações
      disponibilizadas) está protegido por direitos de propriedade intelectual
      da titularidade da <span className="bold">CovidApp</span>.
    </p>
    <p>
      A <span className="bold">CovidApp</span> concede ao{' '}
      <span className="bold">Utilizador</span> uma licença pessoal,
      intransmissível, mundial, não passível de sublicenciamento, não exclusiva,
      livre e revogável de acesso e utilização da
      <span className="bold">CovidApp</span>, conforme necessário para aceder e
      utilizar a mesma, desde que este compra os presentes{' '}
      <span className="bold">T&C</span>.
    </p>
    <p>
      A <span className="bold">CovidApp</span> está disponível para:
      <li>Sistema Android, versão 6 ou superior na Google Play Store.</li>
      <li>iOS (iPhone), versão 10 ou superior na Apple Appstore.</li>
    </p>
    <p>
      O <span className="bold">Utilizador</span> reconhece que estes
      <span className="bold"> T&C</span> são celebrados apenas entre a{' '}
      <span className="bold">CovidApp</span> e o{' '}
      <span className="bold"> Utilizador</span>, e não com outras plataformas.
    </p>

    <p>
      Para os <span className="bold">Utilizador</span>
      es poderem utilizar determinadas funcionalidades e elementos da{' '}
      <span className="bold">CovidApp</span> terão de disponibilizar dados
      pessoais.
    </p>
    <p>
      A informação disponibilizada na <span className="bold">CovidApp</span>,
      ainda que inclua qualquer conselho e/ou recomendação, destina-se
      exclusivamente a fins informativos não vinculativos, não pretendendo
      constituir aconselhamento médico nem se destina a ser utilizado para
      diagnósticos médicos ou tratamentos.
    </p>
    <p>
      A informação disponibilizada na <span className="bold">CovidApp</span> não
      pretende substituir qualquer serviço, recomendação, conselho ou dado
      emitido ou disponibilizado por uma entidade governamental, administrativa
      ou estadual, assim como entidades privadas do setor da saúde ou
      profissionais de saúde, familiarizados com o historial clínico e situação
      concreta do <span className="bold">Utilizador</span>.
    </p>
    <p>
      O <span className="bold">Utilizador</span> deverá, antes de iniciar
      qualquer tratamento, seguir as indicações das autoridades de saúde e, se
      apropriado, o conselho de um médico ou outro profissional de saúde
      qualificado.
    </p>

    <Title>
      A <span className="bold">CovidApp</span> também não garante que:
    </Title>
    <p>
      <li>
        Qualquer conselho, recomendação ou informação, de qualquer tipo, da
        responsabilidade da <span className="bold">CovidApp</span> ,
        apresentados ou disponibilizados através da{' '}
        <span className="bold">CovidApp</span>, ou obtidos através da sua
        utilização, sejam atuais, rigorosos, completos ou estejam isentos de
        erros – a <span className="bold">CovidApp</span> informa que não assume
        qualquer dever jurídico nesta matéria;
      </li>
      <li>
        Qualquer material ou outro tipo de conteúdo disponibilizado por
        terceiros através da <span className="bold">CovidApp</span> seja seguro,
        legal ou adequado;
      </li>
      As qualidades, funcionalidades ou características dos produtos, serviços,
      informações ou outros materiais ou conteúdos divulgados na{' '}
      <span className="bold">CovidApp</span> preencham qualquer expectativa dos{' '}
      <span className="bold">Utilizador</span>
      es.
      <li>
        A <span className="bold">CovidApp</span> não será responsável por erros
        que possam ocorrer devido a irregularidades do sistema ou falha
        (temporária ou permanente) da <span className="bold">CovidApp</span>,
        das aplicações ou de outras ferramentas. A{' '}
        <span className="bold">CovidApp</span> não se responsabilizará por
        quaisquer danos resultantes da utilização indevida ou da impossibilidade
        de utilização da <span className="bold">CovidApp</span>.
      </li>
    </p>
    <p>
      Enviamos os nossos melhores esforços para que a{' '}
      <span className="bold">CovidApp</span> não tenha qualquer tipo de vírus ou
      outros elementos do género perigosos para o seu equipamento. No entanto,
      uma vez que a <span className="bold">CovidApp</span> não conseguimos
      controlar integralmente a circulação de informação através da Internet,
      não consegue garantir que a <span className="bold">CovidApp</span> não
      contém qualquer tipo de vírus ou outros elementos que possam danificar o
      seu equipamento.
    </p>
    <p>
      Para garantir a segurança da <span className="bold">CovidApp</span>, a
      poderemos, a qualquer momento e sem necessidade de aviso prévio, tomar as
      providências necessárias para garantir a integridade, segurança,
      continuidade ou qualidade da <span className="bold">CovidApp</span>,
      incluindo restrições ou limitações de acesso.
    </p>
    <p>
      Podemos suspender, bloquear, interromper ou cessar o acesso à{' '}
      <span className="bold">CovidApp</span>, para a totalidade ou apenas um ou
      mais <span className="bold">utilizador</span>
      es, nos seguintes casos:
      <li>
        Quando se mostre necessário em virtude de condicionalismos técnicos,
        comerciais ou legais;
      </li>
      <li>
        Quando necessário para garantir a segurança, designadamente, dos canais
        de comunicação, dos equipamentos ou da informação;
      </li>
      <li>
        Quando tenhamos fundadas suspeitas de utilização de forma fraudulenta ou
        abusiva, pelo <span className="bold">Utilizador</span> ou terceiro(s);
      </li>
      <li>
        Quando se mostre necessário nas operações de gestão, manutenção, reparação,
        alteração ou modernização dos equipamentos, sistemas ou qualquer
        funcionalidade da <span className="bold">CovidApp</span>;
      </li>
      <li>
        Em outras circunstâncias devidamente justificadas, incluindo,
        designadamente, em caso de incumprimento pelo{' '}
        <span className="bold">Utilizador</span> das suas obrigações ao abrigo
        dos presentes <span className="bold">T&C</span>.
      </li>
      <p>
        Temos ainda o direito exclusivo
        de, a todo o tempo, encerrar, definitiva ou temporariamente, parcial ou
        totalmente, a qualquer momento, de acordo com a sua vontade, a{' '}
        <span className="bold">CovidApp</span> ou qualquer uma das suas
        funcionalidades sem aviso prévio.
      </p>
      <p>
        Se alguma parte ou disposição dos presentes{' '}
        <span className="bold"> T&C</span> não for executável ou estiver em
        conflito com a lei aplicável, a validade das restantes partes ou
        disposições não será afetada.
      </p>
      <p>
        Caso tenha alguma questão sobre os presentes{' '}
        <span className="bold"> T&C</span>, por favor envie-nos o seu pedido de
        esclarecimento através do Formulário de Contacto existente na{' '}
        <span className="bold">CovidApp</span>, geral@CovidApp.pt
      </p>
      <p>
        Estes <span className="bold">T&C</span> são regidos pela lei portuguesa
        e, em caso de litígio na interpretação ou aplicação dos presentes{' '}
        <span className="bold">T&C</span>, será competente em exclusivo o foro
        da Comarca do Porto, com expressa renúncia a qualquer outro.
      </p>
      <h3>Porto, 8 de junho de 2020</h3>
    </p>
  </div>
);

export default withMainLayout(TermsConditions);
