import React, { FunctionComponent, CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export interface BackProps {
  history: RouteComponentProps['history']
  style?: CSSProperties
}

const Back: FunctionComponent<BackProps> = ({ children, history, style }) => (
  <div style={style}>
    <a onClick={() => history.goBack()}>
      {children}
    </a>
  </div>
);

export default Back;
