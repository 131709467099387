import { Form, Input, Button } from 'antd';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import React, {
  SFC, useCallback, useState, useEffect,
} from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { authentication } from '../../../stores';

const schema = object().shape({
  code: string()
    .matches(/[0-9]{6}/)
    .required('Campo obrigatório'),
});

const GetCode: SFC<{
  phoneNumber: string
  code: string
  onChangeNumber: () => void | Promise<void>
  onResendCode: () => void | Promise<void>
  onSubmit: (value: string) => void | Promise<void>
}> = ({
  onSubmit, onChangeNumber, onResendCode, phoneNumber, code,
}) => {

    const [resendCounter, setCounter] = React.useState(60);
    useEffect(() => {
      resendCounter > 0 && setTimeout(() => setCounter(resendCounter - 1), 1000);
    }, [resendCounter]);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const formik = useFormik({
      initialValues: {
        code,
      },
      validateOnChange: true,
      validationSchema: schema,
      async onSubmit(values) {
        const code = values.code.replace(/\s+/g, '')

        let reCaptchaToken
        if (executeRecaptcha) {
          reCaptchaToken = await executeRecaptcha("AuthVerifyCode");
        }

        await authentication.verifyCode(phoneNumber, code, reCaptchaToken);

        onSubmit(code)
      },
    });

    const [resending, setResending] = useState<boolean>(false);
    const onResendClick = useCallback(async (e: any) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      try {
        await setResending(true);
        await onResendCode();
        await setCounter(120);
      } catch (err) {
        // TODO: Change thos behavior plz
        // do nothing in case of error
      } finally {
        await setResending(false);
      }
    }, []);

    const hasErrors = Object.keys(formik.errors).length > 0;

    return (
      <Form
        layout="vertical"
        onChange={formik.handleChange}
        onSubmitCapture={formik.handleSubmit}
      >
        <Form.Item
          required
          validateStatus={
            (formik.isSubmitting && 'validating')
            || (formik?.errors?.code && 'error')
            || (formik.values.code && 'success')
            || undefined
          }
          label="Código enviado por SMS"
          wrapperCol={{ span: 24 }}
          extra={(
            <>
              <Paragraph>
                Por favor insira aqui o código que recebeu no número
              {' '}
                <b>{phoneNumber}</b>
              .
              <br />
                <br />
              </Paragraph>

              <Paragraph>
                Número errado?
              {' '}
                <a onClick={onChangeNumber} href="#">
                  Clique aqui
              </a>
                {' '}
              para alterar.
              <br />
                <br />
              </Paragraph>

              <Paragraph>
                {(resending && <>A re-enviar o código.</>)
                  || (resendCounter > 0 && (
                    <>
                      Enviamos uma mensagem, deverá recebê-la nos próximos
                    {' '}
                      {resendCounter}
                      {' '}
                    segundos.
                  </>
                  )) || (
                    <>
                      Caso não tenha recebido uma SMS,
                    {' '}
                      <a onClick={onResendClick} href="#">
                        clique aqui
                    </a>
                      {' '}
                    para voltar a re-enviar o código.
                  </>
                  )}
              </Paragraph>
            </>
          )}
        >
          <Input
            type="text"
            placeholder="123456"
            name="code"
            value={formik.values.code}
            maxLength={6}
            disabled={resending || formik.isSubmitting}
            style={{
              margin: '0 0 8px 0',
              width: '70%',
            }}
          />
        </Form.Item>

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          disabled={hasErrors}
          loading={formik.isSubmitting}
        >
          Validar Código
      </Button>
      </Form>
    );
  };

export default GetCode;
