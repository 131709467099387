import React, { SFC } from 'react';
import { useObservable, useObserver } from 'mobx-react-lite';
import { Redirect, RouteProps, Route } from 'react-router-dom';
import { authentication } from '../stores/authentication';

const ProtectedRoute: SFC<{
  path: string
  role?: string
  redirectTo?: string
} & RouteProps> = ({
  path, role, redirectTo = '/', ...props
}) => {
  useObservable(authentication);

  return useObserver(() => {
    if (
      // not authenticated
      !authentication.isAuthenticated
      // authenticated but not with the correct role
      || (role && authentication.role !== role)
    ) {
      return <Redirect path={path} to={redirectTo} />;
    }

    // else, lets render a normal route
    return <Route path={path} {...props} />;
  });
};

export default ProtectedRoute;
