/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  timestamptz: any;
  uuid: any;
  date: any;
  float8: any;
  bigint: any;
  Time: any;
};

export type AuthInfo = {
   __typename?: 'AuthInfo';
  accessToken: Scalars['String'];
  role: Scalars['String'];
  sessionUUID: Scalars['String'];
  userUUID: Scalars['String'];
};


export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};


export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};


export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type Mutation = {
   __typename?: 'Mutation';
  acceptAllTerms: Scalars['Boolean'];
  accountSendFeedback: Scalars['Boolean'];
  adminAnonymizeUserByEmail: Scalars['String'];
  adminAnonymizeUserByPhoneNumber: Scalars['String'];
  adminAnonymizeUserByUUID: Scalars['String'];
  adminComputeSeverityForReports: Scalars['Int'];
  authDeleteAccount: Scalars['Boolean'];
  authRefresh: AuthInfo;
  authRevoke: Scalars['Boolean'];
  authSendVerificationCode: Scalars['Boolean'];
  authVerifyCode: AuthInfo;
};


export type MutationAcceptAllTermsArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationAccountSendFeedbackArgs = {
  message: Scalars['String'];
};


export type MutationAdminAnonymizeUserByEmailArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationAdminAnonymizeUserByPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};


export type MutationAdminAnonymizeUserByUuidArgs = {
  token: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationAdminComputeSeverityForReportsArgs = {
  token: Scalars['String'];
};


export type MutationAuthSendVerificationCodeArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationAuthVerifyCodeArgs = {
  code: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type Mutation_Root = {
   __typename?: 'mutation_root';
  acceptAllTerms: Scalars['Boolean'];
  accountSendFeedback: Scalars['Boolean'];
  adminAnonymizeUserByEmail: Scalars['String'];
  adminAnonymizeUserByPhoneNumber: Scalars['String'];
  adminAnonymizeUserByUUID: Scalars['String'];
  adminComputeSeverityForReports: Scalars['Int'];
  authDeleteAccount: Scalars['Boolean'];
  authRefresh: AuthInfo;
  authRevoke: Scalars['Boolean'];
  authSendVerificationCode: Scalars['Boolean'];
  authVerifyCode: AuthInfo;
  delete_Note?: Maybe<Note_Mutation_Response>;
  delete_Note_by_pk?: Maybe<Note>;
  delete_Report?: Maybe<Report_Mutation_Response>;
  delete_ReportView?: Maybe<ReportView_Mutation_Response>;
  delete_ReportView_by_pk?: Maybe<ReportView>;
  delete_Report_by_pk?: Maybe<Report>;
  delete_Session?: Maybe<Session_Mutation_Response>;
  delete_Session_by_pk?: Maybe<Session>;
  delete_User?: Maybe<User_Mutation_Response>;
  delete_UserGender?: Maybe<UserGender_Mutation_Response>;
  delete_UserGender_by_pk?: Maybe<UserGender>;
  delete_UserRole?: Maybe<UserRole_Mutation_Response>;
  delete_UserRole_by_pk?: Maybe<UserRole>;
  delete_UserSeverity?: Maybe<UserSeverity_Mutation_Response>;
  delete_UserSeverity_by_pk?: Maybe<UserSeverity>;
  delete_UserStatus?: Maybe<UserStatus_Mutation_Response>;
  delete_UserStatus_by_pk?: Maybe<UserStatus>;
  delete_User_by_pk?: Maybe<User>;
  insert_Note?: Maybe<Note_Mutation_Response>;
  insert_Note_one?: Maybe<Note>;
  insert_Report?: Maybe<Report_Mutation_Response>;
  insert_ReportView?: Maybe<ReportView_Mutation_Response>;
  insert_ReportView_one?: Maybe<ReportView>;
  insert_Report_one?: Maybe<Report>;
  insert_Session?: Maybe<Session_Mutation_Response>;
  insert_Session_one?: Maybe<Session>;
  insert_User?: Maybe<User_Mutation_Response>;
  insert_UserGender?: Maybe<UserGender_Mutation_Response>;
  insert_UserGender_one?: Maybe<UserGender>;
  insert_UserRole?: Maybe<UserRole_Mutation_Response>;
  insert_UserRole_one?: Maybe<UserRole>;
  insert_UserSeverity?: Maybe<UserSeverity_Mutation_Response>;
  insert_UserSeverity_one?: Maybe<UserSeverity>;
  insert_UserStatus?: Maybe<UserStatus_Mutation_Response>;
  insert_UserStatus_one?: Maybe<UserStatus>;
  insert_User_one?: Maybe<User>;
  update_Note?: Maybe<Note_Mutation_Response>;
  update_Note_by_pk?: Maybe<Note>;
  update_Report?: Maybe<Report_Mutation_Response>;
  update_ReportView?: Maybe<ReportView_Mutation_Response>;
  update_ReportView_by_pk?: Maybe<ReportView>;
  update_Report_by_pk?: Maybe<Report>;
  update_Session?: Maybe<Session_Mutation_Response>;
  update_Session_by_pk?: Maybe<Session>;
  update_User?: Maybe<User_Mutation_Response>;
  update_UserGender?: Maybe<UserGender_Mutation_Response>;
  update_UserGender_by_pk?: Maybe<UserGender>;
  update_UserRole?: Maybe<UserRole_Mutation_Response>;
  update_UserRole_by_pk?: Maybe<UserRole>;
  update_UserSeverity?: Maybe<UserSeverity_Mutation_Response>;
  update_UserSeverity_by_pk?: Maybe<UserSeverity>;
  update_UserStatus?: Maybe<UserStatus_Mutation_Response>;
  update_UserStatus_by_pk?: Maybe<UserStatus>;
  update_User_by_pk?: Maybe<User>;
};


export type Mutation_RootAcceptAllTermsArgs = {
  phoneNumber: Scalars['String'];
};


export type Mutation_RootAccountSendFeedbackArgs = {
  message: Scalars['String'];
};


export type Mutation_RootAdminAnonymizeUserByEmailArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type Mutation_RootAdminAnonymizeUserByPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};


export type Mutation_RootAdminAnonymizeUserByUuidArgs = {
  token: Scalars['String'];
  uuid: Scalars['String'];
};


export type Mutation_RootAdminComputeSeverityForReportsArgs = {
  token: Scalars['String'];
};


export type Mutation_RootAuthSendVerificationCodeArgs = {
  phoneNumber: Scalars['String'];
};


export type Mutation_RootAuthVerifyCodeArgs = {
  code: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type Mutation_RootDelete_NoteArgs = {
  where: Note_Bool_Exp;
};


export type Mutation_RootDelete_Note_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Mutation_RootDelete_ReportArgs = {
  where: Report_Bool_Exp;
};


export type Mutation_RootDelete_ReportViewArgs = {
  where: ReportView_Bool_Exp;
};


export type Mutation_RootDelete_ReportView_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Mutation_RootDelete_Report_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Mutation_RootDelete_SessionArgs = {
  where: Session_Bool_Exp;
};


export type Mutation_RootDelete_Session_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


export type Mutation_RootDelete_UserGenderArgs = {
  where: UserGender_Bool_Exp;
};


export type Mutation_RootDelete_UserGender_By_PkArgs = {
  gender: Scalars['String'];
};


export type Mutation_RootDelete_UserRoleArgs = {
  where: UserRole_Bool_Exp;
};


export type Mutation_RootDelete_UserRole_By_PkArgs = {
  role: Scalars['String'];
};


export type Mutation_RootDelete_UserSeverityArgs = {
  where: UserSeverity_Bool_Exp;
};


export type Mutation_RootDelete_UserSeverity_By_PkArgs = {
  severity: Scalars['String'];
};


export type Mutation_RootDelete_UserStatusArgs = {
  where: UserStatus_Bool_Exp;
};


export type Mutation_RootDelete_UserStatus_By_PkArgs = {
  status: Scalars['String'];
};


export type Mutation_RootDelete_User_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Mutation_RootInsert_NoteArgs = {
  objects: Array<Note_Insert_Input>;
  on_conflict?: Maybe<Note_On_Conflict>;
};


export type Mutation_RootInsert_Note_OneArgs = {
  object: Note_Insert_Input;
  on_conflict?: Maybe<Note_On_Conflict>;
};


export type Mutation_RootInsert_ReportArgs = {
  objects: Array<Report_Insert_Input>;
  on_conflict?: Maybe<Report_On_Conflict>;
};


export type Mutation_RootInsert_ReportViewArgs = {
  objects: Array<ReportView_Insert_Input>;
  on_conflict?: Maybe<ReportView_On_Conflict>;
};


export type Mutation_RootInsert_ReportView_OneArgs = {
  object: ReportView_Insert_Input;
  on_conflict?: Maybe<ReportView_On_Conflict>;
};


export type Mutation_RootInsert_Report_OneArgs = {
  object: Report_Insert_Input;
  on_conflict?: Maybe<Report_On_Conflict>;
};


export type Mutation_RootInsert_SessionArgs = {
  objects: Array<Session_Insert_Input>;
  on_conflict?: Maybe<Session_On_Conflict>;
};


export type Mutation_RootInsert_Session_OneArgs = {
  object: Session_Insert_Input;
  on_conflict?: Maybe<Session_On_Conflict>;
};


export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


export type Mutation_RootInsert_UserGenderArgs = {
  objects: Array<UserGender_Insert_Input>;
  on_conflict?: Maybe<UserGender_On_Conflict>;
};


export type Mutation_RootInsert_UserGender_OneArgs = {
  object: UserGender_Insert_Input;
  on_conflict?: Maybe<UserGender_On_Conflict>;
};


export type Mutation_RootInsert_UserRoleArgs = {
  objects: Array<UserRole_Insert_Input>;
  on_conflict?: Maybe<UserRole_On_Conflict>;
};


export type Mutation_RootInsert_UserRole_OneArgs = {
  object: UserRole_Insert_Input;
  on_conflict?: Maybe<UserRole_On_Conflict>;
};


export type Mutation_RootInsert_UserSeverityArgs = {
  objects: Array<UserSeverity_Insert_Input>;
  on_conflict?: Maybe<UserSeverity_On_Conflict>;
};


export type Mutation_RootInsert_UserSeverity_OneArgs = {
  object: UserSeverity_Insert_Input;
  on_conflict?: Maybe<UserSeverity_On_Conflict>;
};


export type Mutation_RootInsert_UserStatusArgs = {
  objects: Array<UserStatus_Insert_Input>;
  on_conflict?: Maybe<UserStatus_On_Conflict>;
};


export type Mutation_RootInsert_UserStatus_OneArgs = {
  object: UserStatus_Insert_Input;
  on_conflict?: Maybe<UserStatus_On_Conflict>;
};


export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


export type Mutation_RootUpdate_NoteArgs = {
  _set?: Maybe<Note_Set_Input>;
  where: Note_Bool_Exp;
};


export type Mutation_RootUpdate_Note_By_PkArgs = {
  _set?: Maybe<Note_Set_Input>;
  pk_columns: Note_Pk_Columns_Input;
};


export type Mutation_RootUpdate_ReportArgs = {
  _inc?: Maybe<Report_Inc_Input>;
  _set?: Maybe<Report_Set_Input>;
  where: Report_Bool_Exp;
};


export type Mutation_RootUpdate_ReportViewArgs = {
  _set?: Maybe<ReportView_Set_Input>;
  where: ReportView_Bool_Exp;
};


export type Mutation_RootUpdate_ReportView_By_PkArgs = {
  _set?: Maybe<ReportView_Set_Input>;
  pk_columns: ReportView_Pk_Columns_Input;
};


export type Mutation_RootUpdate_Report_By_PkArgs = {
  _inc?: Maybe<Report_Inc_Input>;
  _set?: Maybe<Report_Set_Input>;
  pk_columns: Report_Pk_Columns_Input;
};


export type Mutation_RootUpdate_SessionArgs = {
  _set?: Maybe<Session_Set_Input>;
  where: Session_Bool_Exp;
};


export type Mutation_RootUpdate_Session_By_PkArgs = {
  _set?: Maybe<Session_Set_Input>;
  pk_columns: Session_Pk_Columns_Input;
};


export type Mutation_RootUpdate_UserArgs = {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


export type Mutation_RootUpdate_UserGenderArgs = {
  _set?: Maybe<UserGender_Set_Input>;
  where: UserGender_Bool_Exp;
};


export type Mutation_RootUpdate_UserGender_By_PkArgs = {
  _set?: Maybe<UserGender_Set_Input>;
  pk_columns: UserGender_Pk_Columns_Input;
};


export type Mutation_RootUpdate_UserRoleArgs = {
  _set?: Maybe<UserRole_Set_Input>;
  where: UserRole_Bool_Exp;
};


export type Mutation_RootUpdate_UserRole_By_PkArgs = {
  _set?: Maybe<UserRole_Set_Input>;
  pk_columns: UserRole_Pk_Columns_Input;
};


export type Mutation_RootUpdate_UserSeverityArgs = {
  _set?: Maybe<UserSeverity_Set_Input>;
  where: UserSeverity_Bool_Exp;
};


export type Mutation_RootUpdate_UserSeverity_By_PkArgs = {
  _set?: Maybe<UserSeverity_Set_Input>;
  pk_columns: UserSeverity_Pk_Columns_Input;
};


export type Mutation_RootUpdate_UserStatusArgs = {
  _set?: Maybe<UserStatus_Set_Input>;
  where: UserStatus_Bool_Exp;
};


export type Mutation_RootUpdate_UserStatus_By_PkArgs = {
  _set?: Maybe<UserStatus_Set_Input>;
  pk_columns: UserStatus_Pk_Columns_Input;
};


export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

export type Note = {
   __typename?: 'Note';
  Report: Report;
  User: User;
  createdAt: Scalars['timestamptz'];
  doctorContent: Scalars['String'];
  hasChangedReport: Scalars['Boolean'];
  patientContent: Scalars['String'];
  patientReachability: Scalars['String'];
  reportUUID: Scalars['uuid'];
  userUUID: Scalars['uuid'];
  uuid: Scalars['uuid'];
};

export type Note_Aggregate = {
   __typename?: 'Note_aggregate';
  aggregate?: Maybe<Note_Aggregate_Fields>;
  nodes: Array<Note>;
};

export type Note_Aggregate_Fields = {
   __typename?: 'Note_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Note_Max_Fields>;
  min?: Maybe<Note_Min_Fields>;
};


export type Note_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Note_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Note_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Note_Max_Order_By>;
  min?: Maybe<Note_Min_Order_By>;
};

export type Note_Arr_Rel_Insert_Input = {
  data: Array<Note_Insert_Input>;
  on_conflict?: Maybe<Note_On_Conflict>;
};

export type Note_Bool_Exp = {
  Report?: Maybe<Report_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Note_Bool_Exp>>>;
  _not?: Maybe<Note_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Note_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  doctorContent?: Maybe<String_Comparison_Exp>;
  hasChangedReport?: Maybe<Boolean_Comparison_Exp>;
  patientContent?: Maybe<String_Comparison_Exp>;
  patientReachability?: Maybe<String_Comparison_Exp>;
  reportUUID?: Maybe<Uuid_Comparison_Exp>;
  userUUID?: Maybe<Uuid_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

export enum Note_Constraint {
  NotePkey = 'Note_pkey'
}

export type Note_Insert_Input = {
  Report?: Maybe<Report_Obj_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  doctorContent?: Maybe<Scalars['String']>;
  hasChangedReport?: Maybe<Scalars['Boolean']>;
  patientContent?: Maybe<Scalars['String']>;
  patientReachability?: Maybe<Scalars['String']>;
  reportUUID?: Maybe<Scalars['uuid']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Note_Max_Fields = {
   __typename?: 'Note_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  doctorContent?: Maybe<Scalars['String']>;
  patientContent?: Maybe<Scalars['String']>;
  patientReachability?: Maybe<Scalars['String']>;
  reportUUID?: Maybe<Scalars['uuid']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Note_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  doctorContent?: Maybe<Order_By>;
  patientContent?: Maybe<Order_By>;
  patientReachability?: Maybe<Order_By>;
  reportUUID?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type Note_Min_Fields = {
   __typename?: 'Note_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  doctorContent?: Maybe<Scalars['String']>;
  patientContent?: Maybe<Scalars['String']>;
  patientReachability?: Maybe<Scalars['String']>;
  reportUUID?: Maybe<Scalars['uuid']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Note_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  doctorContent?: Maybe<Order_By>;
  patientContent?: Maybe<Order_By>;
  patientReachability?: Maybe<Order_By>;
  reportUUID?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type Note_Mutation_Response = {
   __typename?: 'Note_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Note>;
};

export type Note_Obj_Rel_Insert_Input = {
  data: Note_Insert_Input;
  on_conflict?: Maybe<Note_On_Conflict>;
};

export type Note_On_Conflict = {
  constraint: Note_Constraint;
  update_columns: Array<Note_Update_Column>;
  where?: Maybe<Note_Bool_Exp>;
};

export type Note_Order_By = {
  Report?: Maybe<Report_Order_By>;
  User?: Maybe<User_Order_By>;
  createdAt?: Maybe<Order_By>;
  doctorContent?: Maybe<Order_By>;
  hasChangedReport?: Maybe<Order_By>;
  patientContent?: Maybe<Order_By>;
  patientReachability?: Maybe<Order_By>;
  reportUUID?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type Note_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

export enum Note_Select_Column {
  CreatedAt = 'createdAt',
  DoctorContent = 'doctorContent',
  HasChangedReport = 'hasChangedReport',
  PatientContent = 'patientContent',
  PatientReachability = 'patientReachability',
  ReportUuid = 'reportUUID',
  UserUuid = 'userUUID',
  Uuid = 'uuid'
}

export type Note_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  doctorContent?: Maybe<Scalars['String']>;
  hasChangedReport?: Maybe<Scalars['Boolean']>;
  patientContent?: Maybe<Scalars['String']>;
  patientReachability?: Maybe<Scalars['String']>;
  reportUUID?: Maybe<Scalars['uuid']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export enum Note_Update_Column {
  CreatedAt = 'createdAt',
  DoctorContent = 'doctorContent',
  HasChangedReport = 'hasChangedReport',
  PatientContent = 'patientContent',
  PatientReachability = 'patientReachability',
  ReportUuid = 'reportUUID',
  UserUuid = 'userUUID',
  Uuid = 'uuid'
}

export enum Order_By {
  Asc = 'asc',
  AscNullsFirst = 'asc_nulls_first',
  AscNullsLast = 'asc_nulls_last',
  Desc = 'desc',
  DescNullsFirst = 'desc_nulls_first',
  DescNullsLast = 'desc_nulls_last'
}

export type Query = {
   __typename?: 'Query';
  health: Scalars['String'];
  stats: Stats;
};

export type Query_Root = {
   __typename?: 'query_root';
  Note: Array<Note>;
  Note_aggregate: Note_Aggregate;
  Note_by_pk?: Maybe<Note>;
  Report: Array<Report>;
  ReportView: Array<ReportView>;
  ReportView_aggregate: ReportView_Aggregate;
  ReportView_by_pk?: Maybe<ReportView>;
  Report_aggregate: Report_Aggregate;
  Report_by_pk?: Maybe<Report>;
  Session: Array<Session>;
  Session_aggregate: Session_Aggregate;
  Session_by_pk?: Maybe<Session>;
  StatsByZipCodes: Array<StatsByZipCodes>;
  StatsByZipCodes_aggregate: StatsByZipCodes_Aggregate;
  User: Array<User>;
  UserGender: Array<UserGender>;
  UserGender_aggregate: UserGender_Aggregate;
  UserGender_by_pk?: Maybe<UserGender>;
  UserRole: Array<UserRole>;
  UserRole_aggregate: UserRole_Aggregate;
  UserRole_by_pk?: Maybe<UserRole>;
  UserSeverity: Array<UserSeverity>;
  UserSeverity_aggregate: UserSeverity_Aggregate;
  UserSeverity_by_pk?: Maybe<UserSeverity>;
  UserStatus: Array<UserStatus>;
  UserStatus_aggregate: UserStatus_Aggregate;
  UserStatus_by_pk?: Maybe<UserStatus>;
  User_aggregate: User_Aggregate;
  User_by_pk?: Maybe<User>;
  health: Scalars['String'];
  stats: Stats;
};


export type Query_RootNoteArgs = {
  distinct_on?: Maybe<Array<Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type Query_RootNote_AggregateArgs = {
  distinct_on?: Maybe<Array<Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type Query_RootNote_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Query_RootReportArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


export type Query_RootReportViewArgs = {
  distinct_on?: Maybe<Array<ReportView_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReportView_Order_By>>;
  where?: Maybe<ReportView_Bool_Exp>;
};


export type Query_RootReportView_AggregateArgs = {
  distinct_on?: Maybe<Array<ReportView_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReportView_Order_By>>;
  where?: Maybe<ReportView_Bool_Exp>;
};


export type Query_RootReportView_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Query_RootReport_AggregateArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


export type Query_RootReport_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Query_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Query_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Query_RootSession_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Query_RootStatsByZipCodesArgs = {
  distinct_on?: Maybe<Array<StatsByZipCodes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StatsByZipCodes_Order_By>>;
  where?: Maybe<StatsByZipCodes_Bool_Exp>;
};


export type Query_RootStatsByZipCodes_AggregateArgs = {
  distinct_on?: Maybe<Array<StatsByZipCodes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StatsByZipCodes_Order_By>>;
  where?: Maybe<StatsByZipCodes_Bool_Exp>;
};


export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUserGenderArgs = {
  distinct_on?: Maybe<Array<UserGender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGender_Order_By>>;
  where?: Maybe<UserGender_Bool_Exp>;
};


export type Query_RootUserGender_AggregateArgs = {
  distinct_on?: Maybe<Array<UserGender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGender_Order_By>>;
  where?: Maybe<UserGender_Bool_Exp>;
};


export type Query_RootUserGender_By_PkArgs = {
  gender: Scalars['String'];
};


export type Query_RootUserRoleArgs = {
  distinct_on?: Maybe<Array<UserRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserRole_Order_By>>;
  where?: Maybe<UserRole_Bool_Exp>;
};


export type Query_RootUserRole_AggregateArgs = {
  distinct_on?: Maybe<Array<UserRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserRole_Order_By>>;
  where?: Maybe<UserRole_Bool_Exp>;
};


export type Query_RootUserRole_By_PkArgs = {
  role: Scalars['String'];
};


export type Query_RootUserSeverityArgs = {
  distinct_on?: Maybe<Array<UserSeverity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserSeverity_Order_By>>;
  where?: Maybe<UserSeverity_Bool_Exp>;
};


export type Query_RootUserSeverity_AggregateArgs = {
  distinct_on?: Maybe<Array<UserSeverity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserSeverity_Order_By>>;
  where?: Maybe<UserSeverity_Bool_Exp>;
};


export type Query_RootUserSeverity_By_PkArgs = {
  severity: Scalars['String'];
};


export type Query_RootUserStatusArgs = {
  distinct_on?: Maybe<Array<UserStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserStatus_Order_By>>;
  where?: Maybe<UserStatus_Bool_Exp>;
};


export type Query_RootUserStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<UserStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserStatus_Order_By>>;
  where?: Maybe<UserStatus_Bool_Exp>;
};


export type Query_RootUserStatus_By_PkArgs = {
  status: Scalars['String'];
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Report = {
   __typename?: 'Report';
  Notes: Array<Note>;
  Notes_aggregate: Note_Aggregate;
  ReportViews: Array<ReportView>;
  ReportViews_aggregate: ReportView_Aggregate;
  User: User;
  analgesics?: Maybe<Scalars['String']>;
  bloodPressureMax?: Maybe<Scalars['float8']>;
  bloodPressureMin?: Maybe<Scalars['float8']>;
  breathRate?: Maybe<Scalars['float8']>;
  breathingTroubleRate?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  hadContactWithInfectedPatients: Scalars['Boolean'];
  hasBeenTested?: Maybe<Scalars['Boolean']>;
  hasCough?: Maybe<Scalars['Boolean']>;
  hasDiabetes?: Maybe<Scalars['Boolean']>;
  hasHypertension?: Maybe<Scalars['Boolean']>;
  hasSymptoms: Scalars['Boolean'];
  heartRate?: Maybe<Scalars['float8']>;
  lastTestDate?: Maybe<Scalars['date']>;
  lastTestResult?: Maybe<Scalars['Boolean']>;
  otherSymptoms?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['Int']>;
  symptomsBeginDate?: Maybe<Scalars['date']>;
  symptomsEvolution?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['float8']>;
  tookPainkillers: Scalars['Boolean'];
  userUUID: Scalars['uuid'];
  uuid: Scalars['uuid'];
};


export type ReportNotesArgs = {
  distinct_on?: Maybe<Array<Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type ReportNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type ReportReportViewsArgs = {
  distinct_on?: Maybe<Array<ReportView_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReportView_Order_By>>;
  where?: Maybe<ReportView_Bool_Exp>;
};


export type ReportReportViews_AggregateArgs = {
  distinct_on?: Maybe<Array<ReportView_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReportView_Order_By>>;
  where?: Maybe<ReportView_Bool_Exp>;
};

export type Report_Aggregate = {
   __typename?: 'Report_aggregate';
  aggregate?: Maybe<Report_Aggregate_Fields>;
  nodes: Array<Report>;
};

export type Report_Aggregate_Fields = {
   __typename?: 'Report_aggregate_fields';
  avg?: Maybe<Report_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Report_Max_Fields>;
  min?: Maybe<Report_Min_Fields>;
  stddev?: Maybe<Report_Stddev_Fields>;
  stddev_pop?: Maybe<Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Report_Stddev_Samp_Fields>;
  sum?: Maybe<Report_Sum_Fields>;
  var_pop?: Maybe<Report_Var_Pop_Fields>;
  var_samp?: Maybe<Report_Var_Samp_Fields>;
  variance?: Maybe<Report_Variance_Fields>;
};


export type Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Report_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Report_Aggregate_Order_By = {
  avg?: Maybe<Report_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Report_Max_Order_By>;
  min?: Maybe<Report_Min_Order_By>;
  stddev?: Maybe<Report_Stddev_Order_By>;
  stddev_pop?: Maybe<Report_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Report_Stddev_Samp_Order_By>;
  sum?: Maybe<Report_Sum_Order_By>;
  var_pop?: Maybe<Report_Var_Pop_Order_By>;
  var_samp?: Maybe<Report_Var_Samp_Order_By>;
  variance?: Maybe<Report_Variance_Order_By>;
};

export type Report_Arr_Rel_Insert_Input = {
  data: Array<Report_Insert_Input>;
  on_conflict?: Maybe<Report_On_Conflict>;
};

export type Report_Avg_Fields = {
   __typename?: 'Report_avg_fields';
  bloodPressureMax?: Maybe<Scalars['Float']>;
  bloodPressureMin?: Maybe<Scalars['Float']>;
  breathRate?: Maybe<Scalars['Float']>;
  breathingTroubleRate?: Maybe<Scalars['Float']>;
  heartRate?: Maybe<Scalars['Float']>;
  severity?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

export type Report_Avg_Order_By = {
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
};

export type Report_Bool_Exp = {
  Notes?: Maybe<Note_Bool_Exp>;
  ReportViews?: Maybe<ReportView_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Report_Bool_Exp>>>;
  _not?: Maybe<Report_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Report_Bool_Exp>>>;
  analgesics?: Maybe<String_Comparison_Exp>;
  bloodPressureMax?: Maybe<Float8_Comparison_Exp>;
  bloodPressureMin?: Maybe<Float8_Comparison_Exp>;
  breathRate?: Maybe<Float8_Comparison_Exp>;
  breathingTroubleRate?: Maybe<Int_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  hadContactWithInfectedPatients?: Maybe<Boolean_Comparison_Exp>;
  hasBeenTested?: Maybe<Boolean_Comparison_Exp>;
  hasCough?: Maybe<Boolean_Comparison_Exp>;
  hasDiabetes?: Maybe<Boolean_Comparison_Exp>;
  hasHypertension?: Maybe<Boolean_Comparison_Exp>;
  hasSymptoms?: Maybe<Boolean_Comparison_Exp>;
  heartRate?: Maybe<Float8_Comparison_Exp>;
  lastTestDate?: Maybe<Date_Comparison_Exp>;
  lastTestResult?: Maybe<Boolean_Comparison_Exp>;
  otherSymptoms?: Maybe<String_Comparison_Exp>;
  severity?: Maybe<Int_Comparison_Exp>;
  symptomsBeginDate?: Maybe<Date_Comparison_Exp>;
  symptomsEvolution?: Maybe<String_Comparison_Exp>;
  temperature?: Maybe<Float8_Comparison_Exp>;
  tookPainkillers?: Maybe<Boolean_Comparison_Exp>;
  userUUID?: Maybe<Uuid_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

export enum Report_Constraint {
  ReportPkey = 'Report_pkey'
}

export type Report_Inc_Input = {
  bloodPressureMax?: Maybe<Scalars['float8']>;
  bloodPressureMin?: Maybe<Scalars['float8']>;
  breathRate?: Maybe<Scalars['float8']>;
  breathingTroubleRate?: Maybe<Scalars['Int']>;
  heartRate?: Maybe<Scalars['float8']>;
  severity?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['float8']>;
};

export type Report_Insert_Input = {
  Notes?: Maybe<Note_Arr_Rel_Insert_Input>;
  ReportViews?: Maybe<ReportView_Arr_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  analgesics?: Maybe<Scalars['String']>;
  bloodPressureMax?: Maybe<Scalars['float8']>;
  bloodPressureMin?: Maybe<Scalars['float8']>;
  breathRate?: Maybe<Scalars['float8']>;
  breathingTroubleRate?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hadContactWithInfectedPatients?: Maybe<Scalars['Boolean']>;
  hasBeenTested?: Maybe<Scalars['Boolean']>;
  hasCough?: Maybe<Scalars['Boolean']>;
  hasDiabetes?: Maybe<Scalars['Boolean']>;
  hasHypertension?: Maybe<Scalars['Boolean']>;
  hasSymptoms?: Maybe<Scalars['Boolean']>;
  heartRate?: Maybe<Scalars['float8']>;
  lastTestDate?: Maybe<Scalars['date']>;
  lastTestResult?: Maybe<Scalars['Boolean']>;
  otherSymptoms?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['Int']>;
  symptomsBeginDate?: Maybe<Scalars['date']>;
  symptomsEvolution?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['float8']>;
  tookPainkillers?: Maybe<Scalars['Boolean']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Report_Max_Fields = {
   __typename?: 'Report_max_fields';
  analgesics?: Maybe<Scalars['String']>;
  bloodPressureMax?: Maybe<Scalars['float8']>;
  bloodPressureMin?: Maybe<Scalars['float8']>;
  breathRate?: Maybe<Scalars['float8']>;
  breathingTroubleRate?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  heartRate?: Maybe<Scalars['float8']>;
  lastTestDate?: Maybe<Scalars['date']>;
  otherSymptoms?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['Int']>;
  symptomsBeginDate?: Maybe<Scalars['date']>;
  symptomsEvolution?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['float8']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Report_Max_Order_By = {
  analgesics?: Maybe<Order_By>;
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  lastTestDate?: Maybe<Order_By>;
  otherSymptoms?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  symptomsBeginDate?: Maybe<Order_By>;
  symptomsEvolution?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type Report_Min_Fields = {
   __typename?: 'Report_min_fields';
  analgesics?: Maybe<Scalars['String']>;
  bloodPressureMax?: Maybe<Scalars['float8']>;
  bloodPressureMin?: Maybe<Scalars['float8']>;
  breathRate?: Maybe<Scalars['float8']>;
  breathingTroubleRate?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  heartRate?: Maybe<Scalars['float8']>;
  lastTestDate?: Maybe<Scalars['date']>;
  otherSymptoms?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['Int']>;
  symptomsBeginDate?: Maybe<Scalars['date']>;
  symptomsEvolution?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['float8']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Report_Min_Order_By = {
  analgesics?: Maybe<Order_By>;
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  lastTestDate?: Maybe<Order_By>;
  otherSymptoms?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  symptomsBeginDate?: Maybe<Order_By>;
  symptomsEvolution?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type Report_Mutation_Response = {
   __typename?: 'Report_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Report>;
};

export type Report_Obj_Rel_Insert_Input = {
  data: Report_Insert_Input;
  on_conflict?: Maybe<Report_On_Conflict>;
};

export type Report_On_Conflict = {
  constraint: Report_Constraint;
  update_columns: Array<Report_Update_Column>;
  where?: Maybe<Report_Bool_Exp>;
};

export type Report_Order_By = {
  Notes_aggregate?: Maybe<Note_Aggregate_Order_By>;
  ReportViews_aggregate?: Maybe<ReportView_Aggregate_Order_By>;
  User?: Maybe<User_Order_By>;
  analgesics?: Maybe<Order_By>;
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  hadContactWithInfectedPatients?: Maybe<Order_By>;
  hasBeenTested?: Maybe<Order_By>;
  hasCough?: Maybe<Order_By>;
  hasDiabetes?: Maybe<Order_By>;
  hasHypertension?: Maybe<Order_By>;
  hasSymptoms?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  lastTestDate?: Maybe<Order_By>;
  lastTestResult?: Maybe<Order_By>;
  otherSymptoms?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  symptomsBeginDate?: Maybe<Order_By>;
  symptomsEvolution?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  tookPainkillers?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type Report_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

export enum Report_Select_Column {
  Analgesics = 'analgesics',
  BloodPressureMax = 'bloodPressureMax',
  BloodPressureMin = 'bloodPressureMin',
  BreathRate = 'breathRate',
  BreathingTroubleRate = 'breathingTroubleRate',
  CreatedAt = 'createdAt',
  HadContactWithInfectedPatients = 'hadContactWithInfectedPatients',
  HasBeenTested = 'hasBeenTested',
  HasCough = 'hasCough',
  HasDiabetes = 'hasDiabetes',
  HasHypertension = 'hasHypertension',
  HasSymptoms = 'hasSymptoms',
  HeartRate = 'heartRate',
  LastTestDate = 'lastTestDate',
  LastTestResult = 'lastTestResult',
  OtherSymptoms = 'otherSymptoms',
  Severity = 'severity',
  SymptomsBeginDate = 'symptomsBeginDate',
  SymptomsEvolution = 'symptomsEvolution',
  Temperature = 'temperature',
  TookPainkillers = 'tookPainkillers',
  UserUuid = 'userUUID',
  Uuid = 'uuid'
}

export type Report_Set_Input = {
  analgesics?: Maybe<Scalars['String']>;
  bloodPressureMax?: Maybe<Scalars['float8']>;
  bloodPressureMin?: Maybe<Scalars['float8']>;
  breathRate?: Maybe<Scalars['float8']>;
  breathingTroubleRate?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hadContactWithInfectedPatients?: Maybe<Scalars['Boolean']>;
  hasBeenTested?: Maybe<Scalars['Boolean']>;
  hasCough?: Maybe<Scalars['Boolean']>;
  hasDiabetes?: Maybe<Scalars['Boolean']>;
  hasHypertension?: Maybe<Scalars['Boolean']>;
  hasSymptoms?: Maybe<Scalars['Boolean']>;
  heartRate?: Maybe<Scalars['float8']>;
  lastTestDate?: Maybe<Scalars['date']>;
  lastTestResult?: Maybe<Scalars['Boolean']>;
  otherSymptoms?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['Int']>;
  symptomsBeginDate?: Maybe<Scalars['date']>;
  symptomsEvolution?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['float8']>;
  tookPainkillers?: Maybe<Scalars['Boolean']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Report_Stddev_Fields = {
   __typename?: 'Report_stddev_fields';
  bloodPressureMax?: Maybe<Scalars['Float']>;
  bloodPressureMin?: Maybe<Scalars['Float']>;
  breathRate?: Maybe<Scalars['Float']>;
  breathingTroubleRate?: Maybe<Scalars['Float']>;
  heartRate?: Maybe<Scalars['Float']>;
  severity?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

export type Report_Stddev_Order_By = {
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
};

export type Report_Stddev_Pop_Fields = {
   __typename?: 'Report_stddev_pop_fields';
  bloodPressureMax?: Maybe<Scalars['Float']>;
  bloodPressureMin?: Maybe<Scalars['Float']>;
  breathRate?: Maybe<Scalars['Float']>;
  breathingTroubleRate?: Maybe<Scalars['Float']>;
  heartRate?: Maybe<Scalars['Float']>;
  severity?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

export type Report_Stddev_Pop_Order_By = {
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
};

export type Report_Stddev_Samp_Fields = {
   __typename?: 'Report_stddev_samp_fields';
  bloodPressureMax?: Maybe<Scalars['Float']>;
  bloodPressureMin?: Maybe<Scalars['Float']>;
  breathRate?: Maybe<Scalars['Float']>;
  breathingTroubleRate?: Maybe<Scalars['Float']>;
  heartRate?: Maybe<Scalars['Float']>;
  severity?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

export type Report_Stddev_Samp_Order_By = {
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
};

export type Report_Sum_Fields = {
   __typename?: 'Report_sum_fields';
  bloodPressureMax?: Maybe<Scalars['float8']>;
  bloodPressureMin?: Maybe<Scalars['float8']>;
  breathRate?: Maybe<Scalars['float8']>;
  breathingTroubleRate?: Maybe<Scalars['Int']>;
  heartRate?: Maybe<Scalars['float8']>;
  severity?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['float8']>;
};

export type Report_Sum_Order_By = {
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
};

export enum Report_Update_Column {
  Analgesics = 'analgesics',
  BloodPressureMax = 'bloodPressureMax',
  BloodPressureMin = 'bloodPressureMin',
  BreathRate = 'breathRate',
  BreathingTroubleRate = 'breathingTroubleRate',
  CreatedAt = 'createdAt',
  HadContactWithInfectedPatients = 'hadContactWithInfectedPatients',
  HasBeenTested = 'hasBeenTested',
  HasCough = 'hasCough',
  HasDiabetes = 'hasDiabetes',
  HasHypertension = 'hasHypertension',
  HasSymptoms = 'hasSymptoms',
  HeartRate = 'heartRate',
  LastTestDate = 'lastTestDate',
  LastTestResult = 'lastTestResult',
  OtherSymptoms = 'otherSymptoms',
  Severity = 'severity',
  SymptomsBeginDate = 'symptomsBeginDate',
  SymptomsEvolution = 'symptomsEvolution',
  Temperature = 'temperature',
  TookPainkillers = 'tookPainkillers',
  UserUuid = 'userUUID',
  Uuid = 'uuid'
}

export type Report_Var_Pop_Fields = {
   __typename?: 'Report_var_pop_fields';
  bloodPressureMax?: Maybe<Scalars['Float']>;
  bloodPressureMin?: Maybe<Scalars['Float']>;
  breathRate?: Maybe<Scalars['Float']>;
  breathingTroubleRate?: Maybe<Scalars['Float']>;
  heartRate?: Maybe<Scalars['Float']>;
  severity?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

export type Report_Var_Pop_Order_By = {
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
};

export type Report_Var_Samp_Fields = {
   __typename?: 'Report_var_samp_fields';
  bloodPressureMax?: Maybe<Scalars['Float']>;
  bloodPressureMin?: Maybe<Scalars['Float']>;
  breathRate?: Maybe<Scalars['Float']>;
  breathingTroubleRate?: Maybe<Scalars['Float']>;
  heartRate?: Maybe<Scalars['Float']>;
  severity?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

export type Report_Var_Samp_Order_By = {
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
};

export type Report_Variance_Fields = {
   __typename?: 'Report_variance_fields';
  bloodPressureMax?: Maybe<Scalars['Float']>;
  bloodPressureMin?: Maybe<Scalars['Float']>;
  breathRate?: Maybe<Scalars['Float']>;
  breathingTroubleRate?: Maybe<Scalars['Float']>;
  heartRate?: Maybe<Scalars['Float']>;
  severity?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
};

export type Report_Variance_Order_By = {
  bloodPressureMax?: Maybe<Order_By>;
  bloodPressureMin?: Maybe<Order_By>;
  breathRate?: Maybe<Order_By>;
  breathingTroubleRate?: Maybe<Order_By>;
  heartRate?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
};

export type ReportView = {
   __typename?: 'ReportView';
  Report: Report;
  User: User;
  createdAt: Scalars['timestamptz'];
  reportUUID: Scalars['uuid'];
  userUUID: Scalars['uuid'];
  uuid: Scalars['uuid'];
};

export type ReportView_Aggregate = {
   __typename?: 'ReportView_aggregate';
  aggregate?: Maybe<ReportView_Aggregate_Fields>;
  nodes: Array<ReportView>;
};

export type ReportView_Aggregate_Fields = {
   __typename?: 'ReportView_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ReportView_Max_Fields>;
  min?: Maybe<ReportView_Min_Fields>;
};


export type ReportView_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ReportView_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type ReportView_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ReportView_Max_Order_By>;
  min?: Maybe<ReportView_Min_Order_By>;
};

export type ReportView_Arr_Rel_Insert_Input = {
  data: Array<ReportView_Insert_Input>;
  on_conflict?: Maybe<ReportView_On_Conflict>;
};

export type ReportView_Bool_Exp = {
  Report?: Maybe<Report_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<ReportView_Bool_Exp>>>;
  _not?: Maybe<ReportView_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ReportView_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  reportUUID?: Maybe<Uuid_Comparison_Exp>;
  userUUID?: Maybe<Uuid_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

export enum ReportView_Constraint {
  ReportViewPkey = 'ReportView_pkey'
}

export type ReportView_Insert_Input = {
  Report?: Maybe<Report_Obj_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  reportUUID?: Maybe<Scalars['uuid']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type ReportView_Max_Fields = {
   __typename?: 'ReportView_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  reportUUID?: Maybe<Scalars['uuid']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type ReportView_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  reportUUID?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type ReportView_Min_Fields = {
   __typename?: 'ReportView_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  reportUUID?: Maybe<Scalars['uuid']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type ReportView_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  reportUUID?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type ReportView_Mutation_Response = {
   __typename?: 'ReportView_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ReportView>;
};

export type ReportView_Obj_Rel_Insert_Input = {
  data: ReportView_Insert_Input;
  on_conflict?: Maybe<ReportView_On_Conflict>;
};

export type ReportView_On_Conflict = {
  constraint: ReportView_Constraint;
  update_columns: Array<ReportView_Update_Column>;
  where?: Maybe<ReportView_Bool_Exp>;
};

export type ReportView_Order_By = {
  Report?: Maybe<Report_Order_By>;
  User?: Maybe<User_Order_By>;
  createdAt?: Maybe<Order_By>;
  reportUUID?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type ReportView_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

export enum ReportView_Select_Column {
  CreatedAt = 'createdAt',
  ReportUuid = 'reportUUID',
  UserUuid = 'userUUID',
  Uuid = 'uuid'
}

export type ReportView_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  reportUUID?: Maybe<Scalars['uuid']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export enum ReportView_Update_Column {
  CreatedAt = 'createdAt',
  ReportUuid = 'reportUUID',
  UserUuid = 'userUUID',
  Uuid = 'uuid'
}

export type Session = {
   __typename?: 'Session';
  User: User;
  createdAt: Scalars['timestamptz'];
  expiresAt: Scalars['timestamptz'];
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userUUID: Scalars['uuid'];
  uuid: Scalars['uuid'];
};

export type Session_Aggregate = {
   __typename?: 'Session_aggregate';
  aggregate?: Maybe<Session_Aggregate_Fields>;
  nodes: Array<Session>;
};

export type Session_Aggregate_Fields = {
   __typename?: 'Session_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Session_Max_Fields>;
  min?: Maybe<Session_Min_Fields>;
};


export type Session_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Session_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type Session_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Session_Max_Order_By>;
  min?: Maybe<Session_Min_Order_By>;
};

export type Session_Arr_Rel_Insert_Input = {
  data: Array<Session_Insert_Input>;
  on_conflict?: Maybe<Session_On_Conflict>;
};

export type Session_Bool_Exp = {
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Session_Bool_Exp>>>;
  _not?: Maybe<Session_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Session_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  expiresAt?: Maybe<Timestamptz_Comparison_Exp>;
  revokedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userUUID?: Maybe<Uuid_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

export enum Session_Constraint {
  SessionPkey = 'Session_pkey'
}

export type Session_Insert_Input = {
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Session_Max_Fields = {
   __typename?: 'Session_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Session_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiresAt?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type Session_Min_Fields = {
   __typename?: 'Session_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type Session_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiresAt?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type Session_Mutation_Response = {
   __typename?: 'Session_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Session>;
};

export type Session_Obj_Rel_Insert_Input = {
  data: Session_Insert_Input;
  on_conflict?: Maybe<Session_On_Conflict>;
};

export type Session_On_Conflict = {
  constraint: Session_Constraint;
  update_columns: Array<Session_Update_Column>;
  where?: Maybe<Session_Bool_Exp>;
};

export type Session_Order_By = {
  User?: Maybe<User_Order_By>;
  createdAt?: Maybe<Order_By>;
  expiresAt?: Maybe<Order_By>;
  revokedAt?: Maybe<Order_By>;
  userUUID?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export type Session_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

export enum Session_Select_Column {
  CreatedAt = 'createdAt',
  ExpiresAt = 'expiresAt',
  RevokedAt = 'revokedAt',
  UserUuid = 'userUUID',
  Uuid = 'uuid'
}

export type Session_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  userUUID?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export enum Session_Update_Column {
  CreatedAt = 'createdAt',
  ExpiresAt = 'expiresAt',
  RevokedAt = 'revokedAt',
  UserUuid = 'userUUID',
  Uuid = 'uuid'
}

export type Stats = {
   __typename?: 'Stats';
  byZipCode: Array<StatsByZipCode>;
  lastUpdatedAt: Scalars['Time'];
};

export type StatsByZipCode = {
   __typename?: 'StatsByZipCode';
  totalUsers: Scalars['Int'];
  usersInTriage: Scalars['Int'];
  usersInfected: Scalars['Int'];
  usersWithHighRisk: Scalars['Int'];
  usersWithLowRisk: Scalars['Int'];
  usersWithoutReports: Scalars['Int'];
  zipCode: Scalars['String'];
};

export type StatsByZipCodes = {
   __typename?: 'StatsByZipCodes';
  totalUsers?: Maybe<Scalars['bigint']>;
  usersInTriage?: Maybe<Scalars['bigint']>;
  usersInfected?: Maybe<Scalars['bigint']>;
  usersWithHighRisk?: Maybe<Scalars['bigint']>;
  usersWithLowRisk?: Maybe<Scalars['bigint']>;
  usersWithoutReports?: Maybe<Scalars['bigint']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type StatsByZipCodes_Aggregate = {
   __typename?: 'StatsByZipCodes_aggregate';
  aggregate?: Maybe<StatsByZipCodes_Aggregate_Fields>;
  nodes: Array<StatsByZipCodes>;
};

export type StatsByZipCodes_Aggregate_Fields = {
   __typename?: 'StatsByZipCodes_aggregate_fields';
  avg?: Maybe<StatsByZipCodes_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<StatsByZipCodes_Max_Fields>;
  min?: Maybe<StatsByZipCodes_Min_Fields>;
  stddev?: Maybe<StatsByZipCodes_Stddev_Fields>;
  stddev_pop?: Maybe<StatsByZipCodes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<StatsByZipCodes_Stddev_Samp_Fields>;
  sum?: Maybe<StatsByZipCodes_Sum_Fields>;
  var_pop?: Maybe<StatsByZipCodes_Var_Pop_Fields>;
  var_samp?: Maybe<StatsByZipCodes_Var_Samp_Fields>;
  variance?: Maybe<StatsByZipCodes_Variance_Fields>;
};


export type StatsByZipCodes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<StatsByZipCodes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type StatsByZipCodes_Aggregate_Order_By = {
  avg?: Maybe<StatsByZipCodes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<StatsByZipCodes_Max_Order_By>;
  min?: Maybe<StatsByZipCodes_Min_Order_By>;
  stddev?: Maybe<StatsByZipCodes_Stddev_Order_By>;
  stddev_pop?: Maybe<StatsByZipCodes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<StatsByZipCodes_Stddev_Samp_Order_By>;
  sum?: Maybe<StatsByZipCodes_Sum_Order_By>;
  var_pop?: Maybe<StatsByZipCodes_Var_Pop_Order_By>;
  var_samp?: Maybe<StatsByZipCodes_Var_Samp_Order_By>;
  variance?: Maybe<StatsByZipCodes_Variance_Order_By>;
};

export type StatsByZipCodes_Avg_Fields = {
   __typename?: 'StatsByZipCodes_avg_fields';
  totalUsers?: Maybe<Scalars['Float']>;
  usersInTriage?: Maybe<Scalars['Float']>;
  usersInfected?: Maybe<Scalars['Float']>;
  usersWithHighRisk?: Maybe<Scalars['Float']>;
  usersWithLowRisk?: Maybe<Scalars['Float']>;
  usersWithoutReports?: Maybe<Scalars['Float']>;
};

export type StatsByZipCodes_Avg_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
};

export type StatsByZipCodes_Bool_Exp = {
  _and?: Maybe<Array<Maybe<StatsByZipCodes_Bool_Exp>>>;
  _not?: Maybe<StatsByZipCodes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<StatsByZipCodes_Bool_Exp>>>;
  totalUsers?: Maybe<Bigint_Comparison_Exp>;
  usersInTriage?: Maybe<Bigint_Comparison_Exp>;
  usersInfected?: Maybe<Bigint_Comparison_Exp>;
  usersWithHighRisk?: Maybe<Bigint_Comparison_Exp>;
  usersWithLowRisk?: Maybe<Bigint_Comparison_Exp>;
  usersWithoutReports?: Maybe<Bigint_Comparison_Exp>;
  zipCode?: Maybe<String_Comparison_Exp>;
};

export type StatsByZipCodes_Max_Fields = {
   __typename?: 'StatsByZipCodes_max_fields';
  totalUsers?: Maybe<Scalars['bigint']>;
  usersInTriage?: Maybe<Scalars['bigint']>;
  usersInfected?: Maybe<Scalars['bigint']>;
  usersWithHighRisk?: Maybe<Scalars['bigint']>;
  usersWithLowRisk?: Maybe<Scalars['bigint']>;
  usersWithoutReports?: Maybe<Scalars['bigint']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type StatsByZipCodes_Max_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

export type StatsByZipCodes_Min_Fields = {
   __typename?: 'StatsByZipCodes_min_fields';
  totalUsers?: Maybe<Scalars['bigint']>;
  usersInTriage?: Maybe<Scalars['bigint']>;
  usersInfected?: Maybe<Scalars['bigint']>;
  usersWithHighRisk?: Maybe<Scalars['bigint']>;
  usersWithLowRisk?: Maybe<Scalars['bigint']>;
  usersWithoutReports?: Maybe<Scalars['bigint']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type StatsByZipCodes_Min_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

export type StatsByZipCodes_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

export enum StatsByZipCodes_Select_Column {
  TotalUsers = 'totalUsers',
  UsersInTriage = 'usersInTriage',
  UsersInfected = 'usersInfected',
  UsersWithHighRisk = 'usersWithHighRisk',
  UsersWithLowRisk = 'usersWithLowRisk',
  UsersWithoutReports = 'usersWithoutReports',
  ZipCode = 'zipCode'
}

export type StatsByZipCodes_Stddev_Fields = {
   __typename?: 'StatsByZipCodes_stddev_fields';
  totalUsers?: Maybe<Scalars['Float']>;
  usersInTriage?: Maybe<Scalars['Float']>;
  usersInfected?: Maybe<Scalars['Float']>;
  usersWithHighRisk?: Maybe<Scalars['Float']>;
  usersWithLowRisk?: Maybe<Scalars['Float']>;
  usersWithoutReports?: Maybe<Scalars['Float']>;
};

export type StatsByZipCodes_Stddev_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
};

export type StatsByZipCodes_Stddev_Pop_Fields = {
   __typename?: 'StatsByZipCodes_stddev_pop_fields';
  totalUsers?: Maybe<Scalars['Float']>;
  usersInTriage?: Maybe<Scalars['Float']>;
  usersInfected?: Maybe<Scalars['Float']>;
  usersWithHighRisk?: Maybe<Scalars['Float']>;
  usersWithLowRisk?: Maybe<Scalars['Float']>;
  usersWithoutReports?: Maybe<Scalars['Float']>;
};

export type StatsByZipCodes_Stddev_Pop_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
};

export type StatsByZipCodes_Stddev_Samp_Fields = {
   __typename?: 'StatsByZipCodes_stddev_samp_fields';
  totalUsers?: Maybe<Scalars['Float']>;
  usersInTriage?: Maybe<Scalars['Float']>;
  usersInfected?: Maybe<Scalars['Float']>;
  usersWithHighRisk?: Maybe<Scalars['Float']>;
  usersWithLowRisk?: Maybe<Scalars['Float']>;
  usersWithoutReports?: Maybe<Scalars['Float']>;
};

export type StatsByZipCodes_Stddev_Samp_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
};

export type StatsByZipCodes_Sum_Fields = {
   __typename?: 'StatsByZipCodes_sum_fields';
  totalUsers?: Maybe<Scalars['bigint']>;
  usersInTriage?: Maybe<Scalars['bigint']>;
  usersInfected?: Maybe<Scalars['bigint']>;
  usersWithHighRisk?: Maybe<Scalars['bigint']>;
  usersWithLowRisk?: Maybe<Scalars['bigint']>;
  usersWithoutReports?: Maybe<Scalars['bigint']>;
};

export type StatsByZipCodes_Sum_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
};

export type StatsByZipCodes_Var_Pop_Fields = {
   __typename?: 'StatsByZipCodes_var_pop_fields';
  totalUsers?: Maybe<Scalars['Float']>;
  usersInTriage?: Maybe<Scalars['Float']>;
  usersInfected?: Maybe<Scalars['Float']>;
  usersWithHighRisk?: Maybe<Scalars['Float']>;
  usersWithLowRisk?: Maybe<Scalars['Float']>;
  usersWithoutReports?: Maybe<Scalars['Float']>;
};

export type StatsByZipCodes_Var_Pop_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
};

export type StatsByZipCodes_Var_Samp_Fields = {
   __typename?: 'StatsByZipCodes_var_samp_fields';
  totalUsers?: Maybe<Scalars['Float']>;
  usersInTriage?: Maybe<Scalars['Float']>;
  usersInfected?: Maybe<Scalars['Float']>;
  usersWithHighRisk?: Maybe<Scalars['Float']>;
  usersWithLowRisk?: Maybe<Scalars['Float']>;
  usersWithoutReports?: Maybe<Scalars['Float']>;
};

export type StatsByZipCodes_Var_Samp_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
};

export type StatsByZipCodes_Variance_Fields = {
   __typename?: 'StatsByZipCodes_variance_fields';
  totalUsers?: Maybe<Scalars['Float']>;
  usersInTriage?: Maybe<Scalars['Float']>;
  usersInfected?: Maybe<Scalars['Float']>;
  usersWithHighRisk?: Maybe<Scalars['Float']>;
  usersWithLowRisk?: Maybe<Scalars['Float']>;
  usersWithoutReports?: Maybe<Scalars['Float']>;
};

export type StatsByZipCodes_Variance_Order_By = {
  totalUsers?: Maybe<Order_By>;
  usersInTriage?: Maybe<Order_By>;
  usersInfected?: Maybe<Order_By>;
  usersWithHighRisk?: Maybe<Order_By>;
  usersWithLowRisk?: Maybe<Order_By>;
  usersWithoutReports?: Maybe<Order_By>;
};

export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type Subscription_Root = {
   __typename?: 'subscription_root';
  Note: Array<Note>;
  Note_aggregate: Note_Aggregate;
  Note_by_pk?: Maybe<Note>;
  Report: Array<Report>;
  ReportView: Array<ReportView>;
  ReportView_aggregate: ReportView_Aggregate;
  ReportView_by_pk?: Maybe<ReportView>;
  Report_aggregate: Report_Aggregate;
  Report_by_pk?: Maybe<Report>;
  Session: Array<Session>;
  Session_aggregate: Session_Aggregate;
  Session_by_pk?: Maybe<Session>;
  StatsByZipCodes: Array<StatsByZipCodes>;
  StatsByZipCodes_aggregate: StatsByZipCodes_Aggregate;
  User: Array<User>;
  UserGender: Array<UserGender>;
  UserGender_aggregate: UserGender_Aggregate;
  UserGender_by_pk?: Maybe<UserGender>;
  UserRole: Array<UserRole>;
  UserRole_aggregate: UserRole_Aggregate;
  UserRole_by_pk?: Maybe<UserRole>;
  UserSeverity: Array<UserSeverity>;
  UserSeverity_aggregate: UserSeverity_Aggregate;
  UserSeverity_by_pk?: Maybe<UserSeverity>;
  UserStatus: Array<UserStatus>;
  UserStatus_aggregate: UserStatus_Aggregate;
  UserStatus_by_pk?: Maybe<UserStatus>;
  User_aggregate: User_Aggregate;
  User_by_pk?: Maybe<User>;
};


export type Subscription_RootNoteArgs = {
  distinct_on?: Maybe<Array<Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type Subscription_RootNote_AggregateArgs = {
  distinct_on?: Maybe<Array<Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type Subscription_RootNote_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Subscription_RootReportArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


export type Subscription_RootReportViewArgs = {
  distinct_on?: Maybe<Array<ReportView_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReportView_Order_By>>;
  where?: Maybe<ReportView_Bool_Exp>;
};


export type Subscription_RootReportView_AggregateArgs = {
  distinct_on?: Maybe<Array<ReportView_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ReportView_Order_By>>;
  where?: Maybe<ReportView_Bool_Exp>;
};


export type Subscription_RootReportView_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Subscription_RootReport_AggregateArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


export type Subscription_RootReport_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Subscription_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Subscription_RootStatsByZipCodesArgs = {
  distinct_on?: Maybe<Array<StatsByZipCodes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StatsByZipCodes_Order_By>>;
  where?: Maybe<StatsByZipCodes_Bool_Exp>;
};


export type Subscription_RootStatsByZipCodes_AggregateArgs = {
  distinct_on?: Maybe<Array<StatsByZipCodes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StatsByZipCodes_Order_By>>;
  where?: Maybe<StatsByZipCodes_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUserGenderArgs = {
  distinct_on?: Maybe<Array<UserGender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGender_Order_By>>;
  where?: Maybe<UserGender_Bool_Exp>;
};


export type Subscription_RootUserGender_AggregateArgs = {
  distinct_on?: Maybe<Array<UserGender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserGender_Order_By>>;
  where?: Maybe<UserGender_Bool_Exp>;
};


export type Subscription_RootUserGender_By_PkArgs = {
  gender: Scalars['String'];
};


export type Subscription_RootUserRoleArgs = {
  distinct_on?: Maybe<Array<UserRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserRole_Order_By>>;
  where?: Maybe<UserRole_Bool_Exp>;
};


export type Subscription_RootUserRole_AggregateArgs = {
  distinct_on?: Maybe<Array<UserRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserRole_Order_By>>;
  where?: Maybe<UserRole_Bool_Exp>;
};


export type Subscription_RootUserRole_By_PkArgs = {
  role: Scalars['String'];
};


export type Subscription_RootUserSeverityArgs = {
  distinct_on?: Maybe<Array<UserSeverity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserSeverity_Order_By>>;
  where?: Maybe<UserSeverity_Bool_Exp>;
};


export type Subscription_RootUserSeverity_AggregateArgs = {
  distinct_on?: Maybe<Array<UserSeverity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserSeverity_Order_By>>;
  where?: Maybe<UserSeverity_Bool_Exp>;
};


export type Subscription_RootUserSeverity_By_PkArgs = {
  severity: Scalars['String'];
};


export type Subscription_RootUserStatusArgs = {
  distinct_on?: Maybe<Array<UserStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserStatus_Order_By>>;
  where?: Maybe<UserStatus_Bool_Exp>;
};


export type Subscription_RootUserStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<UserStatus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserStatus_Order_By>>;
  where?: Maybe<UserStatus_Bool_Exp>;
};


export type Subscription_RootUserStatus_By_PkArgs = {
  status: Scalars['String'];
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  uuid: Scalars['uuid'];
};



export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type User = {
   __typename?: 'User';
  Notes: Array<Note>;
  Notes_aggregate: Note_Aggregate;
  Reports: Array<Report>;
  Reports_aggregate: Report_Aggregate;
  Sessions: Array<Session>;
  Sessions_aggregate: Session_Aggregate;
  acceptedDataUsage?: Maybe<Scalars['timestamptz']>;
  acceptedPrivacy?: Maybe<Scalars['timestamptz']>;
  acceptedTC?: Maybe<Scalars['timestamptz']>;
  chronicMedication?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  firstSymptomDate?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  hasDiabetes?: Maybe<Scalars['Boolean']>;
  hasHypertension?: Maybe<Scalars['Boolean']>;
  hypertensionMedication?: Maybe<Scalars['String']>;
  idCardNumber?: Maybe<Scalars['String']>;
  lastReport?: Maybe<Array<Report>>;
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  prexistingConditions?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  severity: Scalars['String'];
  status: Scalars['String'];
  uuid: Scalars['uuid'];
  zipCode?: Maybe<Scalars['String']>;
  zipCode4?: Maybe<Scalars['String']>;
};


export type UserNotesArgs = {
  distinct_on?: Maybe<Array<Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type UserNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Note_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Order_By>>;
  where?: Maybe<Note_Bool_Exp>;
};


export type UserReportsArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


export type UserReports_AggregateArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


export type UserSessionsArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type UserSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type UserLastReportArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};

export type User_Aggregate = {
   __typename?: 'User_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Fields = {
   __typename?: 'User_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
};

export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

export type User_Bool_Exp = {
  Notes?: Maybe<Note_Bool_Exp>;
  Reports?: Maybe<Report_Bool_Exp>;
  Sessions?: Maybe<Session_Bool_Exp>;
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  acceptedDataUsage?: Maybe<Timestamptz_Comparison_Exp>;
  acceptedPrivacy?: Maybe<Timestamptz_Comparison_Exp>;
  acceptedTC?: Maybe<Timestamptz_Comparison_Exp>;
  chronicMedication?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  dob?: Maybe<Date_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firstSymptomDate?: Maybe<Timestamptz_Comparison_Exp>;
  gender?: Maybe<String_Comparison_Exp>;
  hasDiabetes?: Maybe<Boolean_Comparison_Exp>;
  hasHypertension?: Maybe<Boolean_Comparison_Exp>;
  hypertensionMedication?: Maybe<String_Comparison_Exp>;
  idCardNumber?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phoneNumber?: Maybe<String_Comparison_Exp>;
  prexistingConditions?: Maybe<String_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  severity?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
  zipCode?: Maybe<String_Comparison_Exp>;
};

export enum User_Constraint {
  UserPkey = 'User_pkey'
}

export type User_Insert_Input = {
  Notes?: Maybe<Note_Arr_Rel_Insert_Input>;
  Reports?: Maybe<Report_Arr_Rel_Insert_Input>;
  Sessions?: Maybe<Session_Arr_Rel_Insert_Input>;
  acceptedDataUsage?: Maybe<Scalars['timestamptz']>;
  acceptedPrivacy?: Maybe<Scalars['timestamptz']>;
  acceptedTC?: Maybe<Scalars['timestamptz']>;
  chronicMedication?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  firstSymptomDate?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  hasDiabetes?: Maybe<Scalars['Boolean']>;
  hasHypertension?: Maybe<Scalars['Boolean']>;
  hypertensionMedication?: Maybe<Scalars['String']>;
  idCardNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prexistingConditions?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type User_Max_Fields = {
   __typename?: 'User_max_fields';
  acceptedDataUsage?: Maybe<Scalars['timestamptz']>;
  acceptedPrivacy?: Maybe<Scalars['timestamptz']>;
  acceptedTC?: Maybe<Scalars['timestamptz']>;
  chronicMedication?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  firstSymptomDate?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  hypertensionMedication?: Maybe<Scalars['String']>;
  idCardNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prexistingConditions?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type User_Max_Order_By = {
  acceptedDataUsage?: Maybe<Order_By>;
  acceptedPrivacy?: Maybe<Order_By>;
  acceptedTC?: Maybe<Order_By>;
  chronicMedication?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstSymptomDate?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  hypertensionMedication?: Maybe<Order_By>;
  idCardNumber?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  prexistingConditions?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

export type User_Min_Fields = {
   __typename?: 'User_min_fields';
  acceptedDataUsage?: Maybe<Scalars['timestamptz']>;
  acceptedPrivacy?: Maybe<Scalars['timestamptz']>;
  acceptedTC?: Maybe<Scalars['timestamptz']>;
  chronicMedication?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  firstSymptomDate?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  hypertensionMedication?: Maybe<Scalars['String']>;
  idCardNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prexistingConditions?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type User_Min_Order_By = {
  acceptedDataUsage?: Maybe<Order_By>;
  acceptedPrivacy?: Maybe<Order_By>;
  acceptedTC?: Maybe<Order_By>;
  chronicMedication?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstSymptomDate?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  hypertensionMedication?: Maybe<Order_By>;
  idCardNumber?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  prexistingConditions?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

export type User_Mutation_Response = {
   __typename?: 'User_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User>;
};

export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

export type User_Order_By = {
  Notes_aggregate?: Maybe<Note_Aggregate_Order_By>;
  Reports_aggregate?: Maybe<Report_Aggregate_Order_By>;
  Sessions_aggregate?: Maybe<Session_Aggregate_Order_By>;
  acceptedDataUsage?: Maybe<Order_By>;
  acceptedPrivacy?: Maybe<Order_By>;
  acceptedTC?: Maybe<Order_By>;
  chronicMedication?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstSymptomDate?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  hasDiabetes?: Maybe<Order_By>;
  hasHypertension?: Maybe<Order_By>;
  hypertensionMedication?: Maybe<Order_By>;
  idCardNumber?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  prexistingConditions?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  severity?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

export type User_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

export enum User_Select_Column {
  AcceptedDataUsage = 'acceptedDataUsage',
  AcceptedPrivacy = 'acceptedPrivacy',
  AcceptedTc = 'acceptedTC',
  ChronicMedication = 'chronicMedication',
  CreatedAt = 'createdAt',
  Dob = 'dob',
  Email = 'email',
  FirstSymptomDate = 'firstSymptomDate',
  Gender = 'gender',
  HasDiabetes = 'hasDiabetes',
  HasHypertension = 'hasHypertension',
  HypertensionMedication = 'hypertensionMedication',
  IdCardNumber = 'idCardNumber',
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  PrexistingConditions = 'prexistingConditions',
  Role = 'role',
  Severity = 'severity',
  Status = 'status',
  Uuid = 'uuid',
  ZipCode = 'zipCode'
}

export type User_Set_Input = {
  acceptedDataUsage?: Maybe<Scalars['timestamptz']>;
  acceptedPrivacy?: Maybe<Scalars['timestamptz']>;
  acceptedTC?: Maybe<Scalars['timestamptz']>;
  chronicMedication?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  firstSymptomDate?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  hasDiabetes?: Maybe<Scalars['Boolean']>;
  hasHypertension?: Maybe<Scalars['Boolean']>;
  hypertensionMedication?: Maybe<Scalars['String']>;
  idCardNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prexistingConditions?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
  zipCode?: Maybe<Scalars['String']>;
};

export enum User_Update_Column {
  AcceptedDataUsage = 'acceptedDataUsage',
  AcceptedPrivacy = 'acceptedPrivacy',
  AcceptedTc = 'acceptedTC',
  ChronicMedication = 'chronicMedication',
  CreatedAt = 'createdAt',
  Dob = 'dob',
  Email = 'email',
  FirstSymptomDate = 'firstSymptomDate',
  Gender = 'gender',
  HasDiabetes = 'hasDiabetes',
  HasHypertension = 'hasHypertension',
  HypertensionMedication = 'hypertensionMedication',
  IdCardNumber = 'idCardNumber',
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  PrexistingConditions = 'prexistingConditions',
  Role = 'role',
  Severity = 'severity',
  Status = 'status',
  Uuid = 'uuid',
  ZipCode = 'zipCode'
}

export type UserGender = {
   __typename?: 'UserGender';
  gender: Scalars['String'];
};

export type UserGender_Aggregate = {
   __typename?: 'UserGender_aggregate';
  aggregate?: Maybe<UserGender_Aggregate_Fields>;
  nodes: Array<UserGender>;
};

export type UserGender_Aggregate_Fields = {
   __typename?: 'UserGender_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UserGender_Max_Fields>;
  min?: Maybe<UserGender_Min_Fields>;
};


export type UserGender_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserGender_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type UserGender_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserGender_Max_Order_By>;
  min?: Maybe<UserGender_Min_Order_By>;
};

export type UserGender_Arr_Rel_Insert_Input = {
  data: Array<UserGender_Insert_Input>;
  on_conflict?: Maybe<UserGender_On_Conflict>;
};

export type UserGender_Bool_Exp = {
  _and?: Maybe<Array<Maybe<UserGender_Bool_Exp>>>;
  _not?: Maybe<UserGender_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserGender_Bool_Exp>>>;
  gender?: Maybe<String_Comparison_Exp>;
};

export enum UserGender_Constraint {
  UserGenderPkey = 'UserGender_pkey'
}

export type UserGender_Insert_Input = {
  gender?: Maybe<Scalars['String']>;
};

export type UserGender_Max_Fields = {
   __typename?: 'UserGender_max_fields';
  gender?: Maybe<Scalars['String']>;
};

export type UserGender_Max_Order_By = {
  gender?: Maybe<Order_By>;
};

export type UserGender_Min_Fields = {
   __typename?: 'UserGender_min_fields';
  gender?: Maybe<Scalars['String']>;
};

export type UserGender_Min_Order_By = {
  gender?: Maybe<Order_By>;
};

export type UserGender_Mutation_Response = {
   __typename?: 'UserGender_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<UserGender>;
};

export type UserGender_Obj_Rel_Insert_Input = {
  data: UserGender_Insert_Input;
  on_conflict?: Maybe<UserGender_On_Conflict>;
};

export type UserGender_On_Conflict = {
  constraint: UserGender_Constraint;
  update_columns: Array<UserGender_Update_Column>;
  where?: Maybe<UserGender_Bool_Exp>;
};

export type UserGender_Order_By = {
  gender?: Maybe<Order_By>;
};

export type UserGender_Pk_Columns_Input = {
  gender: Scalars['String'];
};

export enum UserGender_Select_Column {
  Gender = 'gender'
}

export type UserGender_Set_Input = {
  gender?: Maybe<Scalars['String']>;
};

export enum UserGender_Update_Column {
  Gender = 'gender'
}

export type UserRole = {
   __typename?: 'UserRole';
  role: Scalars['String'];
};

export type UserRole_Aggregate = {
   __typename?: 'UserRole_aggregate';
  aggregate?: Maybe<UserRole_Aggregate_Fields>;
  nodes: Array<UserRole>;
};

export type UserRole_Aggregate_Fields = {
   __typename?: 'UserRole_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UserRole_Max_Fields>;
  min?: Maybe<UserRole_Min_Fields>;
};


export type UserRole_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserRole_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type UserRole_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserRole_Max_Order_By>;
  min?: Maybe<UserRole_Min_Order_By>;
};

export type UserRole_Arr_Rel_Insert_Input = {
  data: Array<UserRole_Insert_Input>;
  on_conflict?: Maybe<UserRole_On_Conflict>;
};

export type UserRole_Bool_Exp = {
  _and?: Maybe<Array<Maybe<UserRole_Bool_Exp>>>;
  _not?: Maybe<UserRole_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserRole_Bool_Exp>>>;
  role?: Maybe<String_Comparison_Exp>;
};

export enum UserRole_Constraint {
  UserRolePkey = 'UserRole_pkey'
}

export type UserRole_Insert_Input = {
  role?: Maybe<Scalars['String']>;
};

export type UserRole_Max_Fields = {
   __typename?: 'UserRole_max_fields';
  role?: Maybe<Scalars['String']>;
};

export type UserRole_Max_Order_By = {
  role?: Maybe<Order_By>;
};

export type UserRole_Min_Fields = {
   __typename?: 'UserRole_min_fields';
  role?: Maybe<Scalars['String']>;
};

export type UserRole_Min_Order_By = {
  role?: Maybe<Order_By>;
};

export type UserRole_Mutation_Response = {
   __typename?: 'UserRole_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<UserRole>;
};

export type UserRole_Obj_Rel_Insert_Input = {
  data: UserRole_Insert_Input;
  on_conflict?: Maybe<UserRole_On_Conflict>;
};

export type UserRole_On_Conflict = {
  constraint: UserRole_Constraint;
  update_columns: Array<UserRole_Update_Column>;
  where?: Maybe<UserRole_Bool_Exp>;
};

export type UserRole_Order_By = {
  role?: Maybe<Order_By>;
};

export type UserRole_Pk_Columns_Input = {
  role: Scalars['String'];
};

export enum UserRole_Select_Column {
  Role = 'role'
}

export type UserRole_Set_Input = {
  role?: Maybe<Scalars['String']>;
};

export enum UserRole_Update_Column {
  Role = 'role'
}

export type UserSeverity = {
   __typename?: 'UserSeverity';
  severity: Scalars['String'];
};

export type UserSeverity_Aggregate = {
   __typename?: 'UserSeverity_aggregate';
  aggregate?: Maybe<UserSeverity_Aggregate_Fields>;
  nodes: Array<UserSeverity>;
};

export type UserSeverity_Aggregate_Fields = {
   __typename?: 'UserSeverity_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UserSeverity_Max_Fields>;
  min?: Maybe<UserSeverity_Min_Fields>;
};


export type UserSeverity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserSeverity_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type UserSeverity_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserSeverity_Max_Order_By>;
  min?: Maybe<UserSeverity_Min_Order_By>;
};

export type UserSeverity_Arr_Rel_Insert_Input = {
  data: Array<UserSeverity_Insert_Input>;
  on_conflict?: Maybe<UserSeverity_On_Conflict>;
};

export type UserSeverity_Bool_Exp = {
  _and?: Maybe<Array<Maybe<UserSeverity_Bool_Exp>>>;
  _not?: Maybe<UserSeverity_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserSeverity_Bool_Exp>>>;
  severity?: Maybe<String_Comparison_Exp>;
};

export enum UserSeverity_Constraint {
  UserSeverityPkey = 'UserSeverity_pkey'
}

export type UserSeverity_Insert_Input = {
  severity?: Maybe<Scalars['String']>;
};

export type UserSeverity_Max_Fields = {
   __typename?: 'UserSeverity_max_fields';
  severity?: Maybe<Scalars['String']>;
};

export type UserSeverity_Max_Order_By = {
  severity?: Maybe<Order_By>;
};

export type UserSeverity_Min_Fields = {
   __typename?: 'UserSeverity_min_fields';
  severity?: Maybe<Scalars['String']>;
};

export type UserSeverity_Min_Order_By = {
  severity?: Maybe<Order_By>;
};

export type UserSeverity_Mutation_Response = {
   __typename?: 'UserSeverity_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<UserSeverity>;
};

export type UserSeverity_Obj_Rel_Insert_Input = {
  data: UserSeverity_Insert_Input;
  on_conflict?: Maybe<UserSeverity_On_Conflict>;
};

export type UserSeverity_On_Conflict = {
  constraint: UserSeverity_Constraint;
  update_columns: Array<UserSeverity_Update_Column>;
  where?: Maybe<UserSeverity_Bool_Exp>;
};

export type UserSeverity_Order_By = {
  severity?: Maybe<Order_By>;
};

export type UserSeverity_Pk_Columns_Input = {
  severity: Scalars['String'];
};

export enum UserSeverity_Select_Column {
  Severity = 'severity'
}

export type UserSeverity_Set_Input = {
  severity?: Maybe<Scalars['String']>;
};

export enum UserSeverity_Update_Column {
  Severity = 'severity'
}

export type UserStatus = {
   __typename?: 'UserStatus';
  status: Scalars['String'];
};

export type UserStatus_Aggregate = {
   __typename?: 'UserStatus_aggregate';
  aggregate?: Maybe<UserStatus_Aggregate_Fields>;
  nodes: Array<UserStatus>;
};

export type UserStatus_Aggregate_Fields = {
   __typename?: 'UserStatus_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UserStatus_Max_Fields>;
  min?: Maybe<UserStatus_Min_Fields>;
};


export type UserStatus_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserStatus_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

export type UserStatus_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserStatus_Max_Order_By>;
  min?: Maybe<UserStatus_Min_Order_By>;
};

export type UserStatus_Arr_Rel_Insert_Input = {
  data: Array<UserStatus_Insert_Input>;
  on_conflict?: Maybe<UserStatus_On_Conflict>;
};

export type UserStatus_Bool_Exp = {
  _and?: Maybe<Array<Maybe<UserStatus_Bool_Exp>>>;
  _not?: Maybe<UserStatus_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserStatus_Bool_Exp>>>;
  status?: Maybe<String_Comparison_Exp>;
};

export enum UserStatus_Constraint {
  UserStatusPkey = 'UserStatus_pkey'
}

export type UserStatus_Insert_Input = {
  status?: Maybe<Scalars['String']>;
};

export type UserStatus_Max_Fields = {
   __typename?: 'UserStatus_max_fields';
  status?: Maybe<Scalars['String']>;
};

export type UserStatus_Max_Order_By = {
  status?: Maybe<Order_By>;
};

export type UserStatus_Min_Fields = {
   __typename?: 'UserStatus_min_fields';
  status?: Maybe<Scalars['String']>;
};

export type UserStatus_Min_Order_By = {
  status?: Maybe<Order_By>;
};

export type UserStatus_Mutation_Response = {
   __typename?: 'UserStatus_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<UserStatus>;
};

export type UserStatus_Obj_Rel_Insert_Input = {
  data: UserStatus_Insert_Input;
  on_conflict?: Maybe<UserStatus_On_Conflict>;
};

export type UserStatus_On_Conflict = {
  constraint: UserStatus_Constraint;
  update_columns: Array<UserStatus_Update_Column>;
  where?: Maybe<UserStatus_Bool_Exp>;
};

export type UserStatus_Order_By = {
  status?: Maybe<Order_By>;
};

export type UserStatus_Pk_Columns_Input = {
  status: Scalars['String'];
};

export enum UserStatus_Select_Column {
  Status = 'status'
}

export type UserStatus_Set_Input = {
  status?: Maybe<Scalars['String']>;
};

export enum UserStatus_Update_Column {
  Status = 'status'
}


export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type AccountAcceptTermsMutationVariables = {
  phoneNumber: Scalars['String'];
};


export type AccountAcceptTermsMutation = (
  { __typename?: 'mutation_root' }
  & { accepted: Mutation_Root['acceptAllTerms'] }
);

export type AuthDeleteAccountMutationVariables = {};


export type AuthDeleteAccountMutation = (
  { __typename?: 'mutation_root' }
  & { deleted: Mutation_Root['authDeleteAccount'] }
);

export type AuthRefreshMutationVariables = {};


export type AuthRefreshMutation = (
  { __typename?: 'mutation_root' }
  & { authRefresh: (
    { __typename?: 'AuthInfo' }
    & Pick<AuthInfo, 'accessToken' | 'userUUID' | 'sessionUUID' | 'role'>
  ) }
);

export type AuthRevokeMutationVariables = {};


export type AuthRevokeMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Mutation_Root, 'authRevoke'>
);

export type AuthSendVerificationCodeMutationVariables = {
  phoneNumber: Scalars['String'];
};


export type AuthSendVerificationCodeMutation = (
  { __typename?: 'mutation_root' }
  & { sent: Mutation_Root['authSendVerificationCode'] }
);

export type AuthVerifyCodeMutationVariables = {
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
};


export type AuthVerifyCodeMutation = (
  { __typename?: 'mutation_root' }
  & { auth: (
    { __typename?: 'AuthInfo' }
    & Pick<AuthInfo, 'accessToken' | 'userUUID' | 'role'>
  ) }
);

export type ProfileUpdateMutationVariables = {
  uuid?: Maybe<Scalars['uuid']>;
  data?: Maybe<User_Set_Input>;
};


export type ProfileUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { updated: Maybe<(
    { __typename?: 'User_mutation_response' }
    & { user: Array<(
      { __typename?: 'User' }
      & Pick<User, 'uuid' | 'dob' | 'email' | 'firstSymptomDate' | 'idCardNumber' | 'gender' | 'name' | 'phoneNumber' | 'prexistingConditions' | 'role' | 'zipCode' | 'hasDiabetes' | 'hasHypertension' | 'hypertensionMedication' | 'chronicMedication'>
    )> }
  )> }
);

export type AddNoteMutationVariables = {
  data: Note_Insert_Input;
};


export type AddNoteMutation = (
  { __typename?: 'mutation_root' }
  & { create: Maybe<(
    { __typename?: 'Note_mutation_response' }
    & { note: Array<(
      { __typename?: 'Note' }
      & Pick<Note, 'createdAt' | 'doctorContent' | 'hasChangedReport' | 'patientContent' | 'patientReachability' | 'reportUUID' | 'uuid'>
    )> }
  )> }
);

export type AddReportViewMutationVariables = {
  data: ReportView_Insert_Input;
};


export type AddReportViewMutation = (
  { __typename?: 'mutation_root' }
  & { create: Maybe<(
    { __typename?: 'ReportView_mutation_response' }
    & { note: Array<(
      { __typename?: 'ReportView' }
      & Pick<ReportView, 'createdAt' | 'reportUUID' | 'uuid'>
    )> }
  )> }
);

export type PatientSendFeedbackMutationVariables = {
  message: Scalars['String'];
};


export type PatientSendFeedbackMutation = (
  { __typename?: 'mutation_root' }
  & { feedbackSent: Mutation_Root['accountSendFeedback'] }
);

export type PatientSubmitReportMutationVariables = {
  bloodPressureMax?: Maybe<Scalars['float8']>;
  bloodPressureMin?: Maybe<Scalars['float8']>;
  breathingTroubleRate?: Maybe<Scalars['Int']>;
  breathRate?: Maybe<Scalars['float8']>;
  hadContactWithInfectedPatients?: Maybe<Scalars['Boolean']>;
  hasBeenTested?: Maybe<Scalars['Boolean']>;
  hasCough?: Maybe<Scalars['Boolean']>;
  hasSymptoms?: Maybe<Scalars['Boolean']>;
  heartRate?: Maybe<Scalars['float8']>;
  lastTestDate?: Maybe<Scalars['date']>;
  lastTestResult?: Maybe<Scalars['Boolean']>;
  otherSymptoms?: Maybe<Scalars['String']>;
  symptomsBeginDate?: Maybe<Scalars['date']>;
  symptomsEvolution?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['float8']>;
  tookPainkillers?: Maybe<Scalars['Boolean']>;
  analgesics?: Maybe<Scalars['String']>;
};


export type PatientSubmitReportMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Report: Maybe<(
    { __typename?: 'Report_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'uuid' | 'userUUID' | 'createdAt' | 'severity' | 'bloodPressureMax' | 'bloodPressureMin' | 'breathingTroubleRate' | 'breathRate' | 'hadContactWithInfectedPatients' | 'hasBeenTested' | 'hasCough' | 'hasSymptoms' | 'heartRate' | 'lastTestDate' | 'lastTestResult' | 'otherSymptoms' | 'symptomsBeginDate' | 'symptomsEvolution' | 'temperature' | 'tookPainkillers' | 'analgesics'>
    )> }
  )> }
);

export type AboutStatsQueryVariables = {};


export type AboutStatsQuery = (
  { __typename?: 'query_root' }
  & { stats: (
    { __typename?: 'Stats' }
    & Pick<Stats, 'lastUpdatedAt'>
    & { byZipCode: Array<(
      { __typename?: 'StatsByZipCode' }
      & Pick<StatsByZipCode, 'zipCode' | 'totalUsers' | 'usersWithoutReports' | 'usersInTriage' | 'usersWithLowRisk' | 'usersWithHighRisk' | 'usersInfected'>
    )> }
  ) }
);

export type ProfileGetQueryVariables = {
  uuid: Scalars['uuid'];
};


export type ProfileGetQuery = (
  { __typename?: 'query_root' }
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'uuid' | 'dob' | 'email' | 'firstSymptomDate' | 'idCardNumber' | 'name' | 'gender' | 'phoneNumber' | 'prexistingConditions' | 'role' | 'zipCode' | 'hasDiabetes' | 'hasHypertension' | 'acceptedTC' | 'acceptedPrivacy' | 'acceptedDataUsage'>
  )> }
);

export type DoctorListPatientsQueryVariables = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  userFilters?: Maybe<User_Bool_Exp>;
  reportFilters?: Maybe<Report_Bool_Exp>;
};


export type DoctorListPatientsQuery = (
  { __typename?: 'query_root' }
  & { patients: Array<(
    { __typename?: 'User' }
    & Pick<User, 'uuid' | 'status' | 'phoneNumber' | 'email' | 'idCardNumber' | 'gender' | 'name' | 'dob' | 'zipCode' | 'prexistingConditions' | 'firstSymptomDate' | 'severity' | 'hasDiabetes' | 'hasHypertension' | 'createdAt'>
    & { reports_stats: (
      { __typename?: 'Report_aggregate' }
      & { aggregate: Maybe<(
        { __typename?: 'Report_aggregate_fields' }
        & Pick<Report_Aggregate_Fields, 'count'>
      )> }
    ), last_report: Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'temperature' | 'breathingTroubleRate'>
      & { views: Array<(
        { __typename?: 'ReportView' }
        & Pick<ReportView, 'createdAt'>
        & { User: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
        ) }
      )>, Notes: Array<(
        { __typename?: 'Note' }
        & Pick<Note, 'createdAt'>
        & { User: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
        ) }
      )> }
    )> }
  )>, User_aggregate: (
    { __typename?: 'User_aggregate' }
    & { aggregate: Maybe<(
      { __typename?: 'User_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type PatientDashboardQueryVariables = {};


export type PatientDashboardQuery = (
  { __typename?: 'query_root' }
  & { reports_count: (
    { __typename?: 'Report_aggregate' }
    & { aggregate: Maybe<(
      { __typename?: 'Report_aggregate_fields' }
      & Pick<Report_Aggregate_Fields, 'count'>
    )> }
  ), reports_data: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'createdAt' | 'breathingTroubleRate' | 'breathRate' | 'heartRate' | 'temperature' | 'severity'>
  )> }
);

export type PatientLastReportQueryVariables = {};


export type PatientLastReportQuery = (
  { __typename?: 'query_root' }
  & { Report: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'uuid' | 'userUUID' | 'createdAt' | 'severity' | 'bloodPressureMax' | 'bloodPressureMin' | 'breathingTroubleRate' | 'breathRate' | 'hadContactWithInfectedPatients' | 'hasBeenTested' | 'hasCough' | 'hasSymptoms' | 'heartRate' | 'lastTestDate' | 'lastTestResult' | 'otherSymptoms' | 'symptomsBeginDate' | 'symptomsEvolution' | 'temperature' | 'tookPainkillers'>
  )> }
);

export type PatientMyReportsQueryVariables = {};


export type PatientMyReportsQuery = (
  { __typename?: 'query_root' }
  & { Report: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'uuid' | 'userUUID' | 'createdAt' | 'severity' | 'bloodPressureMax' | 'bloodPressureMin' | 'breathingTroubleRate' | 'breathRate' | 'hadContactWithInfectedPatients' | 'hasBeenTested' | 'hasCough' | 'hasSymptoms' | 'heartRate' | 'lastTestDate' | 'lastTestResult' | 'otherSymptoms' | 'symptomsBeginDate' | 'symptomsEvolution' | 'temperature' | 'tookPainkillers'>
  )> }
);

export type DoctorPatientDetailsSubscriptionVariables = {
  userUUID: Scalars['uuid'];
};


export type DoctorPatientDetailsSubscription = (
  { __typename?: 'subscription_root' }
  & { patient: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'uuid' | 'status' | 'phoneNumber' | 'email' | 'idCardNumber' | 'name' | 'dob' | 'zipCode' | 'prexistingConditions' | 'firstSymptomDate' | 'severity' | 'createdAt'>
    & { reports_stats: (
      { __typename?: 'Report_aggregate' }
      & { aggregate: Maybe<(
        { __typename?: 'Report_aggregate_fields' }
        & Pick<Report_Aggregate_Fields, 'count'>
      )> }
    ), reports: Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'uuid' | 'userUUID' | 'createdAt' | 'severity' | 'bloodPressureMax' | 'bloodPressureMin' | 'breathingTroubleRate' | 'breathRate' | 'hadContactWithInfectedPatients' | 'hasBeenTested' | 'hasCough' | 'hasDiabetes' | 'hasHypertension' | 'hasSymptoms' | 'heartRate' | 'lastTestDate' | 'lastTestResult' | 'otherSymptoms' | 'symptomsBeginDate' | 'symptomsEvolution' | 'temperature' | 'tookPainkillers'>
      & { views: Array<(
        { __typename?: 'ReportView' }
        & Pick<ReportView, 'createdAt' | 'userUUID'>
        & { User: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
        ) }
      )>, Notes: Array<(
        { __typename?: 'Note' }
        & Pick<Note, 'createdAt' | 'doctorContent' | 'hasChangedReport' | 'patientContent' | 'patientReachability' | 'reportUUID' | 'uuid'>
        & { User: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
        ) }
      )> }
    )> }
  )> }
);

export type DoctorPatientListSubscriptionVariables = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  userFilters?: Maybe<User_Bool_Exp>;
  reportFilters?: Maybe<Report_Bool_Exp>;
};


export type DoctorPatientListSubscription = (
  { __typename?: 'subscription_root' }
  & { patients: Array<(
    { __typename?: 'User' }
    & Pick<User, 'uuid' | 'status' | 'phoneNumber' | 'email' | 'idCardNumber' | 'gender' | 'name' | 'dob' | 'zipCode' | 'prexistingConditions' | 'firstSymptomDate' | 'createdAt'>
    & { reports_stats: (
      { __typename?: 'Report_aggregate' }
      & { aggregate: Maybe<(
        { __typename?: 'Report_aggregate_fields' }
        & Pick<Report_Aggregate_Fields, 'count'>
      )> }
    ), lastReport: Maybe<Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'temperature' | 'severity' | 'breathingTroubleRate'>
      & { views: Array<(
        { __typename?: 'ReportView' }
        & Pick<ReportView, 'createdAt' | 'userUUID'>
        & { User: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
        ) }
      )>, Notes: Array<(
        { __typename?: 'Note' }
        & Pick<Note, 'createdAt'>
        & { User: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
        ) }
      )> }
    )>> }
  )> }
);

export type DoctorPatientListNumberSubscriptionVariables = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  userFilters?: Maybe<User_Bool_Exp>;
};


export type DoctorPatientListNumberSubscription = (
  { __typename?: 'subscription_root' }
  & { User_aggregate: (
    { __typename?: 'User_aggregate' }
    & { aggregate: Maybe<(
      { __typename?: 'User_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ) }
);


export const AccountAcceptTermsDocument = gql`
    mutation AccountAcceptTerms($phoneNumber: String!) {
  accepted: acceptAllTerms(phoneNumber: $phoneNumber)
}
    `;
export type AccountAcceptTermsMutationFn = ApolloReactCommon.MutationFunction<AccountAcceptTermsMutation, AccountAcceptTermsMutationVariables>;
export type AccountAcceptTermsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AccountAcceptTermsMutation, AccountAcceptTermsMutationVariables>, 'mutation'>;

    export const AccountAcceptTermsComponent = (props: AccountAcceptTermsComponentProps) => (
      <ApolloReactComponents.Mutation<AccountAcceptTermsMutation, AccountAcceptTermsMutationVariables> mutation={AccountAcceptTermsDocument} {...props} />
    );
    
export type AccountAcceptTermsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AccountAcceptTermsMutation, AccountAcceptTermsMutationVariables> & TChildProps;
export function withAccountAcceptTerms<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AccountAcceptTermsMutation,
  AccountAcceptTermsMutationVariables,
  AccountAcceptTermsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AccountAcceptTermsMutation, AccountAcceptTermsMutationVariables, AccountAcceptTermsProps<TChildProps>>(AccountAcceptTermsDocument, {
      alias: 'accountAcceptTerms',
      ...operationOptions
    });
};

/**
 * __useAccountAcceptTermsMutation__
 *
 * To run a mutation, you first call `useAccountAcceptTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountAcceptTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountAcceptTermsMutation, { data, loading, error }] = useAccountAcceptTermsMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useAccountAcceptTermsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AccountAcceptTermsMutation, AccountAcceptTermsMutationVariables>) {
        return ApolloReactHooks.useMutation<AccountAcceptTermsMutation, AccountAcceptTermsMutationVariables>(AccountAcceptTermsDocument, baseOptions);
      }
export type AccountAcceptTermsMutationHookResult = ReturnType<typeof useAccountAcceptTermsMutation>;
export type AccountAcceptTermsMutationResult = ApolloReactCommon.MutationResult<AccountAcceptTermsMutation>;
export type AccountAcceptTermsMutationOptions = ApolloReactCommon.BaseMutationOptions<AccountAcceptTermsMutation, AccountAcceptTermsMutationVariables>;
export const AuthDeleteAccountDocument = gql`
    mutation AuthDeleteAccount {
  deleted: authDeleteAccount
}
    `;
export type AuthDeleteAccountMutationFn = ApolloReactCommon.MutationFunction<AuthDeleteAccountMutation, AuthDeleteAccountMutationVariables>;
export type AuthDeleteAccountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AuthDeleteAccountMutation, AuthDeleteAccountMutationVariables>, 'mutation'>;

    export const AuthDeleteAccountComponent = (props: AuthDeleteAccountComponentProps) => (
      <ApolloReactComponents.Mutation<AuthDeleteAccountMutation, AuthDeleteAccountMutationVariables> mutation={AuthDeleteAccountDocument} {...props} />
    );
    
export type AuthDeleteAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AuthDeleteAccountMutation, AuthDeleteAccountMutationVariables> & TChildProps;
export function withAuthDeleteAccount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AuthDeleteAccountMutation,
  AuthDeleteAccountMutationVariables,
  AuthDeleteAccountProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AuthDeleteAccountMutation, AuthDeleteAccountMutationVariables, AuthDeleteAccountProps<TChildProps>>(AuthDeleteAccountDocument, {
      alias: 'authDeleteAccount',
      ...operationOptions
    });
};

/**
 * __useAuthDeleteAccountMutation__
 *
 * To run a mutation, you first call `useAuthDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authDeleteAccountMutation, { data, loading, error }] = useAuthDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthDeleteAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthDeleteAccountMutation, AuthDeleteAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthDeleteAccountMutation, AuthDeleteAccountMutationVariables>(AuthDeleteAccountDocument, baseOptions);
      }
export type AuthDeleteAccountMutationHookResult = ReturnType<typeof useAuthDeleteAccountMutation>;
export type AuthDeleteAccountMutationResult = ApolloReactCommon.MutationResult<AuthDeleteAccountMutation>;
export type AuthDeleteAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthDeleteAccountMutation, AuthDeleteAccountMutationVariables>;
export const AuthRefreshDocument = gql`
    mutation AuthRefresh {
  authRefresh {
    accessToken
    userUUID
    sessionUUID
    role
  }
}
    `;
export type AuthRefreshMutationFn = ApolloReactCommon.MutationFunction<AuthRefreshMutation, AuthRefreshMutationVariables>;
export type AuthRefreshComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AuthRefreshMutation, AuthRefreshMutationVariables>, 'mutation'>;

    export const AuthRefreshComponent = (props: AuthRefreshComponentProps) => (
      <ApolloReactComponents.Mutation<AuthRefreshMutation, AuthRefreshMutationVariables> mutation={AuthRefreshDocument} {...props} />
    );
    
export type AuthRefreshProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AuthRefreshMutation, AuthRefreshMutationVariables> & TChildProps;
export function withAuthRefresh<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AuthRefreshMutation,
  AuthRefreshMutationVariables,
  AuthRefreshProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AuthRefreshMutation, AuthRefreshMutationVariables, AuthRefreshProps<TChildProps>>(AuthRefreshDocument, {
      alias: 'authRefresh',
      ...operationOptions
    });
};

/**
 * __useAuthRefreshMutation__
 *
 * To run a mutation, you first call `useAuthRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authRefreshMutation, { data, loading, error }] = useAuthRefreshMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthRefreshMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthRefreshMutation, AuthRefreshMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthRefreshMutation, AuthRefreshMutationVariables>(AuthRefreshDocument, baseOptions);
      }
export type AuthRefreshMutationHookResult = ReturnType<typeof useAuthRefreshMutation>;
export type AuthRefreshMutationResult = ApolloReactCommon.MutationResult<AuthRefreshMutation>;
export type AuthRefreshMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthRefreshMutation, AuthRefreshMutationVariables>;
export const AuthRevokeDocument = gql`
    mutation AuthRevoke {
  authRevoke
}
    `;
export type AuthRevokeMutationFn = ApolloReactCommon.MutationFunction<AuthRevokeMutation, AuthRevokeMutationVariables>;
export type AuthRevokeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AuthRevokeMutation, AuthRevokeMutationVariables>, 'mutation'>;

    export const AuthRevokeComponent = (props: AuthRevokeComponentProps) => (
      <ApolloReactComponents.Mutation<AuthRevokeMutation, AuthRevokeMutationVariables> mutation={AuthRevokeDocument} {...props} />
    );
    
export type AuthRevokeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AuthRevokeMutation, AuthRevokeMutationVariables> & TChildProps;
export function withAuthRevoke<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AuthRevokeMutation,
  AuthRevokeMutationVariables,
  AuthRevokeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AuthRevokeMutation, AuthRevokeMutationVariables, AuthRevokeProps<TChildProps>>(AuthRevokeDocument, {
      alias: 'authRevoke',
      ...operationOptions
    });
};

/**
 * __useAuthRevokeMutation__
 *
 * To run a mutation, you first call `useAuthRevokeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthRevokeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authRevokeMutation, { data, loading, error }] = useAuthRevokeMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthRevokeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthRevokeMutation, AuthRevokeMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthRevokeMutation, AuthRevokeMutationVariables>(AuthRevokeDocument, baseOptions);
      }
export type AuthRevokeMutationHookResult = ReturnType<typeof useAuthRevokeMutation>;
export type AuthRevokeMutationResult = ApolloReactCommon.MutationResult<AuthRevokeMutation>;
export type AuthRevokeMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthRevokeMutation, AuthRevokeMutationVariables>;
export const AuthSendVerificationCodeDocument = gql`
    mutation AuthSendVerificationCode($phoneNumber: String!) {
  sent: authSendVerificationCode(phoneNumber: $phoneNumber)
}
    `;
export type AuthSendVerificationCodeMutationFn = ApolloReactCommon.MutationFunction<AuthSendVerificationCodeMutation, AuthSendVerificationCodeMutationVariables>;
export type AuthSendVerificationCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AuthSendVerificationCodeMutation, AuthSendVerificationCodeMutationVariables>, 'mutation'>;

    export const AuthSendVerificationCodeComponent = (props: AuthSendVerificationCodeComponentProps) => (
      <ApolloReactComponents.Mutation<AuthSendVerificationCodeMutation, AuthSendVerificationCodeMutationVariables> mutation={AuthSendVerificationCodeDocument} {...props} />
    );
    
export type AuthSendVerificationCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AuthSendVerificationCodeMutation, AuthSendVerificationCodeMutationVariables> & TChildProps;
export function withAuthSendVerificationCode<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AuthSendVerificationCodeMutation,
  AuthSendVerificationCodeMutationVariables,
  AuthSendVerificationCodeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AuthSendVerificationCodeMutation, AuthSendVerificationCodeMutationVariables, AuthSendVerificationCodeProps<TChildProps>>(AuthSendVerificationCodeDocument, {
      alias: 'authSendVerificationCode',
      ...operationOptions
    });
};

/**
 * __useAuthSendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useAuthSendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSendVerificationCodeMutation, { data, loading, error }] = useAuthSendVerificationCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useAuthSendVerificationCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthSendVerificationCodeMutation, AuthSendVerificationCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthSendVerificationCodeMutation, AuthSendVerificationCodeMutationVariables>(AuthSendVerificationCodeDocument, baseOptions);
      }
export type AuthSendVerificationCodeMutationHookResult = ReturnType<typeof useAuthSendVerificationCodeMutation>;
export type AuthSendVerificationCodeMutationResult = ApolloReactCommon.MutationResult<AuthSendVerificationCodeMutation>;
export type AuthSendVerificationCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthSendVerificationCodeMutation, AuthSendVerificationCodeMutationVariables>;
export const AuthVerifyCodeDocument = gql`
    mutation AuthVerifyCode($phoneNumber: String!, $code: String!) {
  auth: authVerifyCode(phoneNumber: $phoneNumber, code: $code) {
    accessToken
    userUUID
    role
  }
}
    `;
export type AuthVerifyCodeMutationFn = ApolloReactCommon.MutationFunction<AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables>;
export type AuthVerifyCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables>, 'mutation'>;

    export const AuthVerifyCodeComponent = (props: AuthVerifyCodeComponentProps) => (
      <ApolloReactComponents.Mutation<AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables> mutation={AuthVerifyCodeDocument} {...props} />
    );
    
export type AuthVerifyCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables> & TChildProps;
export function withAuthVerifyCode<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AuthVerifyCodeMutation,
  AuthVerifyCodeMutationVariables,
  AuthVerifyCodeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables, AuthVerifyCodeProps<TChildProps>>(AuthVerifyCodeDocument, {
      alias: 'authVerifyCode',
      ...operationOptions
    });
};

/**
 * __useAuthVerifyCodeMutation__
 *
 * To run a mutation, you first call `useAuthVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authVerifyCodeMutation, { data, loading, error }] = useAuthVerifyCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAuthVerifyCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables>(AuthVerifyCodeDocument, baseOptions);
      }
export type AuthVerifyCodeMutationHookResult = ReturnType<typeof useAuthVerifyCodeMutation>;
export type AuthVerifyCodeMutationResult = ApolloReactCommon.MutationResult<AuthVerifyCodeMutation>;
export type AuthVerifyCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables>;
export const ProfileUpdateDocument = gql`
    mutation ProfileUpdate($uuid: uuid, $data: User_set_input) {
  updated: update_User(where: {uuid: {_eq: $uuid}}, _set: $data) {
    user: returning {
      uuid
      dob
      email
      firstSymptomDate
      idCardNumber
      gender
      name
      phoneNumber
      prexistingConditions
      role
      zipCode
      hasDiabetes
      hasHypertension
      hypertensionMedication
      chronicMedication
    }
  }
}
    `;
export type ProfileUpdateMutationFn = ApolloReactCommon.MutationFunction<ProfileUpdateMutation, ProfileUpdateMutationVariables>;
export type ProfileUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProfileUpdateMutation, ProfileUpdateMutationVariables>, 'mutation'>;

    export const ProfileUpdateComponent = (props: ProfileUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<ProfileUpdateMutation, ProfileUpdateMutationVariables> mutation={ProfileUpdateDocument} {...props} />
    );
    
export type ProfileUpdateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ProfileUpdateMutation, ProfileUpdateMutationVariables> & TChildProps;
export function withProfileUpdate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProfileUpdateMutation,
  ProfileUpdateMutationVariables,
  ProfileUpdateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ProfileUpdateMutation, ProfileUpdateMutationVariables, ProfileUpdateProps<TChildProps>>(ProfileUpdateDocument, {
      alias: 'profileUpdate',
      ...operationOptions
    });
};

/**
 * __useProfileUpdateMutation__
 *
 * To run a mutation, you first call `useProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileUpdateMutation, { data, loading, error }] = useProfileUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProfileUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProfileUpdateMutation, ProfileUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ProfileUpdateMutation, ProfileUpdateMutationVariables>(ProfileUpdateDocument, baseOptions);
      }
export type ProfileUpdateMutationHookResult = ReturnType<typeof useProfileUpdateMutation>;
export type ProfileUpdateMutationResult = ApolloReactCommon.MutationResult<ProfileUpdateMutation>;
export type ProfileUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ProfileUpdateMutation, ProfileUpdateMutationVariables>;
export const AddNoteDocument = gql`
    mutation AddNote($data: Note_insert_input!) {
  create: insert_Note(objects: [$data]) {
    note: returning {
      createdAt
      doctorContent
      hasChangedReport
      patientContent
      patientReachability
      reportUUID
      uuid
    }
  }
}
    `;
export type AddNoteMutationFn = ApolloReactCommon.MutationFunction<AddNoteMutation, AddNoteMutationVariables>;
export type AddNoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddNoteMutation, AddNoteMutationVariables>, 'mutation'>;

    export const AddNoteComponent = (props: AddNoteComponentProps) => (
      <ApolloReactComponents.Mutation<AddNoteMutation, AddNoteMutationVariables> mutation={AddNoteDocument} {...props} />
    );
    
export type AddNoteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddNoteMutation, AddNoteMutationVariables> & TChildProps;
export function withAddNote<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddNoteMutation,
  AddNoteMutationVariables,
  AddNoteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddNoteMutation, AddNoteMutationVariables, AddNoteProps<TChildProps>>(AddNoteDocument, {
      alias: 'addNote',
      ...operationOptions
    });
};

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddNoteMutation, AddNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddNoteMutation, AddNoteMutationVariables>(AddNoteDocument, baseOptions);
      }
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>;
export type AddNoteMutationResult = ApolloReactCommon.MutationResult<AddNoteMutation>;
export type AddNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddNoteMutation, AddNoteMutationVariables>;
export const AddReportViewDocument = gql`
    mutation AddReportView($data: ReportView_insert_input!) {
  create: insert_ReportView(objects: [$data]) {
    note: returning {
      createdAt
      reportUUID
      uuid
    }
  }
}
    `;
export type AddReportViewMutationFn = ApolloReactCommon.MutationFunction<AddReportViewMutation, AddReportViewMutationVariables>;
export type AddReportViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddReportViewMutation, AddReportViewMutationVariables>, 'mutation'>;

    export const AddReportViewComponent = (props: AddReportViewComponentProps) => (
      <ApolloReactComponents.Mutation<AddReportViewMutation, AddReportViewMutationVariables> mutation={AddReportViewDocument} {...props} />
    );
    
export type AddReportViewProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddReportViewMutation, AddReportViewMutationVariables> & TChildProps;
export function withAddReportView<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddReportViewMutation,
  AddReportViewMutationVariables,
  AddReportViewProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddReportViewMutation, AddReportViewMutationVariables, AddReportViewProps<TChildProps>>(AddReportViewDocument, {
      alias: 'addReportView',
      ...operationOptions
    });
};

/**
 * __useAddReportViewMutation__
 *
 * To run a mutation, you first call `useAddReportViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReportViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReportViewMutation, { data, loading, error }] = useAddReportViewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddReportViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddReportViewMutation, AddReportViewMutationVariables>) {
        return ApolloReactHooks.useMutation<AddReportViewMutation, AddReportViewMutationVariables>(AddReportViewDocument, baseOptions);
      }
export type AddReportViewMutationHookResult = ReturnType<typeof useAddReportViewMutation>;
export type AddReportViewMutationResult = ApolloReactCommon.MutationResult<AddReportViewMutation>;
export type AddReportViewMutationOptions = ApolloReactCommon.BaseMutationOptions<AddReportViewMutation, AddReportViewMutationVariables>;
export const PatientSendFeedbackDocument = gql`
    mutation PatientSendFeedback($message: String!) {
  feedbackSent: accountSendFeedback(message: $message)
}
    `;
export type PatientSendFeedbackMutationFn = ApolloReactCommon.MutationFunction<PatientSendFeedbackMutation, PatientSendFeedbackMutationVariables>;
export type PatientSendFeedbackComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PatientSendFeedbackMutation, PatientSendFeedbackMutationVariables>, 'mutation'>;

    export const PatientSendFeedbackComponent = (props: PatientSendFeedbackComponentProps) => (
      <ApolloReactComponents.Mutation<PatientSendFeedbackMutation, PatientSendFeedbackMutationVariables> mutation={PatientSendFeedbackDocument} {...props} />
    );
    
export type PatientSendFeedbackProps<TChildProps = {}> = ApolloReactHoc.MutateProps<PatientSendFeedbackMutation, PatientSendFeedbackMutationVariables> & TChildProps;
export function withPatientSendFeedback<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PatientSendFeedbackMutation,
  PatientSendFeedbackMutationVariables,
  PatientSendFeedbackProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, PatientSendFeedbackMutation, PatientSendFeedbackMutationVariables, PatientSendFeedbackProps<TChildProps>>(PatientSendFeedbackDocument, {
      alias: 'patientSendFeedback',
      ...operationOptions
    });
};

/**
 * __usePatientSendFeedbackMutation__
 *
 * To run a mutation, you first call `usePatientSendFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientSendFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientSendFeedbackMutation, { data, loading, error }] = usePatientSendFeedbackMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function usePatientSendFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PatientSendFeedbackMutation, PatientSendFeedbackMutationVariables>) {
        return ApolloReactHooks.useMutation<PatientSendFeedbackMutation, PatientSendFeedbackMutationVariables>(PatientSendFeedbackDocument, baseOptions);
      }
export type PatientSendFeedbackMutationHookResult = ReturnType<typeof usePatientSendFeedbackMutation>;
export type PatientSendFeedbackMutationResult = ApolloReactCommon.MutationResult<PatientSendFeedbackMutation>;
export type PatientSendFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<PatientSendFeedbackMutation, PatientSendFeedbackMutationVariables>;
export const PatientSubmitReportDocument = gql`
    mutation PatientSubmitReport($bloodPressureMax: float8, $bloodPressureMin: float8, $breathingTroubleRate: Int, $breathRate: float8, $hadContactWithInfectedPatients: Boolean, $hasBeenTested: Boolean, $hasCough: Boolean, $hasSymptoms: Boolean, $heartRate: float8, $lastTestDate: date, $lastTestResult: Boolean, $otherSymptoms: String, $symptomsBeginDate: date, $symptomsEvolution: String, $temperature: float8, $tookPainkillers: Boolean, $analgesics: String) {
  insert_Report(objects: {bloodPressureMax: $bloodPressureMax, bloodPressureMin: $bloodPressureMin, breathingTroubleRate: $breathingTroubleRate, breathRate: $breathRate, hadContactWithInfectedPatients: $hadContactWithInfectedPatients, hasBeenTested: $hasBeenTested, hasCough: $hasCough, hasSymptoms: $hasSymptoms, heartRate: $heartRate, lastTestDate: $lastTestDate, lastTestResult: $lastTestResult, otherSymptoms: $otherSymptoms, symptomsBeginDate: $symptomsBeginDate, symptomsEvolution: $symptomsEvolution, temperature: $temperature, tookPainkillers: $tookPainkillers, analgesics: $analgesics}) {
    returning {
      uuid
      userUUID
      createdAt
      severity
      bloodPressureMax
      bloodPressureMin
      breathingTroubleRate
      breathRate
      hadContactWithInfectedPatients
      hasBeenTested
      hasCough
      hasSymptoms
      heartRate
      lastTestDate
      lastTestResult
      otherSymptoms
      symptomsBeginDate
      symptomsEvolution
      temperature
      tookPainkillers
      analgesics
    }
  }
}
    `;
export type PatientSubmitReportMutationFn = ApolloReactCommon.MutationFunction<PatientSubmitReportMutation, PatientSubmitReportMutationVariables>;
export type PatientSubmitReportComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PatientSubmitReportMutation, PatientSubmitReportMutationVariables>, 'mutation'>;

    export const PatientSubmitReportComponent = (props: PatientSubmitReportComponentProps) => (
      <ApolloReactComponents.Mutation<PatientSubmitReportMutation, PatientSubmitReportMutationVariables> mutation={PatientSubmitReportDocument} {...props} />
    );
    
export type PatientSubmitReportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<PatientSubmitReportMutation, PatientSubmitReportMutationVariables> & TChildProps;
export function withPatientSubmitReport<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PatientSubmitReportMutation,
  PatientSubmitReportMutationVariables,
  PatientSubmitReportProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, PatientSubmitReportMutation, PatientSubmitReportMutationVariables, PatientSubmitReportProps<TChildProps>>(PatientSubmitReportDocument, {
      alias: 'patientSubmitReport',
      ...operationOptions
    });
};

/**
 * __usePatientSubmitReportMutation__
 *
 * To run a mutation, you first call `usePatientSubmitReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientSubmitReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientSubmitReportMutation, { data, loading, error }] = usePatientSubmitReportMutation({
 *   variables: {
 *      bloodPressureMax: // value for 'bloodPressureMax'
 *      bloodPressureMin: // value for 'bloodPressureMin'
 *      breathingTroubleRate: // value for 'breathingTroubleRate'
 *      breathRate: // value for 'breathRate'
 *      hadContactWithInfectedPatients: // value for 'hadContactWithInfectedPatients'
 *      hasBeenTested: // value for 'hasBeenTested'
 *      hasCough: // value for 'hasCough'
 *      hasSymptoms: // value for 'hasSymptoms'
 *      heartRate: // value for 'heartRate'
 *      lastTestDate: // value for 'lastTestDate'
 *      lastTestResult: // value for 'lastTestResult'
 *      otherSymptoms: // value for 'otherSymptoms'
 *      symptomsBeginDate: // value for 'symptomsBeginDate'
 *      symptomsEvolution: // value for 'symptomsEvolution'
 *      temperature: // value for 'temperature'
 *      tookPainkillers: // value for 'tookPainkillers'
 *      analgesics: // value for 'analgesics'
 *   },
 * });
 */
export function usePatientSubmitReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PatientSubmitReportMutation, PatientSubmitReportMutationVariables>) {
        return ApolloReactHooks.useMutation<PatientSubmitReportMutation, PatientSubmitReportMutationVariables>(PatientSubmitReportDocument, baseOptions);
      }
export type PatientSubmitReportMutationHookResult = ReturnType<typeof usePatientSubmitReportMutation>;
export type PatientSubmitReportMutationResult = ApolloReactCommon.MutationResult<PatientSubmitReportMutation>;
export type PatientSubmitReportMutationOptions = ApolloReactCommon.BaseMutationOptions<PatientSubmitReportMutation, PatientSubmitReportMutationVariables>;
export const AboutStatsDocument = gql`
    query AboutStats {
  stats {
    lastUpdatedAt
    byZipCode {
      zipCode
      totalUsers
      usersWithoutReports
      usersInTriage
      usersWithLowRisk
      usersWithHighRisk
      usersInfected
    }
  }
}
    `;
export type AboutStatsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AboutStatsQuery, AboutStatsQueryVariables>, 'query'>;

    export const AboutStatsComponent = (props: AboutStatsComponentProps) => (
      <ApolloReactComponents.Query<AboutStatsQuery, AboutStatsQueryVariables> query={AboutStatsDocument} {...props} />
    );
    
export type AboutStatsProps<TChildProps = {}> = ApolloReactHoc.DataProps<AboutStatsQuery, AboutStatsQueryVariables> & TChildProps;
export function withAboutStats<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AboutStatsQuery,
  AboutStatsQueryVariables,
  AboutStatsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, AboutStatsQuery, AboutStatsQueryVariables, AboutStatsProps<TChildProps>>(AboutStatsDocument, {
      alias: 'aboutStats',
      ...operationOptions
    });
};

/**
 * __useAboutStatsQuery__
 *
 * To run a query within a React component, call `useAboutStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAboutStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AboutStatsQuery, AboutStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<AboutStatsQuery, AboutStatsQueryVariables>(AboutStatsDocument, baseOptions);
      }
export function useAboutStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AboutStatsQuery, AboutStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AboutStatsQuery, AboutStatsQueryVariables>(AboutStatsDocument, baseOptions);
        }
export type AboutStatsQueryHookResult = ReturnType<typeof useAboutStatsQuery>;
export type AboutStatsLazyQueryHookResult = ReturnType<typeof useAboutStatsLazyQuery>;
export type AboutStatsQueryResult = ApolloReactCommon.QueryResult<AboutStatsQuery, AboutStatsQueryVariables>;
export const ProfileGetDocument = gql`
    query ProfileGet($uuid: uuid!) {
  user: User_by_pk(uuid: $uuid) {
    uuid
    dob
    email
    firstSymptomDate
    idCardNumber
    name
    gender
    phoneNumber
    prexistingConditions
    role
    zipCode
    hasDiabetes
    hasHypertension
    acceptedTC
    acceptedPrivacy
    acceptedDataUsage
  }
}
    `;
export type ProfileGetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfileGetQuery, ProfileGetQueryVariables>, 'query'> & ({ variables: ProfileGetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProfileGetComponent = (props: ProfileGetComponentProps) => (
      <ApolloReactComponents.Query<ProfileGetQuery, ProfileGetQueryVariables> query={ProfileGetDocument} {...props} />
    );
    
export type ProfileGetProps<TChildProps = {}> = ApolloReactHoc.DataProps<ProfileGetQuery, ProfileGetQueryVariables> & TChildProps;
export function withProfileGet<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProfileGetQuery,
  ProfileGetQueryVariables,
  ProfileGetProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ProfileGetQuery, ProfileGetQueryVariables, ProfileGetProps<TChildProps>>(ProfileGetDocument, {
      alias: 'profileGet',
      ...operationOptions
    });
};

/**
 * __useProfileGetQuery__
 *
 * To run a query within a React component, call `useProfileGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useProfileGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileGetQuery, ProfileGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfileGetQuery, ProfileGetQueryVariables>(ProfileGetDocument, baseOptions);
      }
export function useProfileGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileGetQuery, ProfileGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfileGetQuery, ProfileGetQueryVariables>(ProfileGetDocument, baseOptions);
        }
export type ProfileGetQueryHookResult = ReturnType<typeof useProfileGetQuery>;
export type ProfileGetLazyQueryHookResult = ReturnType<typeof useProfileGetLazyQuery>;
export type ProfileGetQueryResult = ApolloReactCommon.QueryResult<ProfileGetQuery, ProfileGetQueryVariables>;
export const DoctorListPatientsDocument = gql`
    query DoctorListPatients($offset: Int = 0, $limit: Int = 5, $userFilters: User_bool_exp = {}, $reportFilters: Report_bool_exp = {}) {
  patients: User(offset: $offset, limit: $limit, where: {_and: [$userFilters, {Reports: $reportFilters}]}) {
    uuid
    status
    phoneNumber
    email
    idCardNumber
    gender
    name
    dob
    zipCode
    prexistingConditions
    firstSymptomDate
    severity
    hasDiabetes
    hasHypertension
    createdAt
    reports_stats: Reports_aggregate {
      aggregate {
        count
      }
    }
    last_report: Reports(limit: 1, order_by: {createdAt: desc_nulls_last}) {
      temperature
      breathingTroubleRate
      views: ReportViews(limit: 5, order_by: {createdAt: desc_nulls_last}) {
        createdAt
        User {
          name
          role
        }
      }
      Notes {
        createdAt
        User {
          name
          role
        }
      }
    }
  }
  User_aggregate(where: $userFilters) {
    aggregate {
      count(distinct: true)
    }
  }
}
    `;
export type DoctorListPatientsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DoctorListPatientsQuery, DoctorListPatientsQueryVariables>, 'query'>;

    export const DoctorListPatientsComponent = (props: DoctorListPatientsComponentProps) => (
      <ApolloReactComponents.Query<DoctorListPatientsQuery, DoctorListPatientsQueryVariables> query={DoctorListPatientsDocument} {...props} />
    );
    
export type DoctorListPatientsProps<TChildProps = {}> = ApolloReactHoc.DataProps<DoctorListPatientsQuery, DoctorListPatientsQueryVariables> & TChildProps;
export function withDoctorListPatients<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DoctorListPatientsQuery,
  DoctorListPatientsQueryVariables,
  DoctorListPatientsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, DoctorListPatientsQuery, DoctorListPatientsQueryVariables, DoctorListPatientsProps<TChildProps>>(DoctorListPatientsDocument, {
      alias: 'doctorListPatients',
      ...operationOptions
    });
};

/**
 * __useDoctorListPatientsQuery__
 *
 * To run a query within a React component, call `useDoctorListPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorListPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorListPatientsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      userFilters: // value for 'userFilters'
 *      reportFilters: // value for 'reportFilters'
 *   },
 * });
 */
export function useDoctorListPatientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DoctorListPatientsQuery, DoctorListPatientsQueryVariables>) {
        return ApolloReactHooks.useQuery<DoctorListPatientsQuery, DoctorListPatientsQueryVariables>(DoctorListPatientsDocument, baseOptions);
      }
export function useDoctorListPatientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoctorListPatientsQuery, DoctorListPatientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DoctorListPatientsQuery, DoctorListPatientsQueryVariables>(DoctorListPatientsDocument, baseOptions);
        }
export type DoctorListPatientsQueryHookResult = ReturnType<typeof useDoctorListPatientsQuery>;
export type DoctorListPatientsLazyQueryHookResult = ReturnType<typeof useDoctorListPatientsLazyQuery>;
export type DoctorListPatientsQueryResult = ApolloReactCommon.QueryResult<DoctorListPatientsQuery, DoctorListPatientsQueryVariables>;
export const PatientDashboardDocument = gql`
    query PatientDashboard {
  reports_count: Report_aggregate {
    aggregate {
      count
    }
  }
  reports_data: Report(limit: 20, order_by: {createdAt: desc_nulls_last}) {
    createdAt
    breathingTroubleRate
    breathRate
    heartRate
    temperature
    severity
  }
}
    `;
export type PatientDashboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PatientDashboardQuery, PatientDashboardQueryVariables>, 'query'>;

    export const PatientDashboardComponent = (props: PatientDashboardComponentProps) => (
      <ApolloReactComponents.Query<PatientDashboardQuery, PatientDashboardQueryVariables> query={PatientDashboardDocument} {...props} />
    );
    
export type PatientDashboardProps<TChildProps = {}> = ApolloReactHoc.DataProps<PatientDashboardQuery, PatientDashboardQueryVariables> & TChildProps;
export function withPatientDashboard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PatientDashboardQuery,
  PatientDashboardQueryVariables,
  PatientDashboardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PatientDashboardQuery, PatientDashboardQueryVariables, PatientDashboardProps<TChildProps>>(PatientDashboardDocument, {
      alias: 'patientDashboard',
      ...operationOptions
    });
};

/**
 * __usePatientDashboardQuery__
 *
 * To run a query within a React component, call `usePatientDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientDashboardQuery, PatientDashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientDashboardQuery, PatientDashboardQueryVariables>(PatientDashboardDocument, baseOptions);
      }
export function usePatientDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientDashboardQuery, PatientDashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientDashboardQuery, PatientDashboardQueryVariables>(PatientDashboardDocument, baseOptions);
        }
export type PatientDashboardQueryHookResult = ReturnType<typeof usePatientDashboardQuery>;
export type PatientDashboardLazyQueryHookResult = ReturnType<typeof usePatientDashboardLazyQuery>;
export type PatientDashboardQueryResult = ApolloReactCommon.QueryResult<PatientDashboardQuery, PatientDashboardQueryVariables>;
export const PatientLastReportDocument = gql`
    query PatientLastReport {
  Report(limit: 1, order_by: {createdAt: desc}) {
    uuid
    userUUID
    createdAt
    severity
    bloodPressureMax
    bloodPressureMin
    breathingTroubleRate
    breathRate
    hadContactWithInfectedPatients
    hasBeenTested
    hasCough
    hasSymptoms
    heartRate
    lastTestDate
    lastTestResult
    otherSymptoms
    symptomsBeginDate
    symptomsEvolution
    temperature
    tookPainkillers
  }
}
    `;
export type PatientLastReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PatientLastReportQuery, PatientLastReportQueryVariables>, 'query'>;

    export const PatientLastReportComponent = (props: PatientLastReportComponentProps) => (
      <ApolloReactComponents.Query<PatientLastReportQuery, PatientLastReportQueryVariables> query={PatientLastReportDocument} {...props} />
    );
    
export type PatientLastReportProps<TChildProps = {}> = ApolloReactHoc.DataProps<PatientLastReportQuery, PatientLastReportQueryVariables> & TChildProps;
export function withPatientLastReport<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PatientLastReportQuery,
  PatientLastReportQueryVariables,
  PatientLastReportProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PatientLastReportQuery, PatientLastReportQueryVariables, PatientLastReportProps<TChildProps>>(PatientLastReportDocument, {
      alias: 'patientLastReport',
      ...operationOptions
    });
};

/**
 * __usePatientLastReportQuery__
 *
 * To run a query within a React component, call `usePatientLastReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientLastReportQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientLastReportQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientLastReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientLastReportQuery, PatientLastReportQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientLastReportQuery, PatientLastReportQueryVariables>(PatientLastReportDocument, baseOptions);
      }
export function usePatientLastReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientLastReportQuery, PatientLastReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientLastReportQuery, PatientLastReportQueryVariables>(PatientLastReportDocument, baseOptions);
        }
export type PatientLastReportQueryHookResult = ReturnType<typeof usePatientLastReportQuery>;
export type PatientLastReportLazyQueryHookResult = ReturnType<typeof usePatientLastReportLazyQuery>;
export type PatientLastReportQueryResult = ApolloReactCommon.QueryResult<PatientLastReportQuery, PatientLastReportQueryVariables>;
export const PatientMyReportsDocument = gql`
    query PatientMyReports {
  Report(order_by: {createdAt: desc_nulls_last}) {
    uuid
    userUUID
    createdAt
    severity
    bloodPressureMax
    bloodPressureMin
    breathingTroubleRate
    breathRate
    hadContactWithInfectedPatients
    hasBeenTested
    hasCough
    hasSymptoms
    heartRate
    lastTestDate
    lastTestResult
    otherSymptoms
    symptomsBeginDate
    symptomsEvolution
    temperature
    tookPainkillers
  }
}
    `;
export type PatientMyReportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PatientMyReportsQuery, PatientMyReportsQueryVariables>, 'query'>;

    export const PatientMyReportsComponent = (props: PatientMyReportsComponentProps) => (
      <ApolloReactComponents.Query<PatientMyReportsQuery, PatientMyReportsQueryVariables> query={PatientMyReportsDocument} {...props} />
    );
    
export type PatientMyReportsProps<TChildProps = {}> = ApolloReactHoc.DataProps<PatientMyReportsQuery, PatientMyReportsQueryVariables> & TChildProps;
export function withPatientMyReports<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PatientMyReportsQuery,
  PatientMyReportsQueryVariables,
  PatientMyReportsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PatientMyReportsQuery, PatientMyReportsQueryVariables, PatientMyReportsProps<TChildProps>>(PatientMyReportsDocument, {
      alias: 'patientMyReports',
      ...operationOptions
    });
};

/**
 * __usePatientMyReportsQuery__
 *
 * To run a query within a React component, call `usePatientMyReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientMyReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMyReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientMyReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientMyReportsQuery, PatientMyReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientMyReportsQuery, PatientMyReportsQueryVariables>(PatientMyReportsDocument, baseOptions);
      }
export function usePatientMyReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientMyReportsQuery, PatientMyReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientMyReportsQuery, PatientMyReportsQueryVariables>(PatientMyReportsDocument, baseOptions);
        }
export type PatientMyReportsQueryHookResult = ReturnType<typeof usePatientMyReportsQuery>;
export type PatientMyReportsLazyQueryHookResult = ReturnType<typeof usePatientMyReportsLazyQuery>;
export type PatientMyReportsQueryResult = ApolloReactCommon.QueryResult<PatientMyReportsQuery, PatientMyReportsQueryVariables>;
export const DoctorPatientDetailsDocument = gql`
    subscription DoctorPatientDetails($userUUID: uuid!) {
  patient: User_by_pk(uuid: $userUUID) {
    uuid
    status
    phoneNumber
    email
    idCardNumber
    name
    dob
    zipCode
    prexistingConditions
    firstSymptomDate
    severity
    createdAt
    reports_stats: Reports_aggregate {
      aggregate {
        count
      }
    }
    reports: Reports(order_by: {createdAt: desc_nulls_last}) {
      uuid
      userUUID
      createdAt
      severity
      bloodPressureMax
      bloodPressureMin
      breathingTroubleRate
      breathRate
      hadContactWithInfectedPatients
      hasBeenTested
      hasCough
      hasDiabetes
      hasHypertension
      hasSymptoms
      heartRate
      lastTestDate
      lastTestResult
      otherSymptoms
      symptomsBeginDate
      symptomsEvolution
      temperature
      tookPainkillers
      views: ReportViews(order_by: {createdAt: desc_nulls_last}) {
        createdAt
        userUUID
        User {
          name
          role
        }
      }
      Notes {
        createdAt
        doctorContent
        hasChangedReport
        patientContent
        patientReachability
        reportUUID
        uuid
        User {
          name
          role
        }
      }
    }
  }
}
    `;
export type DoctorPatientDetailsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<DoctorPatientDetailsSubscription, DoctorPatientDetailsSubscriptionVariables>, 'subscription'>;

    export const DoctorPatientDetailsComponent = (props: DoctorPatientDetailsComponentProps) => (
      <ApolloReactComponents.Subscription<DoctorPatientDetailsSubscription, DoctorPatientDetailsSubscriptionVariables> subscription={DoctorPatientDetailsDocument} {...props} />
    );
    
export type DoctorPatientDetailsProps<TChildProps = {}> = ApolloReactHoc.DataProps<DoctorPatientDetailsSubscription, DoctorPatientDetailsSubscriptionVariables> & TChildProps;
export function withDoctorPatientDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DoctorPatientDetailsSubscription,
  DoctorPatientDetailsSubscriptionVariables,
  DoctorPatientDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, DoctorPatientDetailsSubscription, DoctorPatientDetailsSubscriptionVariables, DoctorPatientDetailsProps<TChildProps>>(DoctorPatientDetailsDocument, {
      alias: 'doctorPatientDetails',
      ...operationOptions
    });
};

/**
 * __useDoctorPatientDetailsSubscription__
 *
 * To run a query within a React component, call `useDoctorPatientDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDoctorPatientDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorPatientDetailsSubscription({
 *   variables: {
 *      userUUID: // value for 'userUUID'
 *   },
 * });
 */
export function useDoctorPatientDetailsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<DoctorPatientDetailsSubscription, DoctorPatientDetailsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<DoctorPatientDetailsSubscription, DoctorPatientDetailsSubscriptionVariables>(DoctorPatientDetailsDocument, baseOptions);
      }
export type DoctorPatientDetailsSubscriptionHookResult = ReturnType<typeof useDoctorPatientDetailsSubscription>;
export type DoctorPatientDetailsSubscriptionResult = ApolloReactCommon.SubscriptionResult<DoctorPatientDetailsSubscription>;
export const DoctorPatientListDocument = gql`
    subscription DoctorPatientList($offset: Int = 0, $limit: Int = 5, $userFilters: User_bool_exp = {}, $reportFilters: Report_bool_exp = {}) {
  patients: User(offset: $offset, limit: $limit, where: $userFilters) {
    uuid
    status
    phoneNumber
    email
    idCardNumber
    gender
    name
    dob
    zipCode
    prexistingConditions
    firstSymptomDate
    createdAt
    reports_stats: Reports_aggregate {
      aggregate {
        count
      }
    }
    lastReport(where: $reportFilters) {
      temperature
      severity
      breathingTroubleRate
      views: ReportViews(limit: 5, order_by: {createdAt: desc_nulls_last}) {
        createdAt
        userUUID
        User {
          name
          role
        }
      }
      Notes {
        createdAt
        User {
          name
          role
        }
      }
    }
  }
}
    `;
export type DoctorPatientListComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<DoctorPatientListSubscription, DoctorPatientListSubscriptionVariables>, 'subscription'>;

    export const DoctorPatientListComponent = (props: DoctorPatientListComponentProps) => (
      <ApolloReactComponents.Subscription<DoctorPatientListSubscription, DoctorPatientListSubscriptionVariables> subscription={DoctorPatientListDocument} {...props} />
    );
    
export type DoctorPatientListProps<TChildProps = {}> = ApolloReactHoc.DataProps<DoctorPatientListSubscription, DoctorPatientListSubscriptionVariables> & TChildProps;
export function withDoctorPatientList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DoctorPatientListSubscription,
  DoctorPatientListSubscriptionVariables,
  DoctorPatientListProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, DoctorPatientListSubscription, DoctorPatientListSubscriptionVariables, DoctorPatientListProps<TChildProps>>(DoctorPatientListDocument, {
      alias: 'doctorPatientList',
      ...operationOptions
    });
};

/**
 * __useDoctorPatientListSubscription__
 *
 * To run a query within a React component, call `useDoctorPatientListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDoctorPatientListSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorPatientListSubscription({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      userFilters: // value for 'userFilters'
 *      reportFilters: // value for 'reportFilters'
 *   },
 * });
 */
export function useDoctorPatientListSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<DoctorPatientListSubscription, DoctorPatientListSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<DoctorPatientListSubscription, DoctorPatientListSubscriptionVariables>(DoctorPatientListDocument, baseOptions);
      }
export type DoctorPatientListSubscriptionHookResult = ReturnType<typeof useDoctorPatientListSubscription>;
export type DoctorPatientListSubscriptionResult = ApolloReactCommon.SubscriptionResult<DoctorPatientListSubscription>;
export const DoctorPatientListNumberDocument = gql`
    subscription DoctorPatientListNumber($offset: Int = 0, $limit: Int = 5, $userFilters: User_bool_exp = {}) {
  User_aggregate(where: $userFilters) {
    aggregate {
      count(distinct: true)
    }
  }
}
    `;
export type DoctorPatientListNumberComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<DoctorPatientListNumberSubscription, DoctorPatientListNumberSubscriptionVariables>, 'subscription'>;

    export const DoctorPatientListNumberComponent = (props: DoctorPatientListNumberComponentProps) => (
      <ApolloReactComponents.Subscription<DoctorPatientListNumberSubscription, DoctorPatientListNumberSubscriptionVariables> subscription={DoctorPatientListNumberDocument} {...props} />
    );
    
export type DoctorPatientListNumberProps<TChildProps = {}> = ApolloReactHoc.DataProps<DoctorPatientListNumberSubscription, DoctorPatientListNumberSubscriptionVariables> & TChildProps;
export function withDoctorPatientListNumber<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DoctorPatientListNumberSubscription,
  DoctorPatientListNumberSubscriptionVariables,
  DoctorPatientListNumberProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, DoctorPatientListNumberSubscription, DoctorPatientListNumberSubscriptionVariables, DoctorPatientListNumberProps<TChildProps>>(DoctorPatientListNumberDocument, {
      alias: 'doctorPatientListNumber',
      ...operationOptions
    });
};

/**
 * __useDoctorPatientListNumberSubscription__
 *
 * To run a query within a React component, call `useDoctorPatientListNumberSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDoctorPatientListNumberSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorPatientListNumberSubscription({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      userFilters: // value for 'userFilters'
 *   },
 * });
 */
export function useDoctorPatientListNumberSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<DoctorPatientListNumberSubscription, DoctorPatientListNumberSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<DoctorPatientListNumberSubscription, DoctorPatientListNumberSubscriptionVariables>(DoctorPatientListNumberDocument, baseOptions);
      }
export type DoctorPatientListNumberSubscriptionHookResult = ReturnType<typeof useDoctorPatientListNumberSubscription>;
export type DoctorPatientListNumberSubscriptionResult = ApolloReactCommon.SubscriptionResult<DoctorPatientListNumberSubscription>;