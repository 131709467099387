import React, { FunctionComponent } from 'react';
import { Row, Col } from 'antd';
import withMainLayout from '../../layouts/withMainLayout';

const Home: FunctionComponent = () => (
  <>
    <Row gutter={16}>
      <Col span={6}>{/* <UserDataWidget /> */}</Col>

      <Col span={6}>{/* <MonitorDataWidget /> */}</Col>

      <Col span={10}>{/* <SocialShareWidget /> */}</Col>
    </Row>
  </>
);

export default withMainLayout(Home);
