export const MAX_TEMPERATURE = 44;
export const MIN_TEMPERATURE = 32;
// export const DEFAULT_TEMPERATURE = 37;

export const MAX_BREATHING_TROUBLE = 5;
export const MIN_BREATHING_TROUBLE = 1;
// export const DEFAULT_BREATHING_TROUBLE = 3;

// Maximum blood pressure
export const MAX_MAX_BLOOD_PRESSURE = 200;
export const MIN_MAX_BLOOD_PRESSURE = 30;
// export const DEFAULT_MAX_BLOOD_PRESSURE = 120;

// Minimum blood pressure
export const MAX_MIN_BLOOD_PRESSURE = 200;
export const MIN_MIN_BLOOD_PRESSURE = 30;
// export const DEFAULT_MIN_BLOOD_PRESSURE = 80;

// BREATH RATE

// export const DEFAULT_BREATH_RATE = 16;
export const MIN_BREATH_RATE = 6;
export const MAX_BREATH_RATE = 40;

export const MAX_HEART_RATE = 175;
export const MIN_HEART_RATE = 30;
// export const DEFAULT_HEART_RATE = 70;

export const SEVERITY_LABELS: { [key: string]: string } = {
  triage: 'Prevenção',
  important: 'Moderado',
  urgent: 'Urgente',
};
export const SEVERITY_LABELS_NUMBER: { [key: number]: string } = {
  1: 'Prevenção',
  2: 'Moderado',
  3: 'Urgente',
};

export const SEVERITY_NUMBER_LABELS: { [key: string]: number } = {
  triage: 1,
  important: 2,
  urgent: 3,
};

export const DOCTOR_ACTION_LABELS: { [key: string]: string } = {
  NOT_CONTACTED: 'Nenhuma Ação',
  CONTACTED_BUT_UNAVAILABLE: 'Não atendeu',
  CALLED_STAY_ISOLATED: 'Aconselhei Isolamento',
  CALLED_TAKE_ACTION: 'Aconselhei Ajuda médica',
};

export const DOCTOR_ACTION_COLORS: { [key: string]: string } = {
  NOT_CONTACTED: 'green',
  CONTACTED_BUT_UNAVAILABLE: 'blue',
  CALLED_STAY_ISOLATED: 'purple',
  CALLED_TAKE_ACTION: 'red',
};

export const MEDICATION_ANALGESIC_PREMADE_OPTIONS: { [key: string]: string } = {
  ibuprofen: 'Ibuprofeno',
  paracetamol: 'Paracetamol',
};