import React, { useState, FC } from 'react';
import { SendOutlined } from '@ant-design/icons';
import {
  Card, Input, Button, notification, Form, Result, Col,
} from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
// import Title from 'antd/lib/typography/Title'

import { useFormik } from 'formik';
import { string, object } from 'yup';
import {
  PatientSendFeedbackMutation,
  PatientSendFeedbackMutationVariables,
  PatientSendFeedbackDocument,
} from '../../../graphql/components';
import apolloClient from '../../../graphql/client';
import Spacer from '../../../components/spacer';
import { PatientDataProps } from '.';

const UserFeedbackWidget: FC<PatientDataProps> = () => {
  const [sent, setSent] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: object().shape({
      message: string().required('Este campo é obrigatório.'),
    }),
    async onSubmit({ message }) {
      try {
        await apolloClient.mutate<
          PatientSendFeedbackMutation,
          PatientSendFeedbackMutationVariables
        >({
          mutation: PatientSendFeedbackDocument,
          variables: {
            message,
          },
        });

        await setSent(true);
      } catch (err) {
        notification.error({
          message: 'Ocorreu um erro ao tentar submeter o seu feedback.',
          description: 'Infelizmente não nos foi possível submeter o seu feedback. Por favor verifique o acesso à internet ou tente mais tarde.',
        });
      }
    },
  });

  const hasErrors = Object.keys(formik.errors).length > 0;

  return (
    <Col
      xl={{ span: 8 }}
      md={{ span: 12 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <Card title="Deixe-nos o seu feedback">
        {sent && (
          <Result
            status="success"
            icon={<SendOutlined />}
            title="Muito obrigado!"
            subTitle={(
              <>
                O teu feedback foi entregue à equipa da
                {' '}
                <b>CovidApp</b>
                .
              </>
            )}
          />
        )}

        {!sent && (
          <Form
            layout="vertical"
            onSubmitCapture={formik.handleSubmit}
            onChange={formik.handleChange}
          >
            <Paragraph>
              Contribua para melhorar a plataforma
              {' '}
              <b>CovidApp</b>
              , o seu feedback
              é importante para conseguirmos chegar a mais pessoas.
            </Paragraph>

            <Spacer />

            <Form.Item
              required
              label="Feedback"
              validateStatus={
                (formik.isSubmitting && 'validating')
                || (formik.errors?.message && 'error')
                || (formik.values.message && 'success')
                || undefined
              }
              wrapperCol={{ span: 24 }}
              extra={formik.errors.message}
            >
              <Input.TextArea
                rows={4}
                disabled={formik.isSubmitting}
                name="message"
                value={formik.values.message}
                style={{ margin: '10px 0' }}
                placeholder="Deixe aqui o seu feedback"
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              loading={formik.isSubmitting}
              disabled={hasErrors}
              style={{ float: 'right' }}
            >
              <SendOutlined />
              {' '}
              Enviar Feedback
            </Button>
          </Form>
        )}
      </Card>
    </Col>
  );
};

export default UserFeedbackWidget;
