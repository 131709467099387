import React, { SFC } from 'react';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  RedditIcon,
} from 'react-share';
import { appUrl } from '../config';

const SocialComponent: SFC<{}> = () => (
  <>
    <div className="site-footer-socialmedia">
      <div className="site-footer-facebook">
        <FacebookShareButton url={appUrl}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>
      <div className="site-footer-messenger">
        <FacebookMessengerShareButton url={appUrl} appId="521270401588372">
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
      </div>
      <div className="site-footer-Twitter">
        <TwitterShareButton url={appUrl}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
      <div className="site-footer-WhatsApp">
        <WhatsappShareButton url={appUrl} separator=":: ">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
      <div className="site-footer-Linkedin">
        <LinkedinShareButton url={appUrl}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
      <div className="site-footer-Reddit">
        <RedditShareButton url={appUrl} windowWidth={660} windowHeight={460}>
          <RedditIcon size={32} round />
        </RedditShareButton>
      </div>
    </div>
  </>
);

export default SocialComponent;
