import React, { FC } from 'react';

const SNS24Link: FC<{ withTel?: boolean }> = ({ withTel }) => (
  <a href="tel:808242424">
    SNS 24
    {' '}
    {withTel && '(Tel: 808 24 24 24)' || false}
  </a>
);

export default SNS24Link;
