import React, { FC } from 'react';
import './container.scss';

const Container: FC<{}> = ({ children }) => (
  <div className="container-component">
    {children}
  </div>
);

export default Container;
