import React, { FunctionComponent } from 'react';
import Text from 'antd/lib/typography/Text';

interface LabelProps {
  label?: string
  value?: string | null
}

const Label: FunctionComponent<LabelProps> = ({ label, value }) => (
  <div style={{ marginBottom: 6 }}>
    <Text>
      <Text strong>
        {label}
        {' '}
      </Text>
      <Text>{value}</Text>
    </Text>
  </div>
);

export default Label;
