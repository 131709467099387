import React, { FC } from 'react';
import { Card, Row, Col, Button, Carousel } from 'antd';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Link } from 'react-router-dom';
import withMainLayout from '../../../layouts/withMainLayout';
import './home.scss';
import FMUP from '../../../assets/images/fmup.png';
import MED from '../../../assets/images/med.png';
import MOSANO from '../../../assets/images/mosano.png';
import GULBEN from '../../../assets/images/gulbenkian.png';
// import CUATRE from '../../../assets/images/cuatrecasas.png';
import EDP from '../../../assets/images/edp.png';
import Phone from '../../../assets/icons/telemovel.svg';
import Heart from '../../../assets/icons/heart.svg';
import Report from '../../../assets/icons/Report.svg';
import Control from '../../../assets/icons/control.svg';
import Expose from '../../../assets/icons/expose.svg';
import AiSupport from '../../../assets/icons/aisupport.svg';
import MedicalSupport from '../../../assets/icons/medical_support.svg';
import Safe from '../../../assets/icons/safe.svg';
import Container from '../../../components/container';
import News from './news.json';

const Home: FC = () => {
  const slider = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  return (
    <div id="home-page">
      <section id="hero">
        <Container>
          <Card bordered={false}>
            <Title level={1}>
              Monitorize os seus sintomas do{' '}
              <span style={{ color: '#49C9DD' }}>COVID-19</span> de forma segura
              e gratuita!
            </Title>

            <Link to="/account/login" className="cta">
              <Button type="primary">Registar</Button>
            </Link>
          </Card>
        </Container>
      </section>

      <section id="how-it-works">
        <Container>
          <Title level={1}>Como Funciona?</Title>
          <Row gutter={32}>
            <Col className="step" xl={8} md={12} xs={24}>
              <img src={Phone} className="icon" alt="telemóvel" />
              <Title style={{ fontWeight: 'bold' }} level={3}>
                Registo
              </Title>
              <Paragraph>
                O utilizador procede ao{' '}
                <Link className="red-link" to="/account/login">
                  registo
                </Link>{' '}
                com o seu número de telemóvel e preenche os seus dados pessoais.
              </Paragraph>
            </Col>
            <Col className="step" xl={8} md={12} xs={24}>
              <img src={Heart} className="icon" alt="coração" />
              <Title style={{ fontWeight: 'bold' }} level={3}>
                Monitorização
              </Title>
              <Paragraph>
                Duas vezes por dia, o utilizador preenche os seus sintomas no{' '}
                <Link className="red-link" to="/about/patient/reports/create">
                  centro de monitorização
                </Link>
                .
              </Paragraph>
            </Col>
            <Col className="step" xl={8} md={12} xs={24}>
              <img src={Report} className="icon" alt="relatório" />
              <Title style={{ fontWeight: 'bold' }} level={3}>
                Avaliação
              </Title>
              <Paragraph>
                O algoritmo de inteligência artificial da CovidApp dirá qual a
                melhor forma de proceder dado o seu estado actual e permitirá
                aos profissionais de saúde socorrer os casos mais preocupantes.
              </Paragraph>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="features">
        <Container>
          <Row gutter={32}>
            <Col span={12} className="title-holder">
              <Title level={1}>Porquê utilizar?</Title>
              <Paragraph>
                O objectivo da CovidApp é assegurar o utilizador que está a
                proceder da melhor forma, a partir da comodidade e segurança da
                sua própria casa.
              </Paragraph>
            </Col>
            <Col>{/* empty on purpose */}</Col>
            <Col className="feature" md={12} xs={24}>
              <img src={Control} className="icon" alt="controlo" />
              <Title level={3}>Controlo dos sintomas</Title>
              <Paragraph>
                A plataforma permite monitorizar os sintomas e a evolução dos
                mesmos ao longo do tempo, reduzindo assim a dúvida sobre a
                possível contracção da doença.
              </Paragraph>
            </Col>
            <Col className="feature" md={12} xs={24}>
              <img src={Expose} className="icon" alt="proteger" />
              <Title level={3}>Redução da exposição</Title>
              <Paragraph>
                A utilização da CovidApp previne a necessidade de deslocação a
                um hospital para rastreio do vírus, ação que acarreta riscos
                potencialmente desnecessários. Ao mesmo tempo, estará a retirar
                pressão dos serviços de saúde neste momento crítico e a ser
                acompanhado remotamente.
              </Paragraph>
            </Col>
            <Col className="feature" md={12} xs={24}>
              <img
                src={AiSupport}
                className="icon"
                alt="inteligência artificial"
              />
              <Title level={3}>Apoio inteligente e imediato</Title>
              <Paragraph>
                Uma solução de inteligência artificial, desenvolvida por
                parceiros credenciados, que permite rapidamente fazer um
                pré-rastreio e indicar-lhe qual o melhor procedimentos a tomar
                no caso de situações de risco.
              </Paragraph>
            </Col>
            <Col className="feature" md={12} xs={24}>
              <img src={MedicalSupport} className="icon" alt="apoio médico" />
              <Title level={3}>Acompanhamento médico (em breve)</Title>
              <Paragraph>
                Oferecemos a possibilidade de rastreio feito por profissionais
                de saúde, para que possam entrar em contacto consigo caso sejam
                identificadas situações de risco.
              </Paragraph>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="partners">
        <Container>
          <Row align="middle" gutter={32}>
            <Col
              className="info"
              sm={{ span: 12, order: 1 }}
              xs={{ span: 24, order: 1 }}
            >
              <Title level={1}>Apoios e Parceiros</Title>
              <Paragraph>
                A plataforma CovidApp está a ser construída por técnicos da área
                da saúde e tecnologia, para ajudar a dar resposta à epidemia do
                COVID-19, sem qualquer motivo financeiro.
              </Paragraph>
              <Link to="/about/team">
                <Button size="large">Conheça a Equipa</Button>
              </Link>
            </Col>
            <Col className="apoios" xs={{ span: 24, order: 2 }}>
              <div className="title">
                <Title level={2}>Apoios</Title>
              </div>
              <Row className="logos" justify="space-between">
                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                  <div className="partner">
                    <img src={GULBEN} alt="gulbenkian logo" />
                  </div>
                </Col>
                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                  <div className="partner">
                    <img src={EDP} alt="edp logo" />
                  </div>
                </Col>
                {/* <Col sm={{ span: 8 }} xs={{ span: 24 }}>
                  <div className="partner">
                    <img src={CUATRE} alt="cuatrecasas logo" />
                  </div>
                </Col> */}
              </Row>
            </Col>
            <Col className="parceiros" xs={{ span: 24, order: 3 }}>
              <div className="title">
                <Title level={2}>Parceiros</Title>
              </div>
              <Row className="logos" justify="space-between">
                <Col md={{ span: 8 }} xs={{ span: 24 }}>
                  <div className="partner">
                    <img src={FMUP} alt="FMUP logo" />
                  </div>
                </Col>
                <Col md={{ span: 8 }} xs={{ span: 24 }}>
                  <div className="partner">
                    <img src={MED} alt="med logo" />
                  </div>
                </Col>
                <Col md={{ span: 8 }} xs={{ span: 24 }}>
                  <div className="partner">
                    <img src={MOSANO} alt="mosano logo" />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="news">
        <Container>
          <Row align="middle" justify="center">
            <Title level={1}>CovidApp em destaque</Title>
          </Row>
          <Row align="middle" justify="center">
            <Col md={22} xs={22}>
              <Carousel
                slidesToShow={2}
                slidesToScroll={1}
                infinite={true}
                speed={500}
                arrows
                dots={false}
                responsive={slider}
              >
                {News.news.map(item => {
                  return (
                    <Card bordered>
                      <a href={item.link} target="_blank" rel="noopener noreferrer nofollow">
                        <Row justify="space-between" align="bottom">
                          <img
                            src={require(`../../../assets/icons/${item.logo}`)}
                            alt={`${item.media} logo`}
                          />
                          <span className="news-data">{item.date}</span>
                        </Row>
                        <Paragraph>{item.mention} </Paragraph>
                        <p className="red-link">
                          {item.src}
                        </p>
                      </a>
                    </Card>
                  );
                })}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="your-data-is-safe">
        <Container>
          <Row gutter={32} align="middle" justify="space-between">
            <Col md={12} xs={24} className="content">
              <Title level={1}>
                Os seus dados pessoais e clínicos estãos seguros.
              </Title>
              <Paragraph>
                Toda a informação providenciada na Covid App será guardada de
                forma segura e encriptada, e somente profissionais de saúde
                creditados para o efeito terão acesso aos mesmos.
              </Paragraph>
              <Paragraph>
                Saiba mais sobre a nossa{' '}
                <Link className="red-link" to="/about/privacy-policy">
                  Política de Privacidade
                </Link>
                .
              </Paragraph>
            </Col>
            <Col md={8} xs={24} className="icon">
              <img src={Safe} alt="Safe Icon" />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="bottom-cta">
        <Container>
          <Col>
            <Title level={1}>Está de Quarentena em Casa?</Title>
            <Paragraph>Comece a monitorizar os seus sintomas.</Paragraph>
            <Link to="/account/login">
              <Button type="primary" size="large">
                Monitorizar
              </Button>
            </Link>
          </Col>
        </Container>
      </section>
    </div>
  );
};

export default withMainLayout(Home, false);
