import React, { FC } from 'react';
import {
  Card, Result, Button, Col,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';
import Spacer from '../../../components/spacer';
import { authentication } from '../../../stores';
import { User } from '../../../graphql/components';
import { PatientDataProps } from '.';

const UserDataWidget: FC<PatientDataProps> = ({ data }) => {
  // load user data
  const user: Partial<User> = authentication.userData || {};
  const { isProfileComplete } = authentication;

  if (isProfileComplete && (data?.reports_count?.aggregate?.count || 0) > 0) {
    return null;
  }

  return (
    <Col
      xl={{ span: 8 }}
      md={{ span: 12 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <Card>
        <Title level={3}>Confirme os seus dados</Title>
        <Spacer />
        {!isProfileComplete && (
          <Result
            status="warning"
            title="Faltam dados relevantes na sua conta"
            subTitle="Os profissionais de saúde necessitam de informações pessoais para que o possam identificar e contactar."
            extra={[
              <Link to="/account/profile" key={0}>
                <Button key="console" type="primary">
                  Preencher os dados em falta
                </Button>
              </Link>,
            ]}
          />
        )}
        {isProfileComplete && (
          <Result
            status="success"
            title={`Parabéns ${user.name}`}
            subTitle="Parece que tens todos os dados que os profissionais de saúde necessitam."
          />
        )}
      </Card>
    </Col>
  );
};

export default UserDataWidget;
