import React, { FunctionComponent, useState } from 'react';

import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';
import {
  Card, Button, notification, Tag,
} from 'antd';
import {
  User,
  Report,
  useAddNoteMutation,
  DoctorPatientDetailsDocument,
} from '../graphql/components';
import Label from './label';
import ReportsTable from './reports-table';
import Spacer from './spacer';
import DoctorNoteModal from './doctor-note-modal';
import { DOCTOR_ACTION_LABELS, DOCTOR_ACTION_COLORS } from '../constants';

interface PatientDetailsProps {
  patient?: Partial<User> | null;
  viewers?: any;
  reports?: Partial<Report>[];
}

const emptyNotesFilter = (note: { patientContent: any; doctorContent: any }) => (!!note.patientContent && note.patientContent.length)
  || (!!note.doctorContent && note.doctorContent.length);

const PatientDetails: FunctionComponent<PatientDetailsProps> = (props) => {
  const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false);
  const [mutate, { loading }] = useAddNoteMutation();
  const { patient, reports } = props;
  if (!reports) {
    return null;
  }
  const lastReport = reports?.[0];
  const wasViewed = (lastReport?.Notes?.length || 0) > 0;

  const AddEmptyNote = async () => {
    try {
      await mutate({
        variables: {
          data: {
            doctorContent: '',
            hasChangedReport: false,
            patientReachability: 'NOT_CONTACTED',
            reportUUID: lastReport.uuid,
            patientContent: '',
          },
        },
        refetchQueries: [
          {
            query: DoctorPatientDetailsDocument,
            variables: { userUUID: lastReport.userUUID },
          },
        ],
      });

      notification.success({
        message: 'Paciente marcado como visto! Obrigado pelo trabalho!',
      });
    } catch (e) {
      notification.error({
        message: 'Ocorreu um erro ao tentar guardar os dados.',
        description: 'Infelizmente não nos foi possível guardar os dados. Por favor verifique o acesso à internet ou tente mais tarde.',
      });
    }
  };

  return (
    <div>
      <Card
        style={{
          marginBottom: 36,
        }}
      >
        <Label label="Número de telemóvel:" value={patient?.phoneNumber} />
        <Label label="Email:" value={patient?.email} />
        <Label
          label="Número do Cartão de Cidadão:"
          value={patient?.idCardNumber}
        />
        <Label
          label="Data de Nascimento:"
          value={moment(patient?.dob).format('DD MMM YYYY')}
        />
        <Label label="Código Postal:" value={patient?.zipCode} />
      </Card>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {wasViewed ? null : (
          <Button
            type="primary"
            key="viewed"
            disabled={wasViewed}
            onClick={AddEmptyNote}
            loading={loading}
            style={{ marginRight: 10 }}
          >
            Marcar como visto (sem gravidade)
          </Button>
        )}
        <DoctorNoteModal
          visible={noteModalVisible}
          onClose={() => setNoteModalVisible(false)}
          mutate={mutate}
          report={lastReport}
        />
        <Button
          type="primary"
          key="note"
          loading={loading}
          onClick={() => setNoteModalVisible(true)}
        >
          {wasViewed ? 'Adicionar Nota' : 'Marcar como visto e deixar Nota'}
        </Button>
      </div>
      <Spacer />
      <Spacer />
      {((lastReport?.Notes || []).filter(emptyNotesFilter).length || 0) > 0 ? (
        <>
          <Title level={1}>Notas</Title>
          {lastReport?.Notes?.filter(emptyNotesFilter).map((note) => (
            <Card>
              <Title level={3} style={{ marginBottom: 1 }}>
                {note.User.name}
                <Tag
                  style={{ marginLeft: 10 }}
                  color={note.User.role === 'doctor' ? 'green' : 'volcano'}
                >
                  {note.User.role === 'doctor' ? 'Médico' : 'Doente'}
                </Tag>
              </Title>
              <Paragraph style={{ fontSize: 12 }}>
                {moment(note.createdAt).format('DD/MM/YYYY  -   HH:mm')}
              </Paragraph>
              <Text>{note.doctorContent}</Text>
              {note.patientReachability !== 'NOT_CONTACTED' ? (
                <>
                  <Spacer />
                  <Paragraph style={{ fontSize: 12 }}>
                    <Tag color={DOCTOR_ACTION_COLORS[note.patientReachability]}>
                      {DOCTOR_ACTION_LABELS[note.patientReachability]}
                    </Tag>
                  </Paragraph>
                </>
              ) : null}
            </Card>
          ))}
          <Spacer />
          <Spacer />
          <Spacer />
        </>
      ) : null}

      <Spacer />
      <Spacer />
      {(props.viewers || []).length > 0 ? (
        <>
          <Title level={1}>A ver o doente</Title>
          <div
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            {(props.viewers || []).map((viewer: any) => (
              <Card>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {viewer?.User?.name}
                  </span>
                  <Tag
                    style={{ marginTop: 7, marginBottom: 7 }}
                    color={
                      viewer?.User?.role === 'doctor' ? 'green' : 'volcano'
                    }
                  >
                    {viewer?.User?.role === 'doctor' ? 'Médico' : 'Doente'}
                  </Tag>
                  <span style={{ fontSize: 11 }}>
                    {moment(viewer.createdAt).fromNow()}
                  </span>
                </div>
              </Card>
            ))}
          </div>
          <Spacer />
          <Spacer />
          <Spacer />
        </>
      ) : null}

      <Title style={{ marginBottom: 36 }} level={1}>
        Relatórios
      </Title>
      <ReportsTable reports={reports} />
    </div>
  );
};

export default PatientDetails;
