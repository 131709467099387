import React from 'react';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import url from '../url';
import { authentication } from '../stores/authentication';
import PatientsList from '../pages/doctor/PatientsList';
import PatientDetails from '../pages/doctor/PatientDetails';

const Routes = () => (
  <BrowserRouter basename={url.pathname}>
    <Switch>
      {/* Auth related */}
      <Route
        exact
        path="/account/login"
        component={require('../pages/account/login').default}
      />
      <Route
        exact
        path="/account/logout"
        component={require('../pages/account/logout').default}
      />
      <ProtectedRoute
        exact
        path="/account/profile"
        component={require('../pages/account/profile').default}
      />

      {/* Patient */}
      <ProtectedRoute
        exact
        role="patient"
        path="/patient/dashboard"
        component={require('../pages/patient/dashboard').default}
      />
      <ProtectedRoute
        exact
        role="patient"
        path="/patient/reports/create"
        component={require('../pages/patient/create-report').default}
      />
      <ProtectedRoute
        exact
        role="patient"
        path="/patient/reports"
        component={require('../pages/patient/reports-list').default}
      />
      {/* <ProtectedRoute role="patient" path="/" component={() => <Redirect to="/patient/dashboard" />} /> */}

      {/* Doctors */}
      <ProtectedRoute
        exact
        role="doctor"
        path="/doctor/dashboard"
        component={require('../pages/doctor/dashboard').default}
      />
      <ProtectedRoute
        exact
        role="doctor"
        path="/doctor/patients"
        component={PatientsList}
      />
      <ProtectedRoute
        exact
        role="doctor"
        path="/doctor/patients/:id"
        component={PatientDetails}
      />

      {/* Institutional/Public pages */}
      <Route
        exact
        path="/about/home"
        component={require('../pages/about/home').default}
      />
      <Route
        exact
        path="/about/stats"
        component={require('../pages/about/stats').default}
      />
      <Route
        exact
        path="/about/precautions"
        component={require('../pages/about/precautions').default}
      />

      <Route
        exact
        path="/about/privacy-policy"
        component={require('../pages/about/privacy-policy').default}
      />
      <Route
        exact
        path="/about/terms-and-conditions"
        component={require('../pages/about/terms-conditions').default}
      />
      <Route
        exact
        path="/about/team"
        component={require('../pages/about/team').default}
      />

      <Route
        exact
        path="/"
        component={() => (
          <Redirect
            to={
              (!authentication.isAuthenticated && '/about/home')
              || (authentication.role === 'patient' && '/patient/dashboard')
              || (authentication.role === 'doctor' && '/doctor/patients')
              || '/about/home'
            }
          />
        )}
      />
      <Route
        exact
        path="/about/how-it-works"
        component={require('../pages/about/how-it-works').default}
      />

      {/* Redirect anything else to /login */}
      <Redirect to="/account/login" />
    </Switch>
  </BrowserRouter>
);

export default Routes;
