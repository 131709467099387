import React, { FunctionComponent } from 'react';
import {
  Card, Result, Button, Spin,
} from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import withMainLayout from '../../../layouts/withMainLayout';
import { authentication } from '../../../stores';
// import Spacer from '../../../components/spacer'
// import Title from 'antd/lib/typography/Title'
import { usePatientMyReportsQuery } from '../../../graphql/components';
import ReportsTable from '../../../components/reports-table';

const UserReportHistory: FunctionComponent = () => {
  const { loading, data } = usePatientMyReportsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { isProfileComplete } = authentication;

  if (!isProfileComplete) {
    return (
      <Card title="Confirme os seus dados">
        <Result
          status="warning"
          title="Faltam dados relevantes na sua conta"
          subTitle="Os profissionais de saúde necessitam de informações pessoais para que o possam identificar e contactar."
          extra={[
            <Link to="/account/profile" key={0}>
              <Button key="console" type="primary">
                Preencher os dados em falta
              </Button>
            </Link>,
          ]}
        />
      </Card>
    );
  }

  if (loading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 50 }}>
        <Spin />
      </div>
    );
  }

  if (!data) {
    // TODO
    return null;
  }

  return (
    <Card
      bordered={false}
      title="Os meus Relatórios de Monitorização"
      extra={(
        <Link to="/patient/reports/create">
          <Button type="primary" key="console">
            <PlusOutlined />
            {' '}
            Nova Monitorização
          </Button>
        </Link>
      )}
      style={{
        margin: 'auto',
        boxShadow:
          '0px 6.17824px 21.5365px rgba(0, 0, 0, 0.030405), 0px 17.9145px 49.8481px rgba(0, 0, 0, 0.0358662), 0px 16px 64px rgba(0, 0, 0, 0.05)',
      }}
    >
      <ReportsTable reports={data.Report} />
    </Card>
  );
};

export default withMainLayout(UserReportHistory);
