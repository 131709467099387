import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import withMainLayout from '../../../layouts/withMainLayout';

const HowItWorks: FunctionComponent = () => (
  <>
    <Title>Âmbito da Aplicação </Title>
    <ul>
      1. A Aplicação Covid foi desenvolvida para gerir a afluência ao serviço
      de urgência médica por pacientes com suspeitas de serem portadores do
      vírus COVID-19.
    </ul>
    <ul>
      2. A Aplicação Covid permite a monitorização remota de pacientes
      suspeitos (ou confirmados) de serem portadores do vírus COVID-19.
      {' '}
    </ul>

    <h2>Como Utilizar?</h2>
    <ul>
      1. O Utilizador deverá:
      <ul>
        {' '}
        1.1 Clicar em
        {' '}
        <Link to="/account/login">Entrar</Link>
      </ul>
      <ul>
        {' '}
        1.2 Utilizar o seu número de telemóvel para se autenticar/registar na
        plataforma. Após colocar o número, o utilizador receberá no seu
        telemóvel um código de validação que deverá colocar na plataforma para
        validar o seu registo/login.
      </ul>
      <ul>
        {' '}
        1.3 Caso seja o primeiro registo na plataforma, será pedido ao
        utilizador para colcocar os seguintes campos
        <ul>
          <li>Nome</li>
        </ul>
        <ul>
          <li>Email</li>
        </ul>
        <ul>
          <li>Número do Cartão de Cidadão/B.I.</li>
        </ul>
        <ul>
          <li>Data de Nascimento</li>
        </ul>
        <ul>
          <li>Código Postal</li>
        </ul>
      </ul>
    </ul>

    <ul>
      2. Monitorização de Sintomas:
      <ul>
        {' '}
        2.1 Clicar em
        {' '}
        <Link to="patient/report"> Monitorizar </Link>
        {' '}
      </ul>
      <ul>
        {' '}
        2.2 Ao entrar na página de registo de sintomas será pedido os
        seguintes dados ao utilizador:
        <ul>
          <li>
            Data de Início dos Sintomas (apenas na primeira vez que está a
            efetuar este passo)
          </li>
        </ul>
        <ul>
          <li>Temperatura Corporal</li>
        </ul>
        <ul>
          <li>
            Classificar a sua dificuldade respiratóra (Dispneia) de 1 a 5.
          </li>
        </ul>
        <ul>
          <li>Ritmo Cardíaco</li>
        </ul>
        <ul>
          <li>Tensão Arterial</li>
        </ul>
        <ul>
          <li>Se possui Diabetes</li>
        </ul>
        <ul>
          <li>Se possui outros Sintomas</li>
        </ul>
        <ul>
          <li>
            Se o estado atual melhorou ou piorou relativamente à ultima
            submissão.
          </li>
        </ul>
      </ul>
      <ul>
        2.3 Deverá repetir os passos nos pontos 2.1 e 2.2, pelos menos duas
        vezes por dia.
      </ul>
    </ul>
    <h2>Como são utilizados os seus dados?</h2>
    <ul>
      <p>
        {' '}
        Todos os dados colocados na aplicação irão alimentar uma rede de
        algoritmos de inteligência artificial, que irão categorizar os
        sintomas dos pacientes registados na plataforma.
      </p>
      <p>
        A colheita de dados efetuada através da plataforma irá permitir aos
        profissionais de saúde a deteção de pacientes de risco e facilitar o
        contacto com os mesmos. Ao mesmo tempo viabiliza a deteção de padrões
        nos sintomas reportados e compreensão da sua evolução.
      </p>
      <p>
        Com base nos padrões detetados pela plataforma, esta irá notificar os
        pacientes que considere de risco para que estes entrem em contacto com
        as autoridades de saúde.
      </p>
    </ul>
  </>
);

export default withMainLayout(HowItWorks);
