import React, { FunctionComponent, useMemo } from 'react';
import { Card, Col, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import {
  Axis, Chart, Geom, Legend, Tooltip as Toolt, Label,
} from 'bizcharts';

import { authentication } from '../../../stores';
import { useAboutStatsQuery } from '../../../graphql/components';

interface ChartData {
  status: String;
  count: number
}

const MyZoneWidget: FunctionComponent<{}> = () => {
  const { userData } = authentication;
  const { data, loading, error } = useAboutStatsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 1000 * 60,
  });

  const chartData: Array<ChartData> = useMemo(() => {
    const zipCode = userData?.zipCode4 || userData?.zipCode?.substr(0, 4);
    const zipCodeData = zipCode && data?.stats.byZipCode.find(
      (entry) => entry.zipCode === zipCode
    ) || undefined;

    return [
      {
        status: 'Total',
        count: zipCodeData?.totalUsers || 0,
      },
      {
        status: 'Suspeitos',
        count: zipCodeData && (zipCodeData.usersWithLowRisk + zipCodeData.usersWithHighRisk) || 0,
      },
      {
        status: 'Infectados',
        count: zipCodeData?.usersInfected || 0,
      },
    ];
  }, [loading, data]);

  const chartAxis = {
    count: { alias: 'Count' },
    status: { alias: 'Status Category' },
  };

  if (!(userData?.zipCode4 || userData?.zipCode?.substr(0, 4)) || error) {
    return null;
  }

  if (loading) {
    return (
      <Col
        xl={{ span: 8 }}
        md={{ span: 12 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
      >
        <Card id="my-zone-widget" title="Covid-19 na sua área">
          <div style={{ width: '100%', textAlign: 'center', paddingTop: 50 }}>
            <Spin />
          </div>
        </Card>
      </Col>
    );
  }

  return (
    <Col xl={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
      <Card id="my-zone-widget" title="Covid-19 na sua área">
        <Title level={4}>
          Acompanhe o número de casos suspeitos na sua área de residência.
        </Title>
        <div id="colorChart">
          <Chart
            forceFit
            height={300}
            data={chartData}
            scale={chartAxis}
            padding={{
              top: 40,
              bottom: 60,
              left: 10,
              right: 10,
            }}
          >
            <Axis name="status" visible={false} />
            <Axis name="count" visible={false} />
            <Toolt />
            <Legend name="status" position="bottom" offsetY={-10} />
            <Geom
              type="interval"
              position="status*count"
              color={['status', ['#77b0ab', '#ecc543', '#d32c23']]}
            >
              <Label
                content={[
                  'status*count',
                  (_, count) => `${count}`,
                ]}
                textStyle={{
                  fontSize: '18',
                  fontWeight: 'bold',
                }}
              />
            </Geom>
          </Chart>
        </div>
        <p>Estes dados são relativos aos utilizadores da CovidApp</p>
      </Card>
    </Col>
  );
};

export default MyZoneWidget;
